// import libraries
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";

import axios from 'axios';
import urlConfig from '../../urlConfig';

// third-party libraries
import { connect } from 'react-redux';
import ProductValidation from '../../helpers/productValidation';
import StoreValidation from '../../helpers/storeValidation';

import { createStore, getStore } from '../../store/modules/stores';
import { RootState } from '../../store/rootReducer';

// styles

// components
import {
  Button,
  Container,
  TextField,
  Grid,
  // Card,
  CardActionArea,
  CardMedia,
  FormControl,
} from '@mui/material';

import { makeStyles } from '@mui/styles';
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';

// interfaces
// import { ProductProps, ProductState } from './interfaces';

import { BrowserRouter as Router, HashRouter, Link, matchPath, Navigate, Route, Routes, useParams } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '64px',
  },
  form: {
    width: '100%',
    maxWidth: '400px',
    marginTop: '24px',
  },
  textField: {
    margin: '12px 0'
  },
}));

const Store = (props: any) => {
  const navigate = useNavigate();
    type storeValidator = {
        [key: string]: any;
    };
    const dispatch = useDispatch<any>();
    const [storeName, setStoreName] = React.useState('');
    const [storeDescription, setStoreDescription] = React.useState('');
    const [storeAddress, setStoreAddress] = React.useState('');
    const [storeEmail, setStoreEmail] = React.useState('');
    const [storePhone, setStorePhone] = React.useState('');
    const [errorStoreName, setErrorStoreName] = React.useState(false);
    const [errorStoreDescription, setErrorStoreDescription] = React.useState(false);
    const [errorStoreAddress, setErrorStoreAddress] = React.useState(false);
    const [errorStoreEmail, setErrorStoreEmail] = React.useState(false);
    const [errorStorePhone, setErrorStorePhone] = React.useState(false);
    let [isValid, setIsValid] = React.useState<storeValidator>({});

    const stores = useSelector((state: RootState) => state.stores);
    const classes = useStyles();
    

  React.useEffect(() => {
    if (props.auth.user.storeId) dispatch(getStore(props.auth.user.storeId));
  }, [dispatch]);
    
  const createNewStore = (e) => {
    e.preventDefault();

    setErrorStoreName(false)
    setErrorStoreAddress(false)
    setErrorStorePhone(false)
    setErrorStoreEmail(false)
    setErrorStoreDescription(false)

    isValid = StoreValidation({
        name: storeName,
        address: storeAddress, 
        description: storeDescription,
        email: storeEmail,
        phone: storePhone,
      });

      if(isValid.success == true) {
        dispatch(createStore({
            name: storeName,
            address: storeAddress, 
            description: storeDescription,
            email: storeEmail,
            phone: storePhone,
        }));

        
      } else {
        setErrorStoreName((isValid.error && isValid.error.name) ? true : false)
        setErrorStoreAddress((isValid.error && isValid.error.address) ? true : false)
        setErrorStorePhone((isValid.error && isValid.error.phone) ? true : false)
        setErrorStoreEmail((isValid.error && isValid.error.email) ? true : false)
        setErrorStoreDescription((isValid.error && isValid.error.description) ? true : false)
      }
      navigate(`/`);
  };

  return (
    <div className="store-page">
    <Container component="main" maxWidth="xs" className={classes.container}>
      <form className={classes.form}>
      <Grid container item spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop: "40px", marginRight: "auto", marginLeft: "auto"}}>
            {props.ownsAStore ? <h3>YOUR STORE DETAILS</h3> : <h3>REGISTER YOUR STORE</h3>}
            <TextField
              variant="outlined"
              id="filled-email"
              error={errorStoreName}
              label={props.ownsAStore ? ``: `Store Name`}
              helperText={isValid.error ? isValid.error.name[0] : `Please input your store name`}
              name="storeName"
              value={props.ownsAStore ? stores.store.name : storeName}
              onChange={(event) => {
                setStoreName(event.target.value);
              }}
              placeholder="Store Name"
              fullWidth
              required={true}
              className={classes.textField}
            />
            <TextField
              variant="outlined"
              id="filled-store-description"
              error={errorStoreDescription}
              label={props.ownsAStore ? ``: `Store Description`}
              helperText={isValid.error ? isValid.error.description[0] : `Please input your store description`}
              name="storeDescription"
              value={props.ownsAStore ? stores.store.description : storeDescription}
              onChange={(event) => {
                setStoreDescription(event.target.value);
              }}
              placeholder="Store Name"
              fullWidth
              required={true}
            />
            <TextField
              variant="outlined"
              id="filled-store-address"
              error={errorStoreAddress}
              label={props.ownsAStore ? ``: `Store Address`}
              helperText={isValid.error ? isValid.error.address[0] : `Please input your store address`}
              name="storeAddress"
              value={props.ownsAStore ? stores.store.address : storeAddress}
              onChange={(event) => {
                setStoreAddress(event.target.value);
              }}
              placeholder="Store Address"
              fullWidth
              required={true}
            />
            <TextField
              variant="outlined"
              id="filled-store-email"
              error={errorStoreEmail}
              label={props.ownsAStore ? ``: `Store Email`}
              helperText={isValid.error ? isValid.error.email[0] : `Please input your store email`}
              name="storeEmail"
              value={props.ownsAStore ? stores.store.email : storeEmail}
              onChange={(event) => {
                setStoreEmail(event.target.value);
              }}
              placeholder="Store Email"
              fullWidth
              required={true}
            />
            <TextField
              variant="outlined"
              id="filled-store-phone"
              error={errorStorePhone}
              label={props.ownsAStore ? ``: `Store Phone`}
              helperText={isValid.error ? isValid.error.phone[0] : `Please input your store phone`}
              name="storePhone"
              value={props.ownsAStore ? stores.store.phone : storePhone}
              onChange={(event) => {
                setStorePhone(event.target.value);
              }}
              placeholder="Store Phone"
              fullWidth
              required={true}
            />
            { props.ownsAStore ? <></> : (<Button color="primary" size="medium" onClick={createNewStore}>Register</Button>)}
          </Grid>
      </Grid>
      </form>
      </Container>
    </div>
  );
}

export default Store;