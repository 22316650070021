import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store/rootReducer';
import { useNavigate } from "react-router-dom";

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/joy/IconButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {
    Avatar,
    Stack,
} from '@mui/material';

import { getProducts, deleteProduct } from '../../store/modules/products';
import { getOrders, deleteOrder } from '../../store/modules/orders';


import {
    Inbox as InboxIcon,
    // Drafts as DraftsIcon,
    // Star as StarIcon,
    // Comment as CommentIcon,
    Close as CloseIcon,
    Image as ImageIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Padding,
} from '@mui/icons-material';


interface Column {
    id: 'id' | 'total' | 'address' | 'date' | 'status';
    label: string;
    minWidth?: number;
    align?: 'center';
    format?: (value: number) => string;
}

const columns: readonly Column[] = [
    {
        id: 'id',
        label: 'Id',
        minWidth: 270,
        align: 'center',
        format: (value: number) => value.toFixed(2),
    },
    { id: 'total', label: 'Total', minWidth: 170 },
    {
        id: 'address',
        label: 'Address',
        minWidth: 170,
        align: 'center',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'date',
        label: 'Date',
        minWidth: 170,
        align: 'center',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    { id: 'status', label: 'Status', minWidth: 200 },
];

interface Data {
    name: string;
    code: string;
    population: number;
    size: number;
    density: number;
}

function createData(
    name: string,
    code: string,
    population: number,
    size: number,
): Data {
    const density = population / size;
    return { name, code, population, size, density };
}

const rows = [
    createData('India', 'IN', 1324171354, 3287263),
    createData('China', 'CN', 1403500365, 9596961),
    createData('Italy', 'IT', 60483973, 301340),
    createData('United States', 'US', 327167434, 9833520),
    createData('Canada', 'CA', 37602103, 9984670),
    createData('Australia', 'AU', 25475400, 7692024),
    createData('Germany', 'DE', 83019200, 357578),
    createData('Ireland', 'IE', 4857000, 70273),
    createData('Mexico', 'MX', 126577691, 1972550),
    createData('Japan', 'JP', 126317000, 377973),
    createData('France', 'FR', 67022000, 640679),
    createData('United Kingdom', 'GB', 67545757, 242495),
    createData('Russia', 'RU', 146793744, 17098246),
    createData('Nigeria', 'NG', 200962417, 923768),
    createData('Brazil', 'BR', 210147125, 8515767),
];

// interfaces
import { ProductProps, ProductState } from './interfaces';
import { Navigate } from 'react-router-dom';

const UserOrders = (props: any) => {
    const [open, setOpen] = React.useState(false);
    const [productId, setProductId] = React.useState(0);
    const orders = useSelector((state: RootState) => state.orders);
    
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    
    const dispatch = useDispatch<any>();
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const navigate = useNavigate();

    const handleDeleteProduct = () => (e) => {
        e.preventDefault();
        setOpen(false);
        dispatch(deleteProduct(productId));
        dispatch(getOrders());
        // navigate(`/edit-product/${productId}`);
        // window.location.reload();
    }

    React.useEffect(() => {
        dispatch(getOrders());
    }, [dispatch]);

    const handleClickOpen = (productId) => (e) => {
        e.preventDefault();
        setOpen(true);
        setProductId(productId);
      };
    
      const handleClose = () => {
        setOpen(false);
        Navigate
      };
    

    return (
        <div>
        <h3>Store Products</h3>
      
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align ? column.align : 'center'}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orders.data.map((order) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} >
                                    <TableCell key={order.total}>
                                        {order.total}
                                    </TableCell>
                                    <TableCell key={order.status}>
                                        {order.status}
                                    </TableCell>
                                    <TableCell key={order.id}>
                                        {order.id}
                                    </TableCell>
                                    <TableCell key={order.user.address}>
                                        {order.user.address}
                                    </TableCell>
                                    <TableCell key={order.createdAt}>
                                        {order.createdAt}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
        <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to delete this product
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleDeleteProduct()} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
        </div>
    );
}

export default UserOrders;