import { useState } from 'react';
import * as React from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

const SearchBar = ({ onSearch }) => {
  const [searchValue, setSearchValue] = useState('');

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    onSearch(searchValue);
  };

  const handleClearSearch = (event) => {
    event.preventDefault();
    setSearchValue('');
    onSearch('');
  };

  return (
    <form onSubmit={handleSearchSubmit}>
      <TextField
        label="Search"
        value={searchValue}
        onChange={handleSearchChange}
        variant="standard"
        fullWidth={true}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {searchValue ? (
                <IconButton onClick={handleClearSearch} aria-label="clear search">
                  <ClearIcon />
                </IconButton>
              ) : null}
              <IconButton type="submit" aria-label="search">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </form>
  );
};

export default SearchBar;
