export const GET_BRANDS_SUCCESS = 'xences/brands/GET_BRANDS_SUCCESS';
export type GET_BRANDS_SUCCESS = typeof GET_BRANDS_SUCCESS;

export const BRANDS_ERROR = 'xences/brands/BRANDS_ERROR';
export type BRANDS_ERROR = typeof BRANDS_ERROR;

export const DELETE_BRAND_SUCCESS = 'xences/brands/DELETE_BRAND_SUCCESS';
export type DELETE_BRAND_SUCCESS = typeof DELETE_BRAND_SUCCESS;

export const GET_BRAND_SUCCESS = 'xences/brands/GET_BRAND_SUCCESS';
export type GET_BRAND_SUCCESS = typeof GET_BRAND_SUCCESS;

export const CREATE_BRAND_SUCCESS = 'xences/brands/CREATE_BRAND_SUCCESS ';
export type CREATE_BRAND_SUCCESS  = typeof CREATE_BRAND_SUCCESS ;

export const EDIT_BRAND_SUCCESS = 'xences/brands/EDIT_BRAND_SUCCESS';
export type EDIT_BRAND_SUCCESS  = typeof EDIT_BRAND_SUCCESS;
