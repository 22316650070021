export const GET_CATEGORIES_SUCCESS = 'xences/categories/GET_CATEGORIES_SUCCESS';
export type GET_CATEGORIES_SUCCESS = typeof GET_CATEGORIES_SUCCESS;

export const GET_ALL_PARENT_CATEGORIES_SUCCESS = 'xences/categories/GET_ALL_PARENT_CATEGORIES_SUCCESS';
export type GET_ALL_PARENT_CATEGORIES_SUCCESS = typeof GET_ALL_PARENT_CATEGORIES_SUCCESS;

export const CATEGORIES_ERROR = 'xences/categories/CATEGORIES_ERROR';
export type CATEGORIES_ERROR = typeof CATEGORIES_ERROR;

export const DELETE_CATEGORY_SUCCESS = 'xences/categories/DELETE_CATEGORY_SUCCESS';
export type DELETE_CATEGORY_SUCCESS = typeof DELETE_CATEGORY_SUCCESS;

export const GET_CATEGORY_SUCCESS = 'xences/categories/GET_CATEGORY_SUCCESS';
export type GET_CATEGORY_SUCCESS = typeof GET_CATEGORY_SUCCESS;

export const CREATE_CATEGORY_SUCCESS = 'xences/categories/CREATE_CATEGORY_SUCCESS ';
export type CREATE_CATEGORY_SUCCESS  = typeof CREATE_CATEGORY_SUCCESS ;

export const EDIT_CATEGORY_SUCCESS = 'xences/categories/EDIT_CATEGORY_SUCCESS';
export type EDIT_CATEGORY_SUCCESS  = typeof EDIT_CATEGORY_SUCCESS;
