import axios from 'axios';
import urlConfig from '../../../urlConfig';

// interfaces
import {
  Attribute,
  GetAttributeSuccess,
  AttributesError,
  GetAttributesSuccess,
  EditAttributeSuccess,
  CreateAttributeSuccess,
  DeleteAttributeSuccess
} from './interfaces';

// types
import {
  CREATE_ATTRIBUTE_SUCCESS,
  DELETE_ATTRIBUTE_SUCCESS,
  EDIT_ATTRIBUTE_SUCCESS,
  ATTRIBUTES_ERROR,
  GET_ATTRIBUTE_SUCCESS,
  GET_ATTRIBUTES_SUCCESS,
} from './types';

import { attributes } from './fixtures';
import { Attributes } from 'react';




/**
 * action creator
 *
 * Create attributes success action creator
 *
 * @param {Attribute} attribute
 *
 * @returns {CreateAttributeSuccess}
 */

export const createAttributeSuccess = (attribute: Attribute): CreateAttributeSuccess => ({
  attribute,
  type: CREATE_ATTRIBUTE_SUCCESS,
});

/**
 * action creator
 *
 * Get attribute success action creator
 *
 * @param {Attribute[]} attribute
 *
 * @returns {GetAttributesSuccess}
 */
export const getAttributesSuccess = (data: Attribute[]): GetAttributesSuccess => ({
  attributes: data,
  type: GET_ATTRIBUTES_SUCCESS,
});


export const attributeError = (error): AttributesError => ({
    type: ATTRIBUTES_ERROR,
    error
  });
/**
 * action creator
 *
 * Get an attribute success action creator
 *
 * @param {Attribute} attribute
 *
 * @returns {GetAttributeSuccess}
 */
export const getAttributeSuccess = (data: Attribute): GetAttributeSuccess => ({
  attribute: data,
  type: GET_ATTRIBUTE_SUCCESS,
});

/**
 * action creator
 *
 * Delete specific attribute success action creator
 *
 * @param {Attribute} attribute
 *
 * @returns {DeleteAttributeSuccess}
 */
export const deleteAttributeSuccess = (attributeId: string): DeleteAttributeSuccess => ({
  id: attributeId,
  type: DELETE_ATTRIBUTE_SUCCESS,
});

/**
 * action creator
 *
 * Update attribute success action creator
 *
 * @param {Attribute} attribute
 *
 * @returns {EditAttributeSuccess}
 */
export const editAttributeSuccess = (attribute: Attribute): EditAttributeSuccess => ({
  attribute,
  type: EDIT_ATTRIBUTE_SUCCESS,
});

/**
 * Thunk
 *
 * Create attribute thunk
 *
 * @param {Object} attribute
 *
 * @returns {Function}
 */
export const createAttribute = (attribute: Attribute) => dispatch => axios.post(
  `${urlConfig.apiUrl}/attributes/`, { attribute }
)
  .then((response) => {
    dispatch(createAttributeSuccess(response.data));
  })
  .catch(error =>
    dispatch(attributeError({
      status: error.response.status,
      data: error.response.data
    }))
  );

/**
 * Thunk
 *
 * Delete attribute thunk
 *
 * @param {string} attributeId
 *
 * @returns {Function}
 */
export const deleteAttribute = attributeId => dispatch => axios.delete(
  `${urlConfig.apiUrl}/attributes/${attributeId}`
)
  .then(() => {
    dispatch(deleteAttributeSuccess(attributeId));
  })
  .catch((error) =>
    dispatch(attributeError({
      status: error.response.status,
      data: error.response.data
    }))
  );

  /**
 * Thunk
 *
 * Delete attribute thunk
 *
 * @param {string} attributeId
 *
 * @returns {Function}
 */
export const getAttribute = attributeId => dispatch => axios.get(
  `${urlConfig.apiUrl}/attributes/${attributeId}`
)
  .then((response) => {
    dispatch(getAttributeSuccess(response.data.data));
  })
  .catch((error) =>
    dispatch(attributeError({
      status: error.response.status,
      data: error.response.data
    }))
  );

/**
 * Thunk
 *
 * Get attributes thunk
 *
 * @returns {Function}
 */
export const getAttributes = () => dispatch => {
  return axios.get(
  `${urlConfig.apiUrl}/attributes`
)
  .then(response => {
    return dispatch(getAttributesSuccess(response.data.data))
  })
  .catch((error) =>
    dispatch(attributeError({
      status: error.response.status,
      data: error.response.data
    }))
  );
}

/**
 * Thunk
 *
 * Edit an attribute thunk
 *
 * @param {string} attributeId
 * @param {Object} updatedRolePayload
 *
 * @returns {Funciton}
 */
export const editAttribute = (attribute: Attribute) => dispatch => axios.put(
  `${urlConfig.apiUrl}/attributes/${attribute.id}`, {attribute}
)
  .then((response) => {
    dispatch(getAttributeSuccess(response.data.data));
  })
  .catch((error) =>
    dispatch(attributeError({
      status: error.response.status,
      data: error.response.data
    }))
  );

// Set the initial role state
const attributeInitialState = {
  data: [],
  attribute: {},
  meta: {},
  errors: {},
  isLoading: false
};

/**
 * Redux reducer for User Role actions
 *
 * This reducer changes the attribute state of the application
 *
 * @param {AttributeState} state Reducer initial state
 * @param {Action} action
 *
 * @returns {AttributeState} new state
 */
const reducer = (state = attributeInitialState, action) => {
  switch (action.type) {
    case CREATE_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        data: [action.attribute, ...state.data],
      };
    case EDIT_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        data: state.data.map(attribute =>
          attribute.id === action.attribute.id ? action.attribute : attribute),
      };
    case DELETE_ATTRIBUTE_SUCCESS:
      const updatedAttributeList = state.data.filter(attribute => attribute.id !== action.attributeId);
      return {
        ...state,
        data: updatedAttributeList,
      };
    case GET_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case GET_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        data: action.attributes,
        meta: action.meta,
        isLoading: action.isLoading,
      };
    case ATTRIBUTES_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
};

export default reducer;
