import axios from 'axios';
import urlConfig from '../../../urlConfig';

// interfaces
import {
  Brand,
  GetBrandSuccess,
  BrandsError,
  GetBrandsSuccess,
  EditBrandSuccess,
  CreateBrandSuccess,
  DeleteBrandSuccess
} from './interfaces';

// types
import {
  CREATE_BRAND_SUCCESS,
  DELETE_BRAND_SUCCESS,
  EDIT_BRAND_SUCCESS,
  BRANDS_ERROR,
  GET_BRAND_SUCCESS,
  GET_BRANDS_SUCCESS,
} from './types';

import { brands } from './fixtures';

/**
 * action creator
 *
 * Create brands success action creator
 *
 * @param {Brand} brand
 *
 * @returns {CreateBrandSuccess}
 */

export const createBrandSuccess = (brand: Brand): CreateBrandSuccess => ({
  brand,
  type: CREATE_BRAND_SUCCESS,
});

/**
 * action creator
 *
 * Get brand success action creator
 *
 * @param {Brand[]} brand
 *
 * @returns {GetBrandsSuccess}
 */
export const getBrandsSuccess = (data: Brand[]): GetBrandsSuccess => ({
  brands: data,
  type: GET_BRANDS_SUCCESS,
});


export const brandError = (error): BrandsError => ({
    type: BRANDS_ERROR,
    error
  });
/**
 * action creator
 *
 * Get an brand success action creator
 *
 * @param {Brand} brand
 *
 * @returns {GetBrandSuccess}
 */
export const getBrandSuccess = (data: Brand): GetBrandSuccess => ({
  brand: data,
  type: GET_BRAND_SUCCESS,
});

/**
 * action creator
 *
 * Delete specific brand success action creator
 *
 * @param {Brand} brand
 *
 * @returns {DeleteBrandSuccess}
 */
export const deleteBrandSuccess = (brandId: string): DeleteBrandSuccess => ({
  id: brandId,
  type: DELETE_BRAND_SUCCESS,
});

/**
 * action creator
 *
 * Update brand success action creator
 *
 * @param {Brand} brand
 *
 * @returns {EditBrandSuccess}
 */
export const editBrandSuccess = (brand: Brand): EditBrandSuccess => ({
  brand,
  type: EDIT_BRAND_SUCCESS,
});

/**
 * Thunk
 *
 * Create brand thunk
 *
 * @param {Object} brand
 *
 * @returns {Function}
 */
export const createBrand = (brand: Brand) => dispatch => axios.post(
  `${urlConfig.apiUrl}/brands/`, { ...brand }
)
  .then((response) => {
    dispatch(createBrandSuccess(response.data));
  })
  .catch(error =>
    dispatch(brandError({
      status: error.response.status,
      data: error.response.data
    }))
  );

/**
 * Thunk
 *
 * Delete brand thunk
 *
 * @param {string} brandId
 *
 * @returns {Function}
 */
export const deleteBrand = brandId => dispatch => axios.delete(
  `${urlConfig.apiUrl}/brands/${brandId}`
)
  .then(() => {
    dispatch(deleteBrandSuccess(brandId));
  })
  .catch((error) =>
    dispatch(brandError({
      status: error.response.status,
      data: error.response.data
    }))
  );

  /**
 * Thunk
 *
 * Delete brand thunk
 *
 * @param {string} brandId
 *
 * @returns {Function}
 */
export const getBrand = brandId => dispatch => axios.get(
  `${urlConfig.apiUrl}/brands/${brandId}`
)
  .then((response) => {
    dispatch(getBrandSuccess(response.data.data));
  })
  .catch((error) =>
    dispatch(brandError({
      status: error.response.status,
      data: error.response.data
    }))
  );

/**
 * Thunk
 *
 * Get brands thunk
 *
 * @returns {Function}
 */
export const getBrands = () => dispatch => {
  return axios.get(
  `${urlConfig.apiUrl}/brands`
)
  .then(response => {
    return dispatch(getBrandsSuccess(response.data.data))
  })
  .catch((error) =>
    dispatch(brandError({
      status: error.response.status,
      data: error.response.data
    }))
  );
}

/**
 * Thunk
 *
 * Edit an brand thunk
 *
 * @param {string} brandId
 * @param {Object} updatedRolePayload
 *
 * @returns {Funciton}
 */
export const editBrand = (brand: Brand) => dispatch => {
  return axios.put(
  `${urlConfig.apiUrl}/brands/${brand.id}`, {...brand}
)
  .then((response) => {
    dispatch(getBrandSuccess(response.data.data));
  })
  .catch((error) =>
    dispatch(brandError({
      status: error.response.status,
      data: error.response.data
    }))
  );
}

// Set the initial role state
const brandInitialState = {
  data: [],
  brand: {},
  meta: {},
  errors: {},
  isLoading: false
};

/**
 * Redux reducer for User Role actions
 *
 * This reducer changes the brand state of the application
 *
 * @param {BrandState} state Reducer initial state
 * @param {Action} action
 *
 * @returns {BrandState} new state
 */
const reducer = (state = brandInitialState, action) => {
  switch (action.type) {
    case CREATE_BRAND_SUCCESS:
      return {
        ...state,
        data: [action.brand, ...state.data],
      };
    case EDIT_BRAND_SUCCESS:
      return {
        ...state,
        data: state.data.map(brand =>
          brand.id === action.brand.id ? action.brand : brand),
      };
    case DELETE_BRAND_SUCCESS:
      const updatedBrandList = state.data.filter(brand => brand.id !== action.brandId);
      return {
        ...state,
        data: updatedBrandList,
      };
    case GET_BRAND_SUCCESS:
      return {
        ...state,
        brand: action.brand,
        isLoading: action.isLoading,
      };
    case GET_BRANDS_SUCCESS:
      return {
        ...state,
        data: action.brands,
        meta: action.meta,
        isLoading: action.isLoading,
      };
    case BRANDS_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
};

export default reducer;
