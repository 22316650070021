

export default {
  // apiUrl: process.env.NODE_ENV === 'production'
  //   ? 'https://naijapetstore.com/api' : 'http://localhost:4022/api',
    apiUrl: process.env.NODE_ENV === 'production'
      ? 'https://naijapetstore.com/api' : 'https://naijapetstore.com/api',
  options: { headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS, PUT",
    "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With"
  }},
};
