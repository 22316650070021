// import firebase from 'firebase/app';
import { initializeApp } from "firebase/app";
import { getApp } from "firebase/app";

import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
// import 'firebase/firestore';
// import 'firebase/auth';

// const config = {
//   apiKey: "AIzaSyBeAIaAlZ_xD7zV0tuVPVeCnkX2JTz3YXY",
//   authDomain: "pmapp-c014b.firebaseapp.com",
//   projectId: "pmapp-c014b",
//   storageBucket: "pmapp-c014b.appspot.com",
//   messagingSenderId: "175487366865",
//   appId: "1:175487366865:web:b5230cf1307dcf4dc8f7f0",
//   measurementId: "G-3KC8RVLBHE"
// };

const firebaseConfig = {
  apiKey: "AIzaSyBHmbLKc6K8zSXmC8jo7e_3QSPQVEWoP6s",
  authDomain: "pmbk-a17f3.firebaseapp.com",
  projectId: "pmbk-a17f3",
  storageBucket: "pmbk-a17f3.appspot.com",
  messagingSenderId: "96752968041",
  appId: "1:96752968041:web:ed73f1887c725ebcad5df8",
  measurementId: "G-T33HYTCF11"
};

// Initialize Firebase
// firebase.initializeApp(config);
// if (!firebase.apps.length) {
//   firebase.initializeApp(config);
//   firebase.analytics();
// }

// export const auth = firebase.auth();
// export const firestore = firebase.firestore();

// const provider = new firebase.auth.GoogleAuthProvider();
// provider.setCustomParameters({ prompt: 'select_account' });
// export const signInWithGoogle = () => auth.signInWithPopup(provider);

// const storage = firebase.storage()

// export default firebase;
// export {
//   storage, firebase as default
// }

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const storage = getStorage(firebaseApp);


// Initialize Firebase Authentication and get a reference to the service
// const auth = getAuth(app);


// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyBHmbLKc6K8zSXmC8jo7e_3QSPQVEWoP6s",
//   authDomain: "pmbk-a17f3.firebaseapp.com",
//   projectId: "pmbk-a17f3",
//   storageBucket: "pmbk-a17f3.appspot.com",
//   messagingSenderId: "96752968041",
//   appId: "1:96752968041:web:8ce8f6a4a1d1aa36ad5df8",
//   measurementId: "G-424P301DHL"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);