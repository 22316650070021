// auth actions
export const SET_CURRENT_USER = 'pm-app/auth/SET_CURRENT_USER';
export type SET_CURRENT_USER = typeof SET_CURRENT_USER;

export const SIGN_UP_ERRORS = 'pm-app/auth/SIGN_UP_ERRORS';
export type SIGN_UP_ERRORS = typeof SIGN_UP_ERRORS;

export const SET_CURRENT_USER_FAIL = 'pm-app/auth/SET_CURRENT_USER_FAIL';
export type SET_CURRENT_USER_FAIL = typeof SET_CURRENT_USER_FAIL;

export const SET_CURRENT_USER_ERROR = 'pm-app/auth/SET_CURRENT_USER_ERROR';
export type SET_CURRENT_USER_ERROR = typeof SET_CURRENT_USER_ERROR;

export const SIGNOUT_USER = 'pm-app/auth/SIGNOUT_USER ';
export type SIGNOUT_USER  = typeof SIGNOUT_USER;
