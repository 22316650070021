import axios from 'axios';
import urlConfig from '../../../urlConfig';

// interfaces
import {
  Category,
  GetCategorySuccess,
  CategoriesError,
  GetCategoriesSuccess,
  GetAllParentCategoriesSuccess,
  EditCategorySuccess,
  CreateCategorySuccess,
  DeleteCategorySuccess
} from './interfaces';

// types
import {
  CREATE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_SUCCESS,
  EDIT_CATEGORY_SUCCESS,
  CATEGORIES_ERROR,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORIES_SUCCESS,
  GET_ALL_PARENT_CATEGORIES_SUCCESS,
} from './types';

import { categories } from './fixtures';




/**
 * action creator
 *
 * Create categories success action creator
 *
 * @param {Category} category
 *
 * @returns {CreateCategorySuccess}
 */

export const createCategorySuccess = (category: Category): CreateCategorySuccess => ({
  category,
  type: CREATE_CATEGORY_SUCCESS,
});

/**
 * action creator
 *
 * Get category success action creator
 *
 * @param {Category[]} category
 *
 * @returns {GetCategoriesSuccess}
 */
export const getCategoriesSuccess = (data: Category[]): GetCategoriesSuccess => ({
  categories: data,
  type: GET_CATEGORIES_SUCCESS,
});

/**
 * action creator
 *
 * Get category success action creator
 *
 * @param {Category[]} category
 *
 * @returns {GetAllParentCategoriesSuccess}
 */
export const getAllParentCategoriesSuccess = (data: Category[]): GetAllParentCategoriesSuccess => ({
  parents: data,
  type: GET_ALL_PARENT_CATEGORIES_SUCCESS,
});


export const categoryError = (error): CategoriesError => ({
    type: CATEGORIES_ERROR,
    error
  });
/**
 * action creator
 *
 * Get an category success action creator
 *
 * @param {Category} category
 *
 * @returns {GetCategorySuccess}
 */
export const getCategorySuccess = (data: Category): GetCategorySuccess => ({
  category: data,
  type: GET_CATEGORY_SUCCESS,
});

/**
 * action creator
 *
 * Delete specific category success action creator
 *
 * @param {Category} category
 *
 * @returns {DeleteCategorySuccess}
 */
export const deleteCategorySuccess = (categoryId: string): DeleteCategorySuccess => ({
  id: categoryId,
  type: DELETE_CATEGORY_SUCCESS,
});

/**
 * action creator
 *
 * Update category success action creator
 *
 * @param {Category} category
 *
 * @returns {EditCategorySuccess}
 */
export const editCategorySuccess = (category: Category): EditCategorySuccess => ({
  category,
  type: EDIT_CATEGORY_SUCCESS,
});

/**
 * Thunk
 *
 * Create category thunk
 *
 * @param {Object} category
 *
 * @returns {Function}
 */
export const createCategory = (category: Category) => dispatch => axios.post(
  `${urlConfig.apiUrl}/categories/`, { ...category }
)
  .then((response) => {
    dispatch(createCategorySuccess(response.data));
  })
  .catch(error =>
    dispatch(categoryError({
      status: error.response.status,
      data: error.response.data
    }))
  );

/**
 * Thunk
 *
 * Delete category thunk
 *
 * @param {string} categoryId
 *
 * @returns {Function}
 */
export const deleteCategory = categoryId => dispatch => axios.delete(
  `${urlConfig.apiUrl}/categories/${categoryId}`
)
  .then(() => {
    dispatch(deleteCategorySuccess(categoryId));
  })
  .catch((error) =>
    dispatch(categoryError({
      status: error.response.status,
      data: error.response.data
    }))
  );

  /**
 * Thunk
 *
 * Delete category thunk
 *
 * @param {string} categoryId
 *
 * @returns {Function}
 */
export const getCategory = categoryId => dispatch => axios.get(
  `${urlConfig.apiUrl}/categories/${categoryId}`
)
  .then((response) => {
    dispatch(getCategorySuccess(response.data.data));
  })
  .catch((error) =>
    dispatch(categoryError({
      status: error.response.status,
      data: error.response.data
    }))
  );

/**
 * Thunk
 *
 * Get parent categories thunk with its children
 *
 * @returns {Function}
 */
export const getCategories = () => dispatch => {
  return axios.get(
  `${urlConfig.apiUrl}/categories`
)
  .then(response => {
    return dispatch(getCategoriesSuccess(response.data.data))
  })
  .catch((error) =>
    dispatch(categoryError({
      status: error.response.status,
      data: error.response.data
    }))
  );
}

/**
 * Thunk
 *
 * Get parent categories thunk with its children
 *
 * @returns {Function}
 */
export const getAllParentCategories = () => dispatch => {
  return axios.get(
  `${urlConfig.apiUrl}/categories`
)
  .then(response => {
    return dispatch(getAllParentCategoriesSuccess(response.data.data))
  })
  .catch((error) =>
    dispatch(categoryError({
      status: error.response.status,
      data: error.response.data
    }))
  );
}


/**
 * Thunk
 *
 * Get parent categories thunk with its children
 *
 * @returns {Function}
 */
export const getAllCategories = () => dispatch => {
  return axios.get(
  `${urlConfig.apiUrl}/categories/all`
)
  .then(response => {
    return dispatch(getCategoriesSuccess(response.data.data))
  })
  .catch((error) =>
    dispatch(categoryError({
      status: error.response.status,
      data: error.response.data
    }))
  );
}


/**
 * Thunk
 *
 * Edit an category thunk
 *
 * @param {string} categoryId
 * @param {Object} updatedRolePayload
 *
 * @returns {Funciton}
 */
export const editCategory = (category: Category) => dispatch => axios.put(
  `${urlConfig.apiUrl}/categories/${category.id}`, {...category}
)
  .then((response) => {
    dispatch(getCategorySuccess(response.data.data));
  })
  .catch((error) =>
    dispatch(categoryError({
      status: error.response.status,
      data: error.response.data
    }))
  );

// Set the initial role state
const categoryInitialState = {
  parents: [],
  data: [],
  category: {},
  meta: {},
  errors: {},
  isLoading: false
};

/**
 * Redux reducer for User Role actions
 *
 * This reducer changes the category state of the application
 *
 * @param {CategoryState} state Reducer initial state
 * @param {Action} action
 *
 * @returns {CategoryState} new state
 */
const reducer = (state = categoryInitialState, action) => {
  switch (action.type) {
    case CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        data: [action.category, ...state.data],
      };
    case EDIT_CATEGORY_SUCCESS:
      return {
        ...state,
        data: state.data.map(category =>
          category.id === action.category.id ? action.category : category),
      };
    case DELETE_CATEGORY_SUCCESS:
      const updatedCategoryList = state.data.filter(category => category.id !== action.categoryId);
      return {
        ...state,
        data: updatedCategoryList,
      };
    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        category: action.category,
        isLoading: action.isLoading,
      };
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        data: action.categories,
        meta: action.meta,
        isLoading: action.isLoading,
      };
    case GET_ALL_PARENT_CATEGORIES_SUCCESS:
      return {
        ...state,
        parents: action.parents,
        meta: action.meta,
        isLoading: action.isLoading,
      };
    case CATEGORIES_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
};

export default reducer;
