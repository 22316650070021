// react libraries
import * as React from 'react';

// third-party libraries
import { connect } from 'react-redux';

// third party packages
import { BrowserRouter as Router, HashRouter, Link, matchPath, Navigate, Route, Routes, useParams, useLocation, useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux'
// import { withRouter } from 'react-router'; 
// components
import NavigationBar from '../components/navbar/NavigationBar';
import HomePage from '../pages/HomePage';
import Auth from '../components/auth/Auth';
import StoreProducts from '../components/product/StoreProducts';
import ProfilePage from '../components/profile/Profile';
import NewProduct from '../components/product/NewProduct';
import NewCategory from '../components/product/NewCategory';
import NewBrand from '../components/product/NewBrand';
// import ProductsPage2 from '../components/comment/ProductsPage2';
import ProductDetails from '../components/product/ProductDetails';
import RegisterStore from '../components/store/RegisterStore';
import UserOrders from '../components/order/UserOrders';
import Cart from '../components/cart/Cart';
import { decodeToken } from '../helpers/authorize';
import { getCarts, loadCart } from '../store/modules/carts';
import { getProduct, getProducts, productError } from '../store/modules/products';
import { Product } from '../store/modules/products/interfaces';

import { getBrands } from '../store/modules/brands';
import { getCategories } from '../store/modules/categories';
import { getAttribute, getAttributes } from '../store/modules/attributes';
import { editOrder, getOrders, deleteOrder  } from '../store/modules/orders';
import { getUser } from '../store/modules/users';


// interfaces
import { AppRouteProps, AppRouteState } from './interfaces';

import { Box, Container, CssBaseline, Toolbar } from '@mui/material';

export class AppRoutes extends React.Component<AppRouteProps, AppRouteState> {
  // export const AppRoutes = (props: any) => {

  initialState = {
    userCart: [],
    totalCartItems: 0,
    totalCart: {},
    productTotal: {},
    ownsAStore: false,
    selectedCategories: [],
    selectedBrands: [],
    priceValue: [0, 900000],
    openCategories: [],
  };

  state = this.initialState;

  componentDidMount() {
    this.updateState();
  }

  fetchProducts() {
    const { priceValue, selectedCategories, selectedBrands } = this.state;
    this.props.getProducts({
      searchQuery: '',
      brandList: selectedBrands,
      categoryList: selectedCategories,
      priceRange: priceValue,
    });
  }

  updateState() {
    // Perform the state update logic here
    this.fetchProducts();
    this.props.getBrands();
    this.props.getAttributes();
    this.props.getCategories();
    // this.props.getOrders(); 
    this.calculateCartTotal();
    const userCart = JSON.parse(localStorage.getItem('userCart')) || [];
    this.props.loadCart(userCart);
    // getUser(1)
    // console.log(this.props.auth.user);
    // console.log(this.props.auth.user.userId);
    this.setState({ ownsAStore: (this.props.auth.user.storeId && this.props.auth.user.storeId > 0)})
  }

  // const { auth, signoutUser } = props;
  // const userCartLocalStorage = JSON.parse(localStorage.getItem('userCart'));
  // const [userCart, setUserCart] = React.useState<Product>();
  // const [totalCartItems, setTotalCartItems] = React.useState(0);
  // const [totalCart, setTotalCart] = React.useState({});
  // const [productTotal, setProductTotal] = React.useState({});

  setUserCart = (userCart) => {
    this.setState((prevState) =>({
      userCart,
    }))
  }

  redirectToHomePageIfNotAuth = () => {
    // Get the current URL pathname
    const pathname = window.location.pathname;
  
    // Define the homepage path
    const homepagePath = '/'; // Update this to match your homepage path
  
    // Check if the current pathname is not the homepage
    if (pathname !== homepagePath) {
      // Redirect to the homepage
      window.location.href = homepagePath;
    }
  }

  handleCategorySelection = (event, categoryId) => {
    event.preventDefault();
    const { selectedCategories } = this.state;

    if (selectedCategories.includes(categoryId)) {
      this.setState((prevState) => ({
        selectedCategories: prevState.selectedCategories.filter((id) => id !== categoryId),
      }), () => this.fetchProducts());
    } else {
      this.setState(
        (prevState) => ({
        selectedCategories: [...prevState.selectedCategories, categoryId],
      }), () => this.fetchProducts());
    }
  };

  handleNestedCategoryToggle = (categoryId) => {
    event.preventDefault();
    this.setState((prevState) => ({
      openCategories: prevState.openCategories.includes(categoryId)
        ? prevState.openCategories.filter((id) => id !== categoryId)
        : [...prevState.openCategories, categoryId],
    }));
  };

  handleBrandSelection = (event, brandId) => {
    event.preventDefault();
    const { selectedBrands } = this.state;

    if (selectedBrands.includes(brandId)) {
      this.setState((prevState) => ({
        selectedBrands: prevState.selectedBrands.filter((id) => id !== brandId),
      }), () => this.fetchProducts());
    } else {
      this.setState((prevState) => ({
        selectedBrands: [...prevState.selectedBrands, brandId],
      }), () => this.fetchProducts());
    }
  };
  
  handlePriceChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
    ) => {
      event.preventDefault();

      this.setState((prevState) => ({
        priceValue: newValue,
      }));
    // this.setState({ priceValue: newValue });
  };
  
  handlePriceButtonClick = (
    newValue: number | number[],
    ) => {
      this.setState((prevState) => ({
        priceValue: newValue,
      }), () => this.fetchProducts());
  };

  calculateCartTotal = () => {
    let totalCartItems = 0;
    let totalCost = 0;
    let productTotal = [];
    const userCart = JSON.parse(localStorage.getItem('userCart')) || [];
    if (userCart)
      userCart.map((product) => {
        totalCartItems += product.cartQuantity;
        totalCost = totalCost + product.cartQuantity * product.price;
        productTotal.push({ id: product.id, totalItemCost: product.cartQuantity * product.price, cartItemQuantity: product.cartQuantity, price: product.price })
      });
    // setTotalCartItems(totalCartItems);
    // setTotalCart({ totalCartItems, totalCost });
    // setProductTotal({ productTotal, totalCost, totalCartItems, });

    this.setState((prevState) => ({
      totalCartItems,
      totalCart: { totalCartItems, totalCost },
      productTotal: { productTotal, totalCost, totalCartItems, }
    }))
  }
  // const dispatch = useDispatch<any>();

  // React.useEffect(() => {
  //   calculateCartTotal();
  // }, []);

  // React.useEffect(() => {
  //   // const userCart = JSON.parse(localStorage.getItem('userCart'));
  //   const userCart = JSON.parse(localStorage.getItem('userCart')) || [];
  //   dispatch(loadCart(userCart));
  // }, [dispatch]);

  // const { id } = useParams();
  // let params = useParams();
  // const decodedToken = decodeToken();
  // const [ownsAStore] = React.useState((this.props.auth.user.storeId && auth.user.storeId > 0) ? true : false);
  render() {
    const { totalCartItems, userCart, productTotal, ownsAStore,
      selectedCategories, selectedBrands, priceValue, openCategories,
    } = this.state;
    

    return (
      <Router >
        <NavigationBar {...{ 
          auth: this.props.auth, ownsAStore, categories: this.props.categories, 
          brands: this.props.brands, orders: this.props.orders, products: this.props.products, 
          fetchProducts: this.fetchProducts, signoutUser: this.props.signoutUser, 
          userCart, setUserCart: this.setUserCart, totalCartItems,
          handlePriceChange: this.handlePriceChange, handleBrandSelection: this.handleBrandSelection, 
          handleNestedCategoryToggle: this.handleNestedCategoryToggle, 
          handleCategorySelection: this.handleCategorySelection,
          handlePriceButtonClick: this.handlePriceButtonClick,
          selectedCategories, selectedBrands, priceValue, openCategories,
          }} />
        <Box component="main" sx={{ p: 3 }}>
          <Toolbar />
          <Container maxWidth={false}>
            <Routes>
              <Route path="/" element={<HomePage {...{ 
                auth: this.props.auth, categories: this.props.categories, brands: this.props.brands, 
                orders: this.props.orders, products: this.props.products, fetchProducts: this.fetchProducts, 
                location, userCart, calculateCartTotal: this.calculateCartTotal, 
                setUserCart: this.setUserCart, productTotal,
                handlePriceChange: this.handlePriceChange, handleBrandSelection: this.handleBrandSelection, 
                handleNestedCategoryToggle: this.handleNestedCategoryToggle, 
                handleCategorySelection: this.handleCategorySelection,
                handlePriceButtonClick: this.handlePriceButtonClick,
                selectedCategories, selectedBrands, priceValue, openCategories,
                
                }} />} />
              <Route path="/cart" element={<Cart {...{ ...this.props, ownsAStore, matchPath, userCart, calculateCartTotal: this.calculateCartTotal, productTotal }} />} />

              <Route path="/auth" element={
                !this.props.auth.isAuthenticated ? <Auth {...{ ...this.props, auth: this.props.auth, signoutUser: this.props.signoutUser }} />
                : <Navigate to="/" replace={true} />
              } />

              <Route path="/profile" element={
                this.props.auth.isAuthenticated 
                  ? <ProfilePage {
                    ...{ auth: this.props.auth, ownsAStore, matchPath }
                  } />
                  : <Navigate to="/auth" replace={true} />
              } />

              <Route path="/store" element={
                this.props.auth.isAuthenticated ? <RegisterStore {...{ ...this.props, ownsAStore, matchPath }} />
                  : <Navigate to="/" replace={true} />
              } />

              <Route path="/user-orders" element={
                this.props.auth.isAuthenticated ? <UserOrders {...{ ...this.props, ownsAStore, matchPath }} />
                  : <Navigate to="/" replace={true} />
              } />

              <Route path="/products" element={
                this.props.auth.isAuthenticated && ownsAStore ? <NewProduct {...{ props: this.props, ownsAStore, matchPath }} />
                  : <Navigate to="/" replace={true} />
              } />
              <Route path="/products/:id" element={
                this.props.auth.isAuthenticated && ownsAStore ? <ProductDetails {...{ props: this.props, ownsAStore, matchPath }} />
                  : <Navigate to="/" replace={true} />
              } />
              <Route path="/edit-product/:id" element={
                this.props.auth.isAuthenticated && ownsAStore ? <NewProduct {...{ props: this.props, ownsAStore, matchPath }} />
                  : <Navigate to="/" replace={true} />
              } />
              <Route path="/store-products" element={
                this.props.auth.isAuthenticated && ownsAStore ? <StoreProducts {...{ props: this.props, ownsAStore, matchPath }} />
                  : <Navigate to="/" replace={true} />
              } />
              <Route path="/store-brands" element={
                this.props.auth.isAuthenticated && ownsAStore ? <NewBrand {...{ props: this.props, ownsAStore, matchPath }} />
                  : <Navigate to="/" replace={true} />
              } />
              <Route path="/store-categories" element={
                this.props.auth.isAuthenticated && ownsAStore ? <NewCategory {...{ props: this.props, ownsAStore, matchPath }} />
                  : <Navigate to="/" replace={true} />
              } />
              <Route path="/edit-brand/:id" element={
                this.props.auth.isAuthenticated && ownsAStore ? <NewBrand {...{ props: this.props, ownsAStore, matchPath }} />
                  : <Navigate to="/" replace={true} />
              } />

              <Route path="*" element={<HomePage {...{ auth: this.props.auth, location,  userCart, setUserCart: this.setUserCart, calculateCartTotal: this.calculateCartTotal, productTotal }} />} />
            </Routes>
          </Container>
        </Box>
      </Router>
    )
  };
};

export const mapStateToProps = state => ({
auth: state.auth,
carts: state.carts.data,
});

export const mapDispatchToProps = dispatch => ({
  loadCart: (cart: Product[]) => dispatch(loadCart(cart)),
  getProducts: (actionData: {
    search: {searchValue},
    brandList: [],
    categoryList: []
  }) => dispatch(getProducts(actionData)),
  getBrands: () => dispatch(getBrands()),
  getUser: (userId: any) => dispatch(getUser(userId)),
  getCarts: () => dispatch(getCarts()),
  getCategories: () => dispatch(getCategories()),
  getAttributes: () => dispatch(getAttributes()),
  getProduct: () => dispatch(getProduct(Number)),
  getOrders: () => dispatch(getOrders()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);



export const AdminAppRoutes = (props: any) => {
  const { auth, signoutUser } = props;

  const userCartLocalStorage = JSON.parse(localStorage.getItem('userCart'));
  const [userCart, setUserCart] = React.useState<Product>();
  const [totalCartItems, setTotalCartItems] = React.useState(0);
  const [totalCart, setTotalCart] = React.useState({});
  const [productTotal, setProductTotal] = React.useState({});

  const calculateCartTotal = () => {
    let totalCartItems = 0;
    let totalCost = 0;
    let productTotal = [];
    const userCart = JSON.parse(localStorage.getItem('userCart')) || [];
    if (userCart) 
      userCart.map((product) => {
      totalCartItems += product.cartQuantity;
      totalCost = totalCost + product.cartQuantity * product.price;
      productTotal.push({ id: product.id, totalItemCost: product.cartQuantity * product.price, cartItemQuantity: product.cartQuantity, price: product.price })
    });
    setTotalCartItems(totalCartItems);
    setTotalCart({ totalCartItems, totalCost });
    setProductTotal({ productTotal, totalCost, totalCartItems, });
  }
  const dispatch = useDispatch<any>();

  
  React.useEffect(() => {
    calculateCartTotal();
  }, []);

  React.useEffect(() => {
    // const userCart = JSON.parse(localStorage.getItem('userCart'));
    const userCart = JSON.parse(localStorage.getItem('userCart')) || [];
    dispatch(loadCart(userCart));
  }, [dispatch]);

  const { id } = useParams();
  let params = useParams();
  const decodedToken = decodeToken();
  const [ownsAStore] = React.useState((auth.user.storeId && auth.user.storeId > 0) ? true : false);
  return (
    <Router >
      <NavigationBar {...{ 
        auth: props.auth, ownsAStore, categories: props.categories, 
        brands: props.brands, orders: props.orders, products: props.products, 
        fetchProducts: props.fetchProducts, signoutUser: props.signoutUser, 
        userCart, setUserCart: setUserCart, totalCartItems,
        // handlePriceChange: handlePriceChange, handleBrandSelection: handleBrandSelection, 
        // handleNestedCategoryToggle: handleNestedCategoryToggle, 
        // handleCategorySelection: handleCategorySelection
       }} />
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
        <Container maxWidth='lg'>
          <Routes>
            <Route path="/" element={<HomePage {...{ auth: props.auth, location, params, userCart, setUserCart, calculateCartTotal, productTotal }} />} />
            <Route path="/cart" element={<Cart {...{ ...props, ownsAStore, matchPath, userCart, calculateCartTotal, productTotal }} />} />

            <Route path="/auth" element={
              auth.isAuthenticated ? <HomePage { ...{ auth: props.auth, location, params, userCart, setUserCart, calculateCartTotal, productTotal }} />
                : <Auth {...{ ...props, auth: props.auth, signoutUser: props.signoutUser }} />
            } />

          <Route path="/profile" element={
            auth.isAuthenticated ? <ProfilePage {...{ auth: props.auth, ownsAStore, matchPath }} />
              : <HomePage {...{ auth: props.auth, location, params, userCart, setUserCart, calculateCartTotal, productTotal }} />
          } />

          <Route path="/store" element={
            auth.isAuthenticated ? <RegisterStore {...{ ...props, ownsAStore, matchPath }} />
              : <HomePage {...{ auth: props.auth, location, params, userCart, setUserCart, calculateCartTotal, productTotal }} />
          } />

            <Route path="/products" element={
              auth.isAuthenticated && ownsAStore ? <NewProduct {...{ props, ownsAStore, matchPath }} />
                : <HomePage {...{ auth: props.auth, location, params, userCart, setUserCart, calculateCartTotal, productTotal }} />
            } />
            <Route path="/products/:id" element={
              auth.isAuthenticated && ownsAStore ? <ProductDetails {...{ props, ownsAStore, matchPath }} />
                : <HomePage {...{ auth: props.auth, location, params, userCart, setUserCart, calculateCartTotal, productTotal }} />
            } />
            <Route path="/edit-product/:id" element={
              auth.isAuthenticated && ownsAStore ? <NewProduct {...{ props, ownsAStore, matchPath }} />
                : <HomePage {...{ auth: props.auth, location, params, userCart, setUserCart, calculateCartTotal, productTotal }} />
            } />
            <Route path="/store-products" element={
              auth.isAuthenticated && ownsAStore ? <StoreProducts {...{ props, ownsAStore, matchPath }} />
                : <HomePage {...{ auth: props.auth, location, params, userCart, setUserCart, calculateCartTotal, productTotal }} />
            } />
            <Route path="/store-brands" element={
              auth.isAuthenticated && ownsAStore ? <NewBrand {...{ props, ownsAStore, matchPath }} />
                : <HomePage {...{ auth: props.auth, location, params, userCart, setUserCart, calculateCartTotal, productTotal }} />
            } />
            <Route path="/edit-brand/:id" element={
              auth.isAuthenticated && ownsAStore ? <NewBrand {...{ props, ownsAStore, matchPath }} />
                : <HomePage {...{ auth: props.auth, location, params, userCart, setUserCart, calculateCartTotal, productTotal }} />
            } />

            <Route path="*" element={<HomePage {...{ auth: props.auth, location, params, userCart, setUserCart, calculateCartTotal, productTotal }} />} />
          </Routes>
        </Container>
      </Box>
    </Router>
  );
};

// export default AppRoutes;
