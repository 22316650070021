export const GET_ORDERS_SUCCESS = 'xences/orders/GET_ORDERS_SUCCESS';
export type GET_ORDERS_SUCCESS = typeof GET_ORDERS_SUCCESS;

export const ORDERS_ERROR = 'xences/orders/ORDERS_ERROR';
export type ORDERS_ERROR = typeof ORDERS_ERROR;

export const DELETE_ORDER_SUCCESS = 'xences/orders/DELETE_ORDER_SUCCESS';
export type DELETE_ORDER_SUCCESS = typeof DELETE_ORDER_SUCCESS;

export const GET_ORDER_SUCCESS = 'xences/orders/GET_ORDER_SUCCESS';
export type GET_ORDER_SUCCESS = typeof GET_ORDER_SUCCESS;

export const CREATE_ORDER_SUCCESS = 'xences/orders/CREATE_ORDER_SUCCESS ';
export type CREATE_ORDER_SUCCESS  = typeof CREATE_ORDER_SUCCESS ;

export const EDIT_ORDER_SUCCESS = 'xences/orders/EDIT_ORDER_SUCCESS';
export type EDIT_ORDER_SUCCESS  = typeof EDIT_ORDER_SUCCESS;
