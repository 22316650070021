// import libraries
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Link, Navigation } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store/rootReducer';

import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  ListSubheader,
  CssBaseline,
  Divider,
  Drawer,
  Typography,
  IconButton,
  InputBase,
  Grid,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Switch,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Menu,
  Collapse,
  Tooltip,
  Checkbox,
  Slider,
  Chip,
  Tabs,
  Tab,
} from '@mui/material';
import { ExpandLess, ExpandMore, Inbox, Drafts, Send, StarBorder } from '@mui/icons-material';

import { styled, alpha } from '@mui/material/styles';

import {
  Inbox as InboxIcon,
  AccountCircle as AccountCircle,
  Menu as MenuIcon,
  Search as SearchIcon,
  ShoppingCart as ShoppingCartIcon,
  Store as StoreIcon,
  PersonAdd,
  Settings,
  Logout as LogoutIcon,
  Mail as MailIcon,
  MoreVert as MoreIcon,
  Category as CategoryIcon,
  Notifications as NotificationsIcon,
  BrandingWatermark as BrandingWatermarkIcon,
  Inventory as InventoryIcon,
  AddCircle as AddCircleIcon,
  Login as LoginIcon,
} from '@mui/icons-material';

import SearchBar from './SearchBar';

import { NavigationBarProps } from './interfaces';

import { getProduct, getProducts, productError } from '../../store/modules/products';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const NavigationBar = (props: NavigationBarProps) => {
  const { auth, signoutUser, totalCartItems } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  // const [selectedBrands, setSelectedBrands] = React.useState([]);
  // const [priceValue, setPriceValue] = React.useState([0, 900000]);
  // const [selectedCategories, setSelectedCategories] = React.useState([]);
  // const [openCategories, setOpenCategories] = React.useState([]);

	const [isModalVisible, setModalVisible] = React.useState(false);

  const showSearchModal = (e) => {
    e.stopPropagation();
    setModalVisible(true);
  };

  const hideSearchModal = (e) => {
    e.stopPropagation();
    setModalVisible(false);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };


  const container = window !== undefined ? () => window.document.body : undefined;
  const drawerWidth = 264;

  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);


  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const [selectedTab, setSelectedTab] = React.useState(0);
  const [searchValue, setSearchValue] = React.useState('');

  const [isMenuActive, setMenuActive] = React.useState(false);


  const handleMobileMenuClick = () => {
    console.log('in btn-show-menu-mobile');
    setMenuActive(!isMenuActive);
  };

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // const handleSearch = () => {
  //   // Perform search action using the searchValue
  //   const searchValueArray = searchValue.split(" ");
  //   dispatch(getProducts({
  //     searchQuery: searchValueArray.join("+"),
  //     brandList: [],
  //     categoryList: [],
  //     priceRange: '',
  //   }));
  // };

	const handleSearch = (searchValue) => {
    // Perform search action using the searchValue
    const searchValueArray = searchValue.split(" ");
    dispatch(getProducts({
      searchQuery: searchValueArray.join("+"),
      brandList: [],
      categoryList: [],
      priceRange: '',
    }));
  };

  const catHeader = location.pathname;
  let splitCatHeader1 = catHeader.split('/');
  let splitCatHeader2;
  const categoryName = splitCatHeader1[1];
  if (splitCatHeader1.length > 2) {
    splitCatHeader2 = `${splitCatHeader1[1].toUpperCase()} ${splitCatHeader1[2].toUpperCase()}`;
  } else {
    splitCatHeader2 = splitCatHeader1[1].toUpperCase();
  }


  const isHomePage = () => {
    // Get the current URL pathname
    const pathname = window.location.pathname;

    // Check if the pathname is equal to the homepage path
    return pathname === '/' || pathname === '/home'; // Add any other paths that represent the homepage here
  }

  return (
    <div>
      	<header>
		{/* <!-- Header desktop --> */}
		<div className="container-menu-desktop">
			{/* <!-- Topbar --> */}
			<div className="top-bar">
				<div className="content-topbar flex-sb-m h-full container">
					<div className="left-top-bar">
						Standard shipping for all orders
					</div>

					<div className="right-top-bar flex-w h-full">
						<a href="#" className="flex-c-m trans-04 p-lr-25">
							Help & FAQs
						</a>

						<a href="/profile" className="flex-c-m trans-04 p-lr-25">
							My Account
						</a>

						<a href="#" className="flex-c-m trans-04 p-lr-25">
							EN
						</a>

						<a href="#" className="flex-c-m trans-04 p-lr-25">
							Naira
						</a>
					</div>
				</div>
			</div>

			<div className="wrap-menu-desktop">
				<nav className="limiter-menu-desktop container">
					
					{/* <!-- Logo desktop -->		 */}
					<a href="#" className="logo">
						<img src="https://firebasestorage.googleapis.com/v0/b/pmbk-a17f3.appspot.com/o/images%2FNaijaPetStore_002.png?alt=media&token=d1713eda-3711-49b4-bae8-1b12c0b25d4c" alt="IMG-LOGO" />
					</a>

					{/* <!-- Menu desktop --> */}
					<div className="menu-desktop">
						{/* <ul className="main-menu">
							<li className="active-menu">
								<a href="index.html">Home</a>
								<ul className="sub-menu">
									<li><a href="index.html">Homepage 1</a></li>
									<li><a href="home-02.html">Homepage 2</a></li>
									<li><a href="home-03.html">Homepage 3</a></li>
								</ul>
							</li>

							<li>
								<a href="product.html">Shop</a>
							</li>

							<li className="label1" data-label1="hot">
								<a href="shoping-cart.html">Features</a>
							</li>

							<li>
								<a href="blog.html">Blog</a>
							</li>

							<li>
								<a href="about.html">About</a>
							</li>

							<li>
								<a href="contact.html">Contact</a>
							</li>
						</ul> */}
					</div>	

					{/* <!-- Icon header --> */}
					<div className="wrap-icon-header flex-w flex-r-m">
						{/* <div className="icon-header-item cl2 hov-cl1 trans-04 p-l-22 p-r-11 js-show-modal-search"  onClick={showSearchModal}>
							<i className="zmdi zmdi-search"></i>
						</div> */}

						<div className="icon-header-item cl2 hov-cl1 trans-04 p-l-22 p-r-11 icon-header-noti js-show-cart" data-notify={totalCartItems} onClick={() => { navigate(`/cart`) }}>
							<i className="zmdi zmdi-shopping-cart"></i>
						</div>

						<a href="#" className="dis-block icon-header-item cl2 hov-cl1 trans-04 p-l-22 p-r-11 icon-header-noti" data-notify="0">
							<i className="zmdi zmdi-favorite-outline"></i>
						</a>
						<div className=" p-l-22 p-r-11">
						<IconButton
                size="large"
                aria-label="show more"
                aria-haspopup="true"
                onClick={handleMenu}
                sx={{ sm: 'none', mr: 0, color: "#383f51", padding: 0 }}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
						<Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    width: 240,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <Tabs value={selectedTab} onChange={handleChangeTab} aria-label="Profile Tabs(Profile/Business)">
                  <Tab label="Profile" {...a11yProps(0)} />
                  {props.ownsAStore ? <Tab label="Store" {...a11yProps(1)} /> : <></>}
                </Tabs>

                <TabPanel value={selectedTab} index={0}>
                  {auth.isAuthenticated ? (
                    <div>
                      <MenuItem onClick={() => { handleClose(); navigate(`/profile`) }}>
                        <ListItemIcon>
                          <AccountCircle fontSize="small" />
                        </ListItemIcon>
                        My Profile
                      </MenuItem>
                      <MenuItem onClick={() => { signoutUser(); handleClose(); window.location.reload(); }}>
                        <ListItemIcon>
                          <LogoutIcon fontSize="small" />
                        </ListItemIcon>
                        Logout
                      </MenuItem>
                    </div>
                  ) :
                    <MenuItem onClick={() => { handleClose(); navigate(`/auth`) }} >
                      <ListItemIcon>
                        <LoginIcon fontSize="small" />
                      </ListItemIcon>
                      Login
                    </MenuItem>
                  }
                </TabPanel>
                <TabPanel value={selectedTab} index={1}>
                  <MenuItem onClick={() => { handleClose(); navigate(`/store`) }}>
                    <ListItemIcon>
                      <StoreIcon fontSize="small" />
                    </ListItemIcon>
                    My Store
                  </MenuItem>
                  <MenuItem onClick={() => { handleClose(); navigate(`/products`) }}>
                    <ListItemIcon>
                      <AddCircleIcon fontSize="small" />
                    </ListItemIcon>
                    Add Product
                  </MenuItem>
                  <MenuItem onClick={() => { handleClose(); navigate(`/store-brands`) }}>
                    <ListItemIcon>
                      <BrandingWatermarkIcon fontSize="small" />
                    </ListItemIcon>
                    Add Brand
                  </MenuItem>
                  <MenuItem onClick={() => { handleClose(); navigate(`/store-categories`) }}>
                    <ListItemIcon>
                      <CategoryIcon fontSize="small" />
                    </ListItemIcon>
                    Add Category
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={() => { handleClose(); navigate(`/store-products`) }}>
                    <ListItemIcon>
                      <InventoryIcon fontSize="small" />
                    </ListItemIcon>
                    Store Products
                  </MenuItem>
                  <MenuItem onClick={() => { handleClose(); navigate(`/user-orders`) }}>
                    <ListItemIcon>
                      <InventoryIcon fontSize="small" />
                    </ListItemIcon>
                    User Orders
                  </MenuItem>
                </TabPanel>
              </Menu>
						</div>
					</div>
				</nav>
			</div>	
		</div>

		{/* <!-- Header Mobile --> */}
		<div className="wrap-header-mobile">
			{/* <!-- Logo moblie -->		 */}
			<div className="logo-mobile">
				<a href="/"><img src="https://firebasestorage.googleapis.com/v0/b/pmbk-a17f3.appspot.com/o/images%2FNaijaPetStore_002.png?alt=media&token=d1713eda-3711-49b4-bae8-1b12c0b25d4c" alt="IMG-LOGO" /></a>
			</div>

			{/* <!-- Icon header --> */}
			<div className="wrap-icon-header flex-w flex-r-m m-r-15">
				{/* <div className="icon-header-item cl2 hov-cl1 trans-04 p-r-11 p-l-10 js-show-modal-search" onClick={showSearchModal}>
					<i className="zmdi zmdi-search"></i>
				</div> */}

				<div className="icon-header-item cl2 hov-cl1 trans-04 p-r-11 p-l-10 icon-header-noti js-show-cart" data-notify={totalCartItems} onClick={() => { navigate(`/cart`) }}>
					<i className="zmdi zmdi-shopping-cart"></i>
				</div>

				<a href="#" className="dis-block icon-header-item cl2 hov-cl1 p-l-10 trans-04  icon-header-noti" data-notify="0">
					<i className="zmdi zmdi-favorite-outline"></i>
				</a>
			</div>
			<div className="p-r-11 p-l-10">
			<IconButton
                size="large"
                aria-label="show more"
                aria-haspopup="true"
                onClick={handleMenu}
                sx={{ sm: 'none', mr: 0, color: "#383f51", padding: 0 }}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
			</div>

			{/* <!-- Button show menu --> */}
			<div className={`btn-show-menu-mobile hamburger hamburger--squeeze ${isMenuActive ? 'is-active' : ''}`} onClick={handleMobileMenuClick}>
				<span className="hamburger-box">
					<span className="hamburger-inner"></span>
				</span>
			</div>
		</div>


		{/* <!-- Menu Mobile --> */}
		<div className="menu-mobile"  style={{ display: isMenuActive ? 'block' : 'none' }}>
			<ul className="topbar-mobile">
				<li>
					<div className="left-top-bar">
					Standard shipping for all orders
					</div>
				</li>

				<li>
					<div className="right-top-bar flex-w h-full">
						<a href="#" className="flex-c-m p-lr-10 trans-04">
							Help & FAQs
						</a>

						<a href="/profile" className="flex-c-m p-lr-10 trans-04">
							My Account
						</a>

						<a href="#" className="flex-c-m p-lr-10 trans-04">
							EN
						</a>

						<a href="#" className="flex-c-m p-lr-10 trans-04">
							Naira
						</a>
					</div>
				</li>
			</ul>
			<ul>
			<Box sx={{ textAlign: 'center' }}>
                  <Typography variant="h6" sx={{ my: 2, color: "#383f51" }}>
                    {/* <Button href="/" sx={{ color: '#383f51' }}>
                      <Avatar
                        alt="Naija Pet Store"
                        src="https://firebasestorage.googleapis.com/v0/b/pmbk-a17f3.appspot.com/o/images%2FNaijaPetStore_002.png?alt=media&token=d1713eda-3711-49b4-bae8-1b12c0b25d4c"
                        sx={{ width: 100, height: 57 }}
                      />
                    </Button> */}
                  </Typography>
                  <Divider />
                  {/* Search */}
                  <Grid item xs={12} sx={{ display: { sm: 'none' } }}>
                    <Card><SearchBar onSearch={handleSearch} /></Card>
                  </Grid>
                  {/* Categories */}
                  <Grid item xs={10} sm={11} md={10} lg={10}>
                    <Card className="">
                      <List
                        sx={{ width: '100%', bgcolor: 'background.paper', maxHeight: '240px', overflowY: 'auto' }}
                        subheader={<ListSubheader sx={{ color: 'black', fontWeight: 'bold' }}>CATEGORIES</ListSubheader>}
                      >
                        {props.categories ? (
                          props.categories.map((category) => {
                            const labelId = `checkbox-list-label-${category.id}`;
                            const hasNestedCategories = category.Children && category.Children.length > 0;
                            const isOpen = props.openCategories.includes(category.id);

                            return (
                              <React.Fragment key={category.id}>
                                <ListItem disablePadding>
                                  <ListItemButton dense>
                                    <ListItemIcon>
                                      <Checkbox
                                        edge="start"
                                        checked={props.selectedCategories.includes(category.id)}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                        onClick={(event) => props.handleCategorySelection(event, category.id)}
                                      />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={category.name} />
                                    {hasNestedCategories ? (
                                      isOpen ? (
                                        <ExpandLess onClick={() => props.handleNestedCategoryToggle(category.id)} />
                                      ) : (
                                        <ExpandMore onClick={() => props.handleNestedCategoryToggle(category.id)} />
                                      )
                                    ) : null}
                                  </ListItemButton>
                                </ListItem>
                                {hasNestedCategories ? (
                                  <Collapse in={isOpen} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding sx={{ paddingLeft: 4 }}>
                                      {category.Children.map((nestedCategory) => (
                                        <React.Fragment key={nestedCategory.id}>
                                          <ListItem disablePadding>
                                            <ListItemButton dense>
                                              <ListItemIcon>
                                                <Checkbox
                                                  edge="start"
                                                  checked={props.selectedCategories.includes(nestedCategory.id)}
                                                  tabIndex={-1}
                                                  disableRipple
                                                  inputProps={{ 'aria-labelledby': `checkbox-list-label-${nestedCategory.id}` }}
                                                  onClick={(event) => props.handleCategorySelection(event, nestedCategory.id)}
                                                />
                                              </ListItemIcon>
                                              <ListItemText id={`checkbox-list-label-${nestedCategory.id}`} primary={nestedCategory.name} />
                                              {hasNestedCategories ? (
                                                isOpen ? (
                                                  <ExpandLess onClick={() => props.handleNestedCategoryToggle(nestedCategory.id)} />
                                                ) : (
                                                  <ExpandMore onClick={() => props.handleNestedCategoryToggle(nestedCategory.id)} />
                                                )
                                              ) : null}
                                            </ListItemButton>
                                          </ListItem>
                                          {/* Add another Collapse to handle nested subcategories */}
                                          {nestedCategory.Children && nestedCategory.Children.length > 0 ? (
                                            <Collapse in={props.openCategories.includes(nestedCategory.id)} timeout="auto" unmountOnExit>
                                              {/* Wrap the nested list with a div that limits the height and adds a scroller */}
                                              <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                                <List component="div" disablePadding sx={{ paddingLeft: 4 }}>
                                                  {nestedCategory.Children.map((subNestedCategory) => (
                                                    <ListItem key={subNestedCategory.id} disablePadding>
                                                      <ListItemButton dense>
                                                        <ListItemIcon>
                                                          <Checkbox
                                                            edge="start"
                                                            checked={props.selectedCategories.includes(subNestedCategory.id)}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{ 'aria-labelledby': `checkbox-list-label-${subNestedCategory.id}` }}
                                                            onClick={(event) => props.handleCategorySelection(event, subNestedCategory.id)}
                                                          />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                          id={`checkbox-list-label-${subNestedCategory.id}`}
                                                          primary={subNestedCategory.name}
                                                        />
                                                      </ListItemButton>
                                                    </ListItem>
                                                  ))}
                                                </List>
                                              </div>
                                            </Collapse>
                                          ) : null}
                                        </React.Fragment>
                                      ))}
                                    </List>
                                  </Collapse>
                                ) : null}
                              </React.Fragment>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </List>
                    </Card>
                  </Grid>
                  <Divider />
                  {/* Brands */}
                  <Grid item xs={10} sm={11} md={10} lg={10}>
                    <Card className="">
                      <List
                        sx={{ width: '100%', bgcolor: 'background.paper', maxHeight: '240px', overflowY: 'auto' }}
                        subheader={<ListSubheader sx={{ color: 'black', fontWeight: 'bold' }}>BRANDS</ListSubheader>}
                      >
                        {props.brands ? (
                          props.brands.map((brand) => (
                            <ListItem key={brand.id} disablePadding onClick={(event) => props.handleBrandSelection(event, brand.id)}>
                              <ListItemButton dense>
                                <ListItemIcon>
                                  <Checkbox
                                    edge="start"
                                    checked={props.selectedBrands.includes(brand.id)}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': `checkbox-list-label-${brand.id}` }}
                                  />
                                </ListItemIcon>
                                <ListItemText id={`checkbox-list-label-${brand.id}`} primary={brand.name || brand.id} />
                              </ListItemButton>
                            </ListItem>
                          ))
                        ) : (
                          <></>
                        )}
                      </List>
                    </Card>
                  </Grid>
                  <Divider />
                  {/* Price */}
                  <Grid item xs={10} sm={11} md={10} lg={10}>
                    <Card className="">
                      <List
                        sx={{ width: '100%', bgcolor: 'background.paper', maxHeight: '240px', overflowY: 'auto' }}
                        subheader={<ListSubheader sx={{ color: 'black', fontWeight: 'bold' }}>PRICE</ListSubheader>}
                      >
                        <br />
                        <ListItem>
                          <Slider
                            value={props.priceValue}
                            step={500}
                            max={90000}
                            getAriaLabel={() => 'Product price range'}
                            valueLabelDisplay="auto"
                            onChange={props.handlePriceChange}
                          />
                        </ListItem>
                      </List>
                      <Button onClick={() => props.handlePriceButtonClick(props.priceValue)}>Apply Price</Button>
                    </Card>
                  </Grid>
                </Box>
			</ul>

			{/* <ul className="main-menu-m">
				<li>
					<a href="index.html">Home</a>
					<ul className="sub-menu-m">
						<li><a href="index.html">Homepage 1</a></li>
						<li><a href="home-02.html">Homepage 2</a></li>
						<li><a href="home-03.html">Homepage 3</a></li>
					</ul>
					<span className="arrow-main-menu-m">
						<i className="fa fa-angle-right" aria-hidden="true"></i>
					</span>
				</li>

				<li>
					<a href="product.html">Shop</a>
				</li>

				<li>
					<a href="shoping-cart.html" className="label1 rs1" data-label1="hot">Features</a>
				</li>

				<li>
					<a href="blog.html">Blog</a>
				</li>

				<li>
					<a href="about.html">About</a>
				</li>

				<li>
					<a href="contact.html">Contact</a>
				</li>
			</ul> */}
		</div>

		

		{/* <!-- Modal Search --> */}
		{/* <div className="modal-search-header flex-c-m trans-04 js-hide-modal-search">
			<div className="container-search-header">
				<button className="flex-c-m btn-hide-modal-search trans-04 js-hide-modal-search">
					<img src="https://res.cloudinary.com/faksam-soft/image/upload/v1703858007/faksam/ronin/images/icons/icon-close2_cocswc.png" alt="CLOSE" />
				</button>

				<form className="wrap-search-header flex-w p-l-15">
					<button className="flex-c-m trans-04">
						<i className="zmdi zmdi-search"></i>
					</button>
					<input className="plh3" type="text" name="search" placeholder="Search..." />
				</form>
			</div>
		</div> */}
	</header>

	{/* Modal Search */}
	{isModalVisible && (
        <div className="modal-search-header flex-c-m trans-04 show-modal-search">
          <div className="container-search-header">

            {/* Button to hide the search modal */}
            <button className="flex-c-m btn-hide-modal-search trans-04 js-hide-modal-search" onClick={hideSearchModal}>
              <img src="https://res.cloudinary.com/faksam-soft/image/upload/v1703858007/faksam/ronin/images/icons/icon-close2_cocswc.png" alt="CLOSE" />
            </button>
					<SearchBar onSearch={handleSearch} />
          </div>
        </div>
      )}
    </div>
  );
}

export default NavigationBar;

