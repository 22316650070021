// import libraries
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux'
// import urlConfig from '../../../urlConfig';

import axios from 'axios';
import urlConfig from '../../urlConfig';
// import helperConfig from '../../helperConfig';
import config from "../../../config";

// third-party libraries
import { connect } from 'react-redux';
import ProductValidation from '../../helpers/productValidation';

import { createOrder } from '../../store/modules/orders';
import { getProducts } from '../../store/modules/products';
import { addToCart, getCarts, editCartQuantity, loadCart, deleteFromCart } from '../../store/modules/carts';
import { getUser } from '../../store/modules/users';
import { RootState } from '../../store/rootReducer';
import { Product } from '../../store/modules/products/interfaces';
import { PaystackButton } from "react-paystack"
import { User } from 'modules/users/interfaces';

// styles

// components
import {
  Button,
  Container,
  TextField,
  Grid,
  // Card,
  CardActionArea,
  CardMedia,
  FormControl,
} from '@mui/material';

import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';

import {
  Inbox as InboxIcon,
  // Drafts as DraftsIcon,
  // Star as StarIcon,
  // Comment as CommentIcon,
  Close as CloseIcon,
  Image as ImageIcon,
  Edit as EditIcon,
  RemoveShoppingCart as RemoveShoppingCartIcon,
  Favorite as FavoriteIcon,
  Padding,
} from '@mui/icons-material';

import IconButton from '@mui/joy/IconButton';


// interfaces
// import { ProductProps, ProductState } from './interfaces';

import { BrowserRouter as Router, HashRouter, Link, matchPath, Navigate, Route, Routes, useParams } from 'react-router-dom';

const Cart = (props: any) => {
  // const [product, setProduct] = React.useState([]);
  const dispatch = useDispatch<any>();
  const { auth, signoutUser } = props;
  const { id } = useParams();
  React.useEffect(() => {
    setProductQuantities(carts.data);
  });

  React.useEffect(() => {
    dispatch(getUser(auth.user.userId));
  }, [dispatch]);

  const user: User = auth.user.userId ? useSelector((state: RootState) => state.users.user) : null;

  const publicKey = "pk_test_11a40231d61eacde38bce2148284726e32e25b41"
  const shippingCost = 3000;
  const payStackPaymentProps = {
    email: user && user.email ? user.email : '',
    amount: ((props.productTotal.totalCost + shippingCost)*100),
    firstname: user && user.firstname ? user.firstname : '',
    lastname: user && user.lastname ? user.lastname : '',
    phone: user && user.phone ? user.phone : '',
    publicKey,
    text: "Check Out",
    onSuccess: () => {

      alert("Thanks for doing business with us! Come back soon!!")
    },
    onClose: () => alert("Wait! Don't leave :("),
  }
  const carts = useSelector((state: RootState) => state.carts);

  const [productQuantities, setProductQuantities] = React.useState<Product[]>(carts.data);

  const handleReduceCartItemQuantity = (product: Product, index: number) => (e) => {
    e.preventDefault();
    if(e.target.value == 0) handleRemoveFromCart(product,index);
    else {
      const values = [...productQuantities];
      const newQuantity = productQuantities[index].cartQuantity - parseInt(e.target.value);
      productQuantities[index].cartQuantity = parseInt(e.target.value);
      productQuantities[index].quantity += newQuantity;
      setProductQuantities(values);
      localStorage.setItem('userCart', JSON.stringify(values));
    }
    // console.log(product.cartQuantity);
    props.calculateCartTotal();
  }

  const handleMinus = (product, index, cartItemQuantity)  => (e) => {
    // const updatedCarts = [...productQuantities];
    // if (updatedCarts[index].cartQuantity > 1) {
    //   updatedCarts[index].cartQuantity -= 1;
    //   // Perform any additional logic, such as updating the backend, if needed
    //   // setCarts({ data: updatedCarts });
    // }


    e.preventDefault();
    // if(e.target.value == 0) handleRemoveFromCart(product,index);
    // else {
      // const values = [...productQuantities];
      // const newQuantity = parseInt(cartItemQuantity)-1;
      // productQuantities[index].cartQuantity = newQuantity;
      // productQuantities[index].quantity += newQuantity;
      // setProductQuantities(values);
      // localStorage.setItem('userCart', JSON.stringify(values));
			// dispatch(editCartQuantity(product));
    // }
    // console.log(product.cartQuantity);


		// const cartItems = [...productQuantities];
		// cartItems.map(cartProduct => ((cartProduct.id === product.id) && (cartProduct.quantity > product.cartQuantity))
		// 	? {
		// 		...cartProduct,
		// 		cartQuantity: product.cartQuantity - 1,
		// 		quantity: cartProduct.quantity + 1
		// 	}
		// 	: product)
		// const cartItems = productQuantities.map(cartProduct => ((cartProduct.id === product.id) && (cartProduct.quantity > product.cartQuantity))
		// 	? {
		// 		...cartProduct,
		// 		cartQuantity: product.cartQuantity - 1,
		// 		quantity: cartProduct.quantity + 1
		// 	}
		// 	: product)

		if ((product.cartQuantity == 1)) {
			productQuantities.splice(index, 1);

      localStorage.setItem('userCart', JSON.stringify(productQuantities));
    	props.calculateCartTotal();
			return;
		}

			const cartItems = productQuantities.map((cartProduct, index) => {
				if ((cartProduct.id === product.id) && (cartProduct.cartQuantity > 0)) {
					productQuantities[index].cartQuantity -= 1;
					productQuantities[index].quantity += 1;
					
					return {
						...cartProduct,
						cartQuantity: product.cartQuantity - 1,
						quantity: cartProduct.quantity + 1
					};
				} else {
					return product;
				}
			});

      // productQuantities[index].cartQuantity -= 1;
      // productQuantities[index].quantity += 1;
      setProductQuantities(cartItems);
      localStorage.setItem('userCart', JSON.stringify(productQuantities));
      // localStorage.setItem('userCart', JSON.stringify(productQuantities));
    props.calculateCartTotal();
  };

  const handleAdd = (product, index, cartItemQuantity)  => (e) => {
    // const updatedCarts = [...carts.data];
    // updatedCarts[index].cartQuantity += 1;
    // Perform any additional logic, such as updating the backend, if needed
    // setCarts({ data: updatedCarts });

    e.preventDefault();
    // if(e.target.value == 0) handleRemoveFromCart(product,index);
    // else {
      // const cartItems = [...productQuantities];
			// console.log("cartItems[index]");
			// console.log(cartItems[index]);
			// console.log(product);
			// cartItems.map(cartProduct => ((cartProduct.id === product.id) && (cartProduct.quantity > 0))
			// 	? {
			// 		...cartProduct,
			// 		cartQuantity: product.cartQuantity + 1,
			// 		quantity: cartProduct.quantity - 1
			// 	}
			// 	: product)
			// const cartItems = productQuantities.map(cartProduct => ((cartProduct.id === product.id) && (cartProduct.quantity > 0))
			// 	? {
			// 		productQuantities[index].cartQuantity += 1;
      
			// 		productQuantities[index].quantity -= 1;
			// 		return {
			// 		...cartProduct,
			// 		cartQuantity: product.cartQuantity + 1,
			// 		quantity: cartProduct.quantity - 1
			// 	}
			// }
			// 	: product)
			const cartItems = productQuantities.map((cartProduct, index) => {
				if (cartProduct.id === product.id && cartProduct.quantity > 0) {
					productQuantities[index].cartQuantity += 1;
					productQuantities[index].quantity -= 1;
					
					return {
						...cartProduct,
						cartQuantity: cartProduct.cartQuantity + 1,
						quantity: cartProduct.quantity - 1
					};
				} else {
					return cartProduct;
				}
			});
      // const newQuantity = parseInt(cartItemQuantity)+1;
      // productQuantities[index].cartQuantity = newQuantity;
      // productQuantities[index].quantity -= newQuantity;

      // productQuantities[index].cartQuantity += 1;
      // productQuantities[index].quantity -= 1;
      setProductQuantities(cartItems);
      localStorage.setItem('userCart', JSON.stringify(productQuantities));
			// dispatch(editCartQuantity(product));
    // }
    // console.log(product.cartQuantity);
    props.calculateCartTotal();
  };

  const handleRemoveFromCart = (product: Product, index: number) => {
    const values = productQuantities.filter(product => product.id !== productQuantities[index].id);
    setProductQuantities(values);
    localStorage.setItem('userCart', JSON.stringify(values));
    dispatch(deleteFromCart(product.id));
    props.calculateCartTotal();
  }


  return (
    <div className="cart-page">
	{/* <!-- Cart --> */}
	<div className="wrap-header-cart js-panel-cart">
		<div className="s-full js-hide-cart"></div>

		<div className="header-cart flex-col-l p-l-65 p-r-25">
			<div className="header-cart-title flex-w flex-sb-m p-b-8">
				<span className="mtext-103 cl2">
					Your Cart
				</span>

				<div className="fs-35 lh-10 cl2 p-lr-5 pointer hov-cl1 trans-04 js-hide-cart">
					<i className="zmdi zmdi-close"></i>
				</div>
			</div>
			
			<div className="header-cart-content flex-w js-pscroll">
				<ul className="header-cart-wrapitem w-full">
					<li className="header-cart-item flex-w flex-t m-b-12">
						<div className="header-cart-item-img">
							<img src="https://res.cloudinary.com/faksam-soft/image/upload/v1703858001/faksam/ronin/images/item-cart-01_vqmeby.jpg" alt="IMG" />
						</div>

						<div className="header-cart-item-txt p-t-8">
							<a href="#" className="header-cart-item-name m-b-18 hov-cl1 trans-04">
								White Shirt Pleat
							</a>

							<span className="header-cart-item-info">
								1 x $19.00
							</span>
						</div>
					</li>

					<li className="header-cart-item flex-w flex-t m-b-12">
						<div className="header-cart-item-img">
							<img src="https://res.cloudinary.com/faksam-soft/image/upload/v1703858003/faksam/ronin/images/item-cart-02_yw2oba.jpg" alt="IMG" />
						</div>

						<div className="header-cart-item-txt p-t-8">
							<a href="#" className="header-cart-item-name m-b-18 hov-cl1 trans-04">
								Converse All Star
							</a>

							<span className="header-cart-item-info">
								1 x $39.00
							</span>
						</div>
					</li>

					<li className="header-cart-item flex-w flex-t m-b-12">
						<div className="header-cart-item-img">
							<img src="https://res.cloudinary.com/faksam-soft/image/upload/v1703858002/faksam/ronin/images/item-cart-03_nc0grd.jpg" alt="IMG" />
						</div>

						<div className="header-cart-item-txt p-t-8">
							<a href="#" className="header-cart-item-name m-b-18 hov-cl1 trans-04">
								Nixon Porter Leather
							</a>

							<span className="header-cart-item-info">
								1 x $17.00
							</span>
						</div>
					</li>
				</ul>
				
				<div className="w-full">
					<div className="header-cart-total w-full p-tb-40">
						Total: {((props.productTotal.totalCost + shippingCost)*100)}
					</div>

					<div className="header-cart-buttons flex-w w-full">
						<a href="shoping-cart.html" className="flex-c-m stext-101 cl0 size-107 bg3 bor2 hov-btn3 p-lr-15 trans-04 m-r-8 m-b-10">
							View Cart
						</a>

						<a href="shoping-cart.html" className="flex-c-m stext-101 cl0 size-107 bg3 bor2 hov-btn3 p-lr-15 trans-04 m-b-10">
							Check Out
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>


	{/* <!-- breadcrumb --> */}
	<div className="container">
		<div className="bread-crumb flex-w p-l-25 p-r-15 p-t-30 p-lr-0-lg">
			<a href="/" className="stext-109 cl8 hov-cl1 trans-04">
				Home
				<i className="fa fa-angle-right m-l-9 m-r-10" aria-hidden="true"></i>
			</a>

			<span className="stext-109 cl4">
				Shoping Cart
			</span>
		</div>
	</div>
		

	{/* <!-- Shoping Cart --> */}
	<form className="bg0 p-t-75 p-b-85">
		<div className="container">
			<div className="row">
				<div className="col-lg-10 col-xl-7 m-lr-auto m-b-50">
					<div className="m-l-25 m-r--38 m-lr-0-xl">
						<div className="wrap-table-shopping-cart">
							<table className="table-shopping-cart">
								<tr className="table_head">
									<th className="column-1">Product</th>
									<th className="column-2"></th>
									<th className="column-3">Price</th>
									<th className="column-4">Quantity</th>
									<th className="column-5">Total</th>
								</tr>

								{/* <tr className="table_row">
									<td className="column-1">
										<div className="how-itemcart1">
											<img src="https://res.cloudinary.com/faksam-soft/image/upload/v1703858005/faksam/ronin/images/item-cart-04_iilyfe.jpg" alt="IMG" />
										</div>
									</td>
									<td className="column-2">Fresh Strawberries</td>
									<td className="column-3">$ 36.00</td>
									<td className="column-4">
										<div className="wrap-num-product flex-w m-l-auto m-r-0">
											<div className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m">
												<i className="fs-16 zmdi zmdi-minus"></i>
											</div>

											<input className="mtext-104 cl3 txt-center num-product" type="number" name="num-product1" value="1" />

											<div className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m">
												<i className="fs-16 zmdi zmdi-plus"></i>
											</div>
										</div>
									</td>
									<td className="column-5">$ 36.00</td>
								</tr>

                <tr className="table_row">
                  <td className="column-1">
                    <div className="how-itemcart1">
                      <img src="https://res.cloudinary.com/faksam-soft/image/upload/v1703858004/faksam/ronin/images/item-cart-05_ojvxnv.jpg" alt="IMG" />
                    </div>
                  </td>
                  <td className="column-2">Lightweight Jacket</td>
                  <td className="column-3">$ 16.00</td>
                  <td className="column-4">
                    <div className="wrap-num-product flex-w m-l-auto m-r-0">
                      <div className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m">
                        <i className="fs-16 zmdi zmdi-minus"></i>
                      </div>

                      <input className="mtext-104 cl3 txt-center num-product" type="number" name="num-product2" value="1" />

                      <div className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m">
                        <i className="fs-16 zmdi zmdi-plus"></i>
                      </div>
                    </div>
                  </td>
                  <td className="column-5">$ 16.00</td>
                </tr> */}

                {(carts && carts.data.length > 0) ? carts.data.map((product, index) => (
                <tr className="table_row">
                  <td className="column-1">
                    <div className="how-itemcart1">
                      <img src={product.images[0]} alt="IMG" />
                    </div>
                  </td>
                  <td className="column-2">{product.name}</td>
                  <td className="column-3">$ {product.price}</td>
                  <td className="column-4">
                    <div className="wrap-num-product flex-w m-l-auto m-r-0">
                      <div className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m" onClick={handleMinus(product, index, product.cartQuantity)} >
                        <i className="fs-16 zmdi zmdi-minus"></i>
                      </div>

                      <input className="mtext-104 cl3 txt-center num-product" type="number" name="num-product2" disabled value={product.cartQuantity} onChange={handleReduceCartItemQuantity(product, index)} />

                      <div className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m"  onClick={handleAdd(product, index, product.cartQuantity)}>
                        <i className="fs-16 zmdi zmdi-plus"></i>
                      </div>
                    </div>
                  </td>
                  <td className="column-5">$ {product.name}</td>
                </tr>
                )) : <></>}
							</table>
						</div>

						<div className="flex-w flex-sb-m bor15 p-t-18 p-b-15 p-lr-40 p-lr-15-sm">
							<div className="flex-w flex-m m-r-20 m-tb-5">
								<input className="stext-104 cl2 plh4 size-117 bor13 p-lr-20 m-r-10 m-tb-5" type="text" name="coupon" placeholder="Coupon Code" />
									
								<div className="flex-c-m stext-101 cl2 size-118 bg8 bor13 hov-btn3 p-lr-15 trans-04 pointer m-tb-5">
									Apply coupon
								</div>
							</div>

							<div className="flex-c-m stext-101 cl2 size-119 bg8 bor13 hov-btn3 p-lr-15 trans-04 pointer m-tb-10">
								Update Cart
							</div>
						</div>
					</div>
				</div>

				<div className="col-sm-10 col-lg-7 col-xl-5 m-lr-auto m-b-50">
					<div className="bor10 p-lr-40 p-t-30 p-b-40 m-l-63 m-r-40 m-lr-0-xl p-lr-15-sm">
						<h4 className="mtext-109 cl2 p-b-30">
							Cart Totals
						</h4>

						<div className="flex-w flex-t bor12 p-b-13">
							<div className="size-208">
								<span className="stext-110 cl2">
									Subtotal:
								</span>
							</div>

							<div className="size-209">
								<span className="mtext-110 cl2">
								{((props.productTotal.totalCost))}
								</span>
							</div>
						</div>

						<div className="flex-w flex-t bor12 p-t-15 p-b-30">
							<div className="size-208 w-full-ssm">
								<span className="stext-110 cl2">
									Shipping:
								</span>
							</div>

							<div className="size-209 p-r-18 p-r-0-sm w-full-ssm">
								<p className="stext-111 cl6 p-t-2">
									There are no shipping methods available. Please double check your address, or contact us if you need any help.
								</p>
								
								<div className="p-t-15">
									<span className="stext-112 cl8">
										Calculate Shipping: {((shippingCost))}
									</span>

									<div className="rs1-select2 rs2-select2 bor8 bg0 m-b-12 m-t-9">
										<select className="js-select2" name="time">
											<option>Select a country...</option>
											<option>Nigeria</option>
											{/* <option>UK</option> */}
										</select>
										<div className="dropDownSelect2"></div>
									</div>

									<div className="bor8 bg0 m-b-12">
										<input className="stext-111 cl8 plh3 size-111 p-lr-15" type="text" name="state" placeholder="State /  country" />
									</div>

									<div className="bor8 bg0 m-b-22">
										<input className="stext-111 cl8 plh3 size-111 p-lr-15" type="text" name="postcode" placeholder="Postcode / Zip" />
									</div>
									
									<div className="flex-w">
										<div className="flex-c-m stext-101 cl2 size-115 bg8 bor13 hov-btn3 p-lr-15 trans-04 pointer">
											Update Totals
										</div>
									</div>
										
								</div>
							</div>
						</div>

						<div className="flex-w flex-t p-t-27 p-b-33">
							<div className="size-208">
								<span className="mtext-101 cl2">
									Total:
								</span>
							</div>

							<div className="size-209 p-t-1">
								<span className="mtext-110 cl2">
								{((props.productTotal.totalCost + shippingCost))}
								</span>
							</div>
						</div>

						<button className="flex-c-m stext-101 cl0 size-116 bg3 bor14 hov-btn3 p-lr-15 trans-04 pointer">
							Proceed to Checkout
						</button>
					</div>
				</div>
			</div>
		</div>
	</form>
		
    </div>
  );
}

export default Cart;