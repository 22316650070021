// import libraries
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux'

import axios from 'axios';
import urlConfig from '../../urlConfig';

// third-party libraries
import { connect } from 'react-redux';
import ProductValidation from '../../helpers/productValidation';

import { getProduct } from '../../store/modules/products';
import { RootState } from '../../store/rootReducer';

// styles

// components
import {
  Button,
  Container,
  TextField,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
} from '@mui/material';

// interfaces
import { ProductProps, ProductState } from './interfaces';

import { BrowserRouter as Router, HashRouter, Link, matchPath, Navigate, Route, Routes, useParams } from 'react-router-dom';

const ProductDetails = (props: any) => {
  const dispatch = useDispatch<any>();
  const { auth, signoutUser } = props;
  const {id} = useParams();
  getProduct(id);
  React.useEffect(() => {
  let p = getProduct(id);
  });
  const products = useSelector((state: RootState) => state.products);
  const product = products.data[0];
  React.useEffect(() => {
    dispatch(getProduct(id));
  }, [dispatch]);

  return (
    <div className="new-product">
      <Container maxWidth="md">
        {product ? (<Grid>
          <h2>{product.name}: {product.description}</h2>
          <Card className="" style={{ maxWidth: 420, marginLeft: "auto", marginRight: "auto"  }}>
            <CardActionArea>
              <CardMedia
                className="" style={{ height: 400, width: 420}}
                image={product.images ? product.images[0] : ''}
                title={product.description}
              />
            </CardActionArea>
          </Card>
        </Grid>) : <></>}
      </Container>
    </div>
  );
}

export default ProductDetails;