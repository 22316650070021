import axios from 'axios';
import urlConfig from '../../../urlConfig';

// interfaces
import {
  AddToCartSuccess,
  Cart,
  GetCartSuccess,
  CartsError,
  GetCartsSuccess,
  EditCartSuccess,
  EditCartQuantitySuccess,
  CreateCartSuccess,
  DeleteCartSuccess,
  DeleteFromCartSuccess,
  LoadCartSuccess,
} from './interfaces';

// types
import {
  ADD_TO_CART_SUCCESS,
  CREATE_CART_SUCCESS,
  DELETE_CART_SUCCESS,
  EDIT_CART_SUCCESS,
  EDIT_CART_QUANTITY_SUCCESS,
  DELETE_FROM_CART_SUCCESS,
  CARTS_ERROR,
  GET_CART_SUCCESS,
  GET_CARTS_SUCCESS,
  LOAD_CART_SUCCESS,
} from './types';
import { Product } from 'modules/products/interfaces';


/**
 * action creator
 *
 * Add to cart success action creator
 *
 * @param {Product} cartItem
 *
 * @returns {AddToCartSuccess}
 */

export const addToCartSuccess = (cartItem: Product): AddToCartSuccess => ({
  cartItem,
  type: ADD_TO_CART_SUCCESS,
});

/**
 * action creator
 *
 * Add to cart success action creator
 *
 * @param {Product[]} cart
 *
 * @returns {LoadCartSuccess}
 */

export const loadCartSuccess = (cart: Product[]): LoadCartSuccess => ({
  cart,
  type: LOAD_CART_SUCCESS,
});

/**
 * action creator
 *
 * Add to cart success action creator
 *
 * @param {Product} cartItem
 *
 * @returns {EditCartQuantitySuccess}
 */

export const editCartQuantitySuccess = (cartItem: Product): EditCartQuantitySuccess => ({
  cartItem,
  type: EDIT_CART_QUANTITY_SUCCESS,
});

/**
 * action creator
 *
 * Delete from cart success action creator
 *
 * @param {Cart} cart
 *
 * @returns {DeleteFromCartSuccess}
 */

export const deleteFromCartSuccess = (productId: number): DeleteFromCartSuccess => ({
  productId,
  type: DELETE_FROM_CART_SUCCESS,
});

/**
 * action creator
 *
 * Create carts success action creator
 *
 * @param {Cart} cart
 *
 * @returns {CreateCartSuccess}
 */

export const createCartSuccess = (cart: Cart): CreateCartSuccess => ({
  cart,
  type: CREATE_CART_SUCCESS,
});

/**
 * action creator
 *
 * Get cart success action creator
 *
 *
 * @returns {GetCartsSuccess}
 */
export const getCartsSuccess = (): GetCartsSuccess => ({
  carts: [],
  type: GET_CARTS_SUCCESS,
});


export const cartError = (error): CartsError => ({
    type: CARTS_ERROR,
    error
  });
/**
 * action creator
 *
 * Get an cart success action creator
 *
 * @param {Cart} cart
 *
 * @returns {GetCartSuccess}
 */
export const getCartSuccess = (data: Cart): GetCartSuccess => ({
  cart: data,
  type: GET_CART_SUCCESS,
});

/**
 * action creator
 *
 * Delete specific cart success action creator
 *
 * @param {Cart} cart
 *
 * @returns {DeleteCartSuccess}
 */
export const deleteCartSuccess = (cartId: string): DeleteCartSuccess => ({
  id: cartId,
  type: DELETE_CART_SUCCESS,
});

/**
 * action creator
 *
 * Update cart success action creator
 *
 * @param {Cart} cart
 *
 * @returns {EditCartSuccess}
 */
export const editCartSuccess = (cart: Cart): EditCartSuccess => ({
  cart,
  type: EDIT_CART_SUCCESS,
});

/**
 * Thunk
 *
 * Create cart thunk
 *
 * @param {Object} cart
 *
 * @returns {Function}
 */
export const createCart = (cart: Cart) => dispatch => axios.post(
  `${urlConfig.apiUrl}/carts/`, { ...cart }
)
  .then((response) => {
    dispatch(createCartSuccess(response.data));
  })
  .catch(error =>
    dispatch(cartError({
      status: error.response.status,
      data: error.response.data
    }))
  );

  /**
   * Thunk
   *
   * Add to cart thunk
   *
   * @param {Product[]} cart
   *
   * @returns {Function}
   */
  export const loadCart = (cart: Product[]) => dispatch => {
      dispatch(loadCartSuccess(cart));
    }


  /**
   * Thunk
   *
   * Add to cart thunk
   *
   * @param {Product} cartItem
   *
   * @returns {Function}
   */
  export const addToCart = (cartItem: Product) => dispatch => {
      dispatch(addToCartSuccess(cartItem));
    }

/**
 * Thunk
 *
 * Delete cart thunk
 *
 * @param {string} productId
 *
 * @returns {Function}
 */
export const deleteFromCart = productId => dispatch => {
  dispatch(deleteFromCartSuccess(productId));
}

  /**
   * Thunk
   *
   * Delete cart thunk
   *
   * @param {string} cartId
   *
   * @returns {Function}
   */
  export const deleteCart = cartId => dispatch => axios.delete(
    `${urlConfig.apiUrl}/carts/${cartId}`
  )
    .then(() => {
      dispatch(deleteCartSuccess(cartId));
    })
    .catch((error) =>
      dispatch(cartError({
        status: error.response.status,
        data: error.response.data
      }))
    );

  /**
 * Thunk
 *
 * Delete cart thunk
 *
 * @param {string} cartId
 *
 * @returns {Function}
 */
export const getCart = cartId => dispatch => axios.get(
  `${urlConfig.apiUrl}/carts/${cartId}`
)
  .then((response) => {
    dispatch(getCartSuccess(response.data.data));
  })
  .catch((error) =>
    dispatch(cartError({
      status: error.response.status,
      data: error.response.data
    }))
  );

/**
 * Thunk
 *
 * Get carts thunk
 *
 * @returns {Function}
 */
export const getCarts = () => dispatch => {
    return dispatch(getCartsSuccess())
}

/**
 * Thunk
 *
 * Edit an cart thunk
 *
 * @param {string} cartId
 * @param {Object} updatedRolePayload
 *
 * @returns {Funciton}
 */
export const editCart = (cart: Cart) => dispatch => {
  return axios.put(
  `${urlConfig.apiUrl}/carts/${cart.id}`, {...cart}
)
  .then((response) => {
    dispatch(getCartSuccess(response.data.data));
  })
  .catch((error) =>
    dispatch(cartError({
      status: error.response.status,
      data: error.response.data
    }))
  );
}

/**
 * Thunk
 *
 * Edit an cart thunk
 *
 * @param {Product} cartItem
 * @param {Object} updatedRolePayload
 *
 * @returns {Funciton}
 */
export const editCartQuantity = (cartItem: Product) => dispatch => {
  dispatch(editCartQuantitySuccess(cartItem));
}

// Set the initial role state
const cartInitialState = {
  data: [],
  cartItem: {},
  meta: {},
  errors: {},
  isLoading: false
};

/**
 * Redux reducer for User Role actions
 *
 * This reducer changes the cart state of the application
 *
 * @param {CartState} state Reducer initial state
 * @param {Action} action
 *
 * @returns {CartState} new state
 */
const reducer = (state = cartInitialState, action) => {
  switch (action.type) {
    case ADD_TO_CART_SUCCESS:
      return {
        ...state,
        data: [...state.data, {...action.cartItem, cartQuantity: 1}],
      };
    case LOAD_CART_SUCCESS:
      const loadedCart = action.cart;
      return {
        ...state,
        data: loadedCart.map(product =>
          product ? { ...product, cartQuantity: product.cartQuantity } : product)
      }
    case CREATE_CART_SUCCESS:
      return {
        ...state,
        data: [action.cart, ...state.data],
      };
    case EDIT_CART_QUANTITY_SUCCESS:
      const inCart = state.data.find((product) =>
      product.id === action.cartItem.id ? true : false
      );
      return {
        ...state,
        data: inCart ? state.data.map(product =>
          ((product.id === action.cartItem.id) && (product.quantity > action.cartItem.cartQuantity))? { ...product, cartQuantity: product.cartQuantity + 1 } : product)
          : [...state.data]
      };
    case EDIT_CART_SUCCESS:
      return {
        ...state,
        data: state.data.map(cart =>
          cart.id === action.cart.id ? action.cart : cart),
      };
    case DELETE_CART_SUCCESS:
      const updatedCartList = state.data.filter(cart => cart.id !== action.cartId);
      return {
        ...state,
        data: updatedCartList,
      };
    case DELETE_FROM_CART_SUCCESS:
      const deleteResultCartList = state.data.filter(product => product.id !== action.productId);
      return {
        ...state,
        data: deleteResultCartList,
      };
    case GET_CART_SUCCESS:
      return {
        ...state,
        cart: action.cart,
        isLoading: action.isLoading,
      };
    case GET_CARTS_SUCCESS:
      return {
        ...state,
        meta: action.meta,
        isLoading: action.isLoading,
      };
    case CARTS_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
};

export default reducer;
