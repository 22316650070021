// import libraries
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom';

import { RootState } from '../../store/rootReducer';

// third-party libraries
import { connect } from 'react-redux';
import ProductValidation from '../../helpers/productValidation';
import { storage } from "../../helpers/firebaseConf";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import { makeStyles } from '@mui/styles';
import { createProduct, editProduct, getProduct } from '../../store/modules/products';
import { getCategories } from '../../store/modules/categories';
import { getBrands } from '../../store/modules/brands';

// styles

// components
import {
  Button,
  Container,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

import CircularProgress from '@mui/joy/CircularProgress';

// interfaces
import { ProductProps, ProductState } from './interfaces';
import { Product } from 'modules/products/interfaces';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '64px',
  },
  form: {
    width: '100%',
    maxWidth: '400px',
    marginTop: '24px',
  },
  textField: {
    margin: '12px 0'
  },
}));

const NewProduct = (props: any) => {
  const {id} = useParams();
  const dispatch = useDispatch<any>();
  const classes = useStyles();

  const emptyProduct = {
    name: '',
    description: '',
    images: [],
    price: 0,
    brand: '',
    category: [],
    discount_percent: 0,
    quantity:0,
  }
  const product = id ? useSelector((state: RootState) => state.products.product) : emptyProduct;
  const [editingProduct, setEditingProduct] = id ? React.useState(true) : React.useState(false);
  const brands = useSelector((state: RootState) => state.brands);
  const categories = useSelector((state: RootState) => state.categories);
  React.useEffect(() => {
    if (id) dispatch(getProduct(id));
    dispatch(getCategories());
    dispatch(getBrands());
  }, [dispatch]);

  const [firstLevel, setFirstLevel] = React.useState([]);
  const [secondLevel, setSecondLevel] = React.useState([]);
  const [thirdLevel, setThirdLevel] = React.useState([]);

  const [selectedCategory, setSelectedCategory] = React.useState([]);
  
  const updateFirstLevel = (category) => {
    setSecondLevel([]);
    setThirdLevel([]);
    if (category && category.Children && category.Children.length > 0) {
      setFirstLevel(category.Children);
      const selectedFirstLevel = category.Children;
    }
  };

  // Function to find the selected category based on its ID
  const findSelectedCategoryById = (categoryId) => {
    return categories.data.find((category) => category.id === categoryId);
  };

  // Function to find the selected category based on its ID
  const findFirstCategoryChildren = (selectedCategories, categoryId) => {
    setThirdLevel([]);
    const selectedFirstCat = selectedCategories.find((category) => category.id === categoryId);
    updateSecondLevel(selectedFirstCat);
  };

  // Function to find the selected category based on its ID
  const findSecondCategoryChildren = (selectedCategories, categoryId) => {
    const selectedSecondCat = selectedCategories.find((category) => category.id === categoryId);
    updateThirdLevel(selectedSecondCat);
  };
    
  const updateSecondLevel = (selectedFirstCat) => {
    const updatedSecondLevel = [];
    firstLevel.forEach((child) => {
      if (child.Children && child.Children.length > 0) {
        updatedSecondLevel.push(...child.Children);
      }
    });
    setSecondLevel(updatedSecondLevel);
    // updateThirdLevel();
  };
  
  const updateThirdLevel = (selectedSecondCat) => {
    const updatedThirdLevel = [];
    secondLevel.forEach((grandchild) => {
      if (grandchild.Children && grandchild.Children.length > 0) {
        updatedThirdLevel.push(...grandchild.Children);
      }
    });
    setThirdLevel(updatedThirdLevel);
  };

  const [updateProduct, setUpdateProduct] = React.useState<Product>({...product});

  const [productImages, setProductImages] = React.useState(null);

  const [editProductImagesUrl, setEditProductImagesUrl] = React.useState(false);
  const [editProductDescription, setEditProductDescription] = React.useState(false);
  const [editProductName, setEditProductName] = React.useState(false);
  const [editProductDiscountPercent, setEditProductDiscountPercent] = React.useState(false);
  const [editproductPrice, setEditProductPrice] = React.useState(false);
  const [editProductBrand, setEditProductBrand] = React.useState(false);
  const [editProductCategory, setEditProductCategory] = React.useState(false);
  const [editProductQuantity, setEditProductQuantity] = React.useState(false);

  const [productImagesHelperText, setProductImagesHelperText] = React.useState('Please select product image');
  const [uploadingImage, setUploadingImage] = React.useState(false);


  const [productId, setProductId] = React.useState(0);
  let [isValid, setIsValid] = React.useState<{[key: string]: any;}>({});
  const navigate = useNavigate();

  const [productDescriptionError, setProductDescriptionError] = React.useState(false);
  const [productNameError, setProductNameError] = React.useState(false);
  const [productImagesError, setProductImagesError] = React.useState(false);
  const [productDiscountPercentError, setProductDiscountPercentError] = React.useState(false);
  const [productPriceError, setProductPriceError] = React.useState(false);
  const [productBrandError, setProductBrandError] = React.useState(false);
  const [productCategoryError, setProductCategoryError] = React.useState(false);
  const [productQuantityError, setProductQuantityError] = React.useState(false);

  const [categoriesFirstLevelChildrenId, setCategoriesFirstLevelChildrenId] = React.useState('');
  const [petCategoryId, setPetCategoryId] = React.useState('');
  const [categoriesSecondLevelChildrenId, setCategoriesSecondLevelChildrenId] = React.useState('');

  const onFileChange = (event) => {
    event.preventDefault();

    let imagesCount = 0;

    const files = event.target.files;
    setProductImages(files);
    setUploadingImage(true);
    setEditProductImagesUrl(true);
    setProductImagesHelperText(`${files.length} image(s)`);

    setUpdateProduct({...updateProduct, images: []});

    if(files && files.length > 0) {
      for (const imagesFile of files) {
        imagesCount++;
        const storageRef = ref(storage, `/images/${product.name + imagesCount}`);
        const uploadTask = uploadBytesResumable(storageRef, imagesFile);
        uploadTask.on('state_changed',
          (snapshot) => {
            //takes a snap shot of the process as it is happening
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            // console.log(snapshot);
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused');
                break;
              case 'running':
                console.log('Upload is running');
                break;
            }
          }, (err) => {
            // Handle unsuccessful uploads
            //catches the errors
            console.log(err)
          }, () => {
            // gets the functions from storage refences the image storage in firebase by the children
            // gets the download url then sets the image from firebase as the value for the imagesUrl key:
            // storage.ref('images/').child(productName+imagesCount).getDownloadURL()
            // .then(fireBaseUrl => {
            //   this.setState((prevState) => ({ 
            //     productImagesUrl: [...prevState.productImagesUrl, fireBaseUrl],
            //     uploadingImage: false,
            //   }));
            // setImageAsUrl(prevObject => ({...prevObject, imagesUrl: fireBaseUrl}))


            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              // console.log('File available at', downloadURL);
              setUploadingImage(false);
              setUpdateProduct({
                name: editProductName ? updateProduct.name : product.name,
                description: editProductDescription ? updateProduct.description : product.description,
                // images: editProductImagesUrl ? updateProduct.images : product.images,
                price: editproductPrice ? updateProduct.price : product.price,
                brand: editProductBrand ? updateProduct.brand : product.brand,
                category: editProductCategory ? updateProduct.category : product.category,
  
                discount_percent: editProductDiscountPercent ? updateProduct.discount_percent : product.discount_percent,
  
                quantity: editProductQuantity ? updateProduct.quantity : product.quantity,
                images: editProductImagesUrl ? [...updateProduct.images || null,  downloadURL] : [downloadURL]
              });
            })
          });
      };
    }
  }
  const addNewProduct = (e) => {
    e.preventDefault();

    isValid = ProductValidation({
      name: updateProduct.name,
      images: updateProduct.images.length || 0,
      description: updateProduct.description,
      discount_percent: updateProduct.discount_percent,
      price: updateProduct.price,
      brand: updateProduct.brand,
      category: updateProduct.category,
      quantity: updateProduct.quantity,
    });

    if (isValid.success == true) {
      dispatch(createProduct(product));
    }

    if (isValid.success == true && editingProduct == true) {
      updateProduct.id = parseInt(id);
      dispatch(editProduct(updateProduct));
    } else if(isValid.success == true && editingProduct == false) {
      dispatch(createProduct(updateProduct));
    } else {
      setProductDescriptionError((isValid.error && isValid.error.description) ? true : false)
      setProductNameError((isValid.error && isValid.error.name) ? true : false)
      setProductImagesError((isValid.error && isValid.error.images) ? true : false)
      setProductDiscountPercentError((isValid.error && isValid.error.discount_percent) ? true : false)
      setProductPriceError((isValid.error && isValid.error.price) ? true : false)
      setProductBrandError((isValid.error && isValid.error.brand) ? true : false)
      setProductCategoryError((isValid.error && isValid.error.category) ? true : false)
      setProductQuantityError((isValid.error && isValid.error.quantity) ? true : false)
    }

    if (isValid.success == true) navigate(`/`);
  }

  return (
    <div className="new-product">

<Container component="main" maxWidth="xs" className={classes.container}>
      <form className={classes.form}>
      {editingProduct ? <h3>Update Product Details</h3> : <h3>Add New Product</h3>}

    <div style={{ maxWidth: 320}}>
      <InputLabel id="category-label">Select Pet</InputLabel>
      <Select
        error={productCategoryError}
        labelId="category-label"
        fullWidth
        value={petCategoryId}
        onChange={(event) => {
          setEditProductCategory(true);
          setPetCategoryId(event.target.value);
          updateFirstLevel(findSelectedCategoryById(event.target.value));
          setUpdateProduct({
              name: editProductName ? updateProduct.name : product.name,
              description: editProductDescription ? updateProduct.description : product.description,
              images: editProductImagesUrl ? updateProduct.images : product.images,
              price: editproductPrice ? updateProduct.price : product.price,
              brand: editProductBrand ? updateProduct.brand : product.brand,
              category: editProductCategory ? [...updateProduct.category, event.target.value] : [...product.category, event.target.value],
              discount_percent: editProductDiscountPercent ? updateProduct.discount_percent : product.discount_percent,
              quantity: editProductQuantity ? updateProduct.quantity : product.quantity,
            });
        }}
      >
        {categories.data.map((category) => (
          <MenuItem key={category.id} value={category.id}>
            {category.name}
          </MenuItem>
        ))}
      </Select>
      </div>

      <TextField
        variant="standard"
        id="filled-select-currency"
        error={productNameError}
        label={id ? ``: `Product Name`}
        helperText={isValid.error ? isValid.error.name[0] : 'Please the your product name'}
        name="productName"
        value={editProductName ? updateProduct.name : product.name}
        onChange={(event) => {
          setEditProductName(true);
          setUpdateProduct({
              name: event.target.value,
              description: editProductDescription ? updateProduct.description : product.description,
              images: editProductImagesUrl ? updateProduct.images : product.images,
              price: editproductPrice ? updateProduct.price : product.price,
              brand: editProductBrand ? updateProduct.brand : product.brand,
              category: editProductCategory ? updateProduct.category : product.category,
              discount_percent: editProductDiscountPercent ? updateProduct.discount_percent : product.discount_percent,
              quantity: editProductQuantity ? updateProduct.quantity : product.quantity,
            });
        }}
        fullWidth
        required={true}
      ></TextField>
      <TextField
  variant="standard"
        id="filled-select-currency"
        error={productDescriptionError}
        label={id ? ``: `Product Description`}
        helperText={isValid.error ? isValid.error.description[0] : 'Please enter product description'}
        name="productDescription"
        value={editProductDescription ? updateProduct.description : product.description}
        onChange={(event) => {
          setEditProductDescription(true);
          setUpdateProduct({
              name: editProductName ? updateProduct.name : product.name,
              description: event.target.value,
              images: editProductImagesUrl ? updateProduct.images : product.images,
              price: editproductPrice ? updateProduct.price : product.price,
              brand: editProductBrand ? updateProduct.brand : product.brand,
              category: editProductCategory ? updateProduct.category : product.category,
              discount_percent: editProductDiscountPercent ? updateProduct.discount_percent : product.discount_percent,
              quantity: editProductQuantity ? updateProduct.quantity : product.quantity,
            });
        }}
        fullWidth
        required={true}
      ></TextField>
      <div className='product-image-input'>
        <TextField
  variant="standard"
          id="filled-select-currency"
          error={productImagesError}
          label={id ? ``: `Product Image`}
          helperText={isValid.error ? isValid.error.images[0] : 'Please select product image'}
          name="productImages"
          value={editProductImagesUrl ? updateProduct.images : product.images}
          disabled
        ></TextField>
        <input
          accept="image/*"
          id="contained-button-file"
          multiple
          type="file"
          style={{ display: "none" }}
          name="productImages"
          onChange={onFileChange}
        />
        <label htmlFor="contained-button-file">
          {uploadingImage ? <CircularProgress variant="soft" size="sm" /> :
            <Button color="primary" component="span" style={{ marginTop: "10px" }}>
              Upload
            </Button>}
        </label>
      </div>
      <TextField
  variant="standard"
        error={productDiscountPercentError}
        id="filled-select-currency"
        label={id ? ``: `Product discount percent`}
        helperText={isValid.error ? isValid.error.discount_percent[0] : 'Please enter the product disctount'}
        name="productDiscountPercent"
        value={editProductDiscountPercent ? updateProduct.discount_percent : product.discount_percent}
        onChange={(event) => {
          setEditProductDiscountPercent(true);
          setUpdateProduct({
              name: editProductName ? updateProduct.name : product.name,
              description: editProductDescription ? updateProduct.description : product.description,
              images: editProductImagesUrl ? updateProduct.images : product.images,
              price: editproductPrice ? updateProduct.price : product.price,
              brand: editProductBrand ? updateProduct.brand : product.brand,
              category: editProductCategory ? updateProduct.category : product.category,
              discount_percent: parseInt(event.target.value),
              quantity: editProductQuantity ? updateProduct.quantity : product.quantity,
            });
        }}
        fullWidth
        required={true}
      ></TextField>
      <TextField
  variant="standard"
        error={productPriceError}
        id="filled-select-currency"
        label={id ? ``: `Product Price`}
        helperText={isValid.error ? isValid.error.price[0] : 'Please enter the product price'}
        name="productPrice"
        value={editproductPrice ? updateProduct.price : product.price}
        onChange={(event) => {
          setEditProductPrice(true);
          setUpdateProduct({
              name: editProductName ? updateProduct.name : product.name,
              description: editProductDescription ? updateProduct.description : product.description,
              images: editProductImagesUrl ? updateProduct.images : product.images,
              price: parseInt(event.target.value),
              brand: editProductBrand ? updateProduct.brand : product.brand,
              category: editProductCategory ? updateProduct.category : product.category,
              discount_percent: editProductDiscountPercent ? updateProduct.discount_percent : product.discount_percent,
              quantity: editProductQuantity ? updateProduct.quantity : product.quantity,
            });
        }}
        fullWidth
        required={true}
      ></TextField>

      {firstLevel.length > 0 ? <div style={{padding: "10px", maxWidth: 320}}>
      <InputLabel id="category-label">Select category from pet items</InputLabel>
      <Select
        error={productCategoryError}
        labelId="category-label"
        fullWidth
        value={categoriesFirstLevelChildrenId}
        onChange={(event) => {
          setEditProductCategory(true);
          setCategoriesFirstLevelChildrenId(event.target.value);
          findFirstCategoryChildren(firstLevel, event.target.value);
          setUpdateProduct({
              name: editProductName ? updateProduct.name : product.name,
              description: editProductDescription ? updateProduct.description : product.description,
              images: editProductImagesUrl ? updateProduct.images : product.images,
              price: editproductPrice ? updateProduct.price : product.price,
              brand: editProductBrand ? updateProduct.brand : product.brand,
              category: editProductCategory ? [...updateProduct.category, event.target.value] : [...product.category, event.target.value],
              discount_percent: editProductDiscountPercent ? updateProduct.discount_percent : product.discount_percent,
              quantity: editProductQuantity ? updateProduct.quantity : product.quantity,
            });
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 240,
              overflowY: 'auto',
            },
          },
        }}
      >
        {firstLevel.map((category) => (
          <MenuItem key={category.id} value={category.id}>
          {category.name}
          </MenuItem>
        ))}
      </Select>
      </div>:<></>}

      {secondLevel.length > 0 ? <div style={{padding: "10px", maxWidth: 320}}>
      <InputLabel id="category-label">Select sub categories if the item belongs to it:</InputLabel>
      <Select
        error={productCategoryError}
        labelId="category-label"
        fullWidth
        value={categoriesSecondLevelChildrenId}
        onChange={(event) => {
          setEditProductCategory(true);
          findSecondCategoryChildren(secondLevel, event.target.value);
          setCategoriesSecondLevelChildrenId(event.target.value);
          setUpdateProduct({
              name: editProductName ? updateProduct.name : product.name,
              description: editProductDescription ? updateProduct.description : product.description,
              images: editProductImagesUrl ? updateProduct.images : product.images,
              price: editproductPrice ? updateProduct.price : product.price,
              brand: editProductBrand ? updateProduct.brand : product.brand,
              category: editProductCategory ? [...updateProduct.category, event.target.value] : [...product.category, event.target.value],
              discount_percent: editProductDiscountPercent ? updateProduct.discount_percent : product.discount_percent,
              quantity: editProductQuantity ? updateProduct.quantity : product.quantity,
            });
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 240,
              overflowY: 'auto',
            },
          },
        }}
      >
        {secondLevel.map((category) => (
          <MenuItem key={category.id} value={category.id}>
            {category.name}
          </MenuItem>
        ))}
      </Select>
      </div>:<></>}

      {/* <div style={{padding: "10px"}}>
      <InputLabel id="category-label">Select First Level Category</InputLabel>
      <Select
        error={productCategoryError}
        labelId="category-label"
        fullWidth
        value={editProductCategory ? updateProduct.category : product.category}
        onChange={(event) => {
          setEditProductCategory(true);
          setUpdateProduct({
              name: editProductName ? updateProduct.name : product.name,
              description: editProductDescription ? updateProduct.description : product.description,
              images: editProductImagesUrl ? updateProduct.images : product.images,
              price: editproductPrice ? updateProduct.price : product.price,
              brand: editProductBrand ? updateProduct.brand : product.brand,
              category: event.target.value,
              discount_percent: editProductDiscountPercent ? updateProduct.discount_percent : product.discount_percent,
              quantity: editProductQuantity ? updateProduct.quantity : product.quantity,
            });
        }}
      >
        {categories.data ? categories.data.map((category) => (
          <MenuItem key={category.id} value={category.id}>
            {category.name}
          </MenuItem>
        )) : <></>}
      </Select>
      </div> */}
      
      <div style={{padding: "10px", maxWidth: 320}}>
          <InputLabel id="brand-label">Select Brand</InputLabel>
          <Select
            error={productBrandError}
            labelId="brand-label"
            fullWidth
            value={editProductBrand ? updateProduct.brand : product.brand}
            onChange={(event) => {
              setEditProductBrand(true);
              setUpdateProduct({
                  name: editProductName ? updateProduct.name : product.name,
                  description: editProductDescription ? updateProduct.description : product.description,
                  images: editProductImagesUrl ? updateProduct.images : product.images,
                  price: editproductPrice ? updateProduct.price : product.price,
                  brand: event.target.value,
                  category: editProductCategory ? updateProduct.category : product.category,
                  discount_percent: editProductDiscountPercent ? updateProduct.discount_percent : product.discount_percent,
                  quantity: editProductQuantity ? updateProduct.quantity : product.quantity,
                });
            }}
          >
            {brands.data.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
          </div>
      <TextField
        variant="standard"
        error={productQuantityError}
        id="filled-select-currency"
        label={id ? ``: `Product Quantity`}
        helperText={isValid.error ? isValid.error.quantity[0] : 'Please enter the product quantity'}
        name="productQuantity"
        value={editProductQuantity ? updateProduct.quantity : product.quantity}
        onChange={(event) => {
          setEditProductQuantity(true);
          setUpdateProduct({
              name: editProductName ? updateProduct.name : product.name,
              description: editProductDescription ? updateProduct.description : product.description,
              images: editProductImagesUrl ? updateProduct.images : product.images,
              price: editproductPrice ? updateProduct.price : product.price,
              brand: editProductBrand ? updateProduct.brand : product.brand,
              category: editProductCategory ? updateProduct.category : product.category,
              discount_percent: editProductDiscountPercent ? updateProduct.discount_percent : product.discount_percent,
              quantity: parseInt(event.target.value),
            });
        }}
        fullWidth
        required={true}
      ></TextField>
      <Button variant="contained" color="primary" onClick={addNewProduct}>Save</Button>
      </form>
      </Container>
    </div>
  );
}

export default NewProduct;
