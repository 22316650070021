import { AppRoutes, AdminAppRoutes } from "../routes"

export const APPS =[
  {
    subdomain: "www",
    app: AppRoutes,
    main: true,
  },
  {
    subdomain: "admin",
    app: AdminAppRoutes,
    main: false,
  },
];