export const GET_USERS_SUCCESS = 'pm-app/users/GET_USERS_SUCCESS';
export type GET_USERS_SUCCESS = typeof GET_USERS_SUCCESS;

export const USERS_ERROR = 'pm-app/users/USERS_ERROR';
export type USERS_ERROR = typeof USERS_ERROR;

export const DELETE_USER_SUCCESS = 'pm-app/users/DELETE_USER_SUCCESS';
export type DELETE_USER_SUCCESS = typeof DELETE_USER_SUCCESS;

export const GET_USER_SUCCESS = 'pm-app/users/GET_USER_SUCCESS';
export type GET_USER_SUCCESS = typeof GET_USER_SUCCESS;

export const CREATE_USER_SUCCESS = 'pm-app/users/CREATE_USER_SUCCESS ';
export type CREATE_USER_SUCCESS  = typeof CREATE_USER_SUCCESS ;

export const EDIT_USER_SUCCESS = 'pm-app/users/EDIT_USER_SUCCESS';
export type EDIT_USER_SUCCESS  = typeof EDIT_USER_SUCCESS;
