export const GET_ATTRIBUTES_SUCCESS = 'xences/attributes/GET_ATTRIBUTES_SUCCESS';
export type GET_ATTRIBUTES_SUCCESS = typeof GET_ATTRIBUTES_SUCCESS;

export const ATTRIBUTES_ERROR = 'xences/attributes/ATTRIBUTES_ERROR';
export type ATTRIBUTES_ERROR = typeof ATTRIBUTES_ERROR;

export const DELETE_ATTRIBUTE_SUCCESS = 'xences/attributes/DELETE_ATTRIBUTE_SUCCESS';
export type DELETE_ATTRIBUTE_SUCCESS = typeof DELETE_ATTRIBUTE_SUCCESS;

export const GET_ATTRIBUTE_SUCCESS = 'xences/attributes/GET_ATTRIBUTE_SUCCESS';
export type GET_ATTRIBUTE_SUCCESS = typeof GET_ATTRIBUTE_SUCCESS;

export const CREATE_ATTRIBUTE_SUCCESS = 'xences/attributes/CREATE_ATTRIBUTE_SUCCESS ';
export type CREATE_ATTRIBUTE_SUCCESS  = typeof CREATE_ATTRIBUTE_SUCCESS ;

export const EDIT_ATTRIBUTE_SUCCESS = 'xences/attributes/EDIT_ATTRIBUTE_SUCCESS';
export type EDIT_ATTRIBUTE_SUCCESS  = typeof EDIT_ATTRIBUTE_SUCCESS;
