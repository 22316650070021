// import libraries
import * as React from 'react';

// third-party libraries
import { connect } from 'react-redux';

// styles
import './HomePage.scss';

// components

// import Carousel from 'react-material-ui-carousel';
import {
  // Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardHeader,
  CardMedia,
  Checkbox,
  Grid,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  // IconButton,
  InputLabel,
  List,
  ListItem,
  // ListItemAvatar,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  ListSubheader,
  MenuItem,
  TextField,
  // Typography,
  Stack,
  Select,
  Slider,
  Collapse
} from '@mui/material';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

// import Select, { SelectChangeEvent } from '@mui/material/Select';

import AspectRatio from '@mui/joy/AspectRatio';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import BookmarkAdd from '@mui/icons-material/BookmarkAddOutlined';

import JoyButton from '@mui/joy/Button';
import JoyAspectRatio from '@mui/joy/AspectRatio';
import JoyBox from '@mui/joy/Box';
import JoyCard from '@mui/joy/Card';
import JoyIconButton from '@mui/joy/IconButton';
import JoyTypography from '@mui/joy/Typography';
import JoyBookmarkAdd from '@mui/icons-material/BookmarkAddOutlined';

import {
  Inbox as InboxIcon,
  // Drafts as DraftsIcon,
  // Star as StarIcon,
  // Comment as CommentIcon,
  Close as CloseIcon,
  Image as ImageIcon,
  Edit as EditIcon,
  AddShoppingCart as AddShoppingCartIcon,
  Favorite as FavoriteIcon,
  Padding,
} from '@mui/icons-material';
// import AddStorepingCartIcon from '@mui/icons-material/AddStorepingCart';
// import EditIcon from '@mui/icons-material/Edit';
// import FavoriteIcon from '@mui/icons-material/Favorite';


import { styled } from '@mui/material/styles';



// interfaces
import { ProductProps, ProductState } from './interfaces';

// helper functions
import { getProduct, getProducts, productError } from '../../store/modules/products';
import { getBrands } from '../../store/modules/brands';
import { getCategories } from '../../store/modules/categories';
import { getAttribute, getAttributes } from '../../store/modules/attributes';
import { addToCart, editCartQuantity, getCarts, deleteFromCart, cartError, loadCart, } from '../../store/modules/carts';
import { Cart } from '../../store/modules/carts/interfaces';
import { Product } from '../../store/modules/products/interfaces';
import { editOrder, getOrders, deleteOrder } from '../../store/modules/orders';
import MenuItems from './MenuItems';

// fixtures

export class HomePage extends React.Component<ProductProps, ProductState> {

  initialState = {
    product: {
      id: 0,
      name: '',
      images: [],
      description: '',
      region: '',
      price: 0,
      brands: [],
      discount_percent: 0,
      quantity: 1,
      categories: [],
    },
    userCart: [],
    totalCartItems: 0,
    productQuantity: '',
    productColor: '',
    productSize: '',
    displayedImage: '',
    clickQuickView: false,
    searchValue: '',
    selectedCategories: [],
    selectedBrands: [],
    priceValue: [0, 900000],
    openCategories: [],
    showFilter: false,
    showSearch: false,
  };

  state = this.initialState;

  componentDidMount() {
    // this.fetchProducts();
    // this.props.getBrands();
    // this.props.getAttributes();
    // this.props.getCategories();
    // this.props.getOrders(); 
    this.props.calculateCartTotal();
    this.setState({ userCart: JSON.parse(localStorage.getItem('userCart')) });
  }


  componentDidUpdate(prevProps: Readonly<ProductProps>, prevState: Readonly<ProductState>, snapshot?: any): void {

  }



  setDisplayedImage = (imageUrl) => (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      displayedImage: imageUrl,
    }));
  }


  handleSearch = (searchValue) => {
    // Perform search action using the searchValue
    const searchValueArray = this.state.searchValue.split(" ");
    this.props.getProducts({
      searchQuery: searchValueArray.join("+"),
      brandList: [],
      categoryList: [],
      priceRange: '',
    });
  };

  handleSearchValueChange = (event) => {
    this.setState((prevState) => ({
      searchValue: event.target.value,
    }));
  };
  onClickQuickView = (product) => (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      product,
      displayedImage: product.images[0],
      clickQuickView: true,
    }));
  }

  handleQuantityChange = (event) => {
    this.setState((prevState) => ({
      productQuantity: event.target.value,
    }));
  };

  handleColorChange = (event) => {
    this.setState((prevState) => ({
      productColor: event.target.value,
    }));
  };

  handleSizeChange = (event) => {
    this.setState((prevState) => ({
      productSize: event.target.value,
    }));
  };

  onCloseModalView = e => {
    e.preventDefault();
    this.setState((prevState) => ({
      clickQuickView: false,
    }));
  }

  handleAddToCart = (product: Product) => (e) => {
    e.preventDefault();
    if (this.state.userCart && (this.state.userCart.filter(p => p.id === product.id).length > 0)) {
      this.state.userCart.map(currentUserCart => ((currentUserCart.id === product.id) && (currentUserCart.quantity >= 1)) ? { ...currentUserCart, quantity: currentUserCart.quantity--, cartQuantity: currentUserCart.cartQuantity++ } : currentUserCart);
      localStorage.setItem('userCart', JSON.stringify(this.state.userCart));
      this.props.editCartQuantity(product);
      this.props.calculateCartTotal();
    }
    else {
      this.setState((prevState) => ({
        userCart: prevState.userCart ? [...prevState.userCart, { ...product, cartQuantity: 1 }] : [{ ...product, cartQuantity: 1 }],
      }), () => {
        this.state.userCart.map(currentUserCart => currentUserCart.id === product.id ? { ...currentUserCart, quantity: currentUserCart.quantity-- } : currentUserCart);
        localStorage.setItem('userCart', JSON.stringify(this.state.userCart));
        this.props.addToCart(product);
        this.props.calculateCartTotal();
      });
    }
    this.onCloseModalView(e);
  }

  handleFilterClick = e => {
    e.preventDefault();
    this.setState((prevState) => ({
      showFilter: !prevState.showFilter,
    }));
  }

  handleSearchClick = e => {
    e.preventDefault();
    this.setState((prevState) => ({
      showSearch: !prevState.showSearch,
    }));
  }

  render() {
    const options = {
      shouldForwardProp: (prop) => prop !== 'hoverShadow',
    };
    type Props = {
      hoverShadow: number;
    };
    const StyledCard = styled(
      Card,
      options,
    )<Props>(({ theme, hoverShadow = 1 }) => ({
      ':hover': {
        boxShadow: theme.shadows[hoverShadow],
      },
    }));
    const JoyStyledCard = styled(
      JoyCard,
      options,
    )<Props>(({ theme, hoverShadow = 1 }) => ({
      ':hover': {
        boxShadow: theme.shadows[hoverShadow],
      },
    }));

    const { categories, brands, selectedCategories, selectedBrands, priceValue, openCategories } = this.props;

    return (
      <Grid container item className="home-page" id="product-section">
                  <Grid item xs={12} sm={12} md={12} lg={12} >
          <div className="flex-w flex-sb-m p-b-52">
            <div className="flex-w flex-l-m filter-tope-group m-tb-10">
              <button className="stext-106 cl6 hov1 bor3 trans-04 m-r-32 m-tb-5 how-active1" data-filter="*">
                All Products
              </button>

              {/* <button className="stext-106 cl6 hov1 bor3 trans-04 m-r-32 m-tb-5" data-filter=".women">
                Women
              </button>

              <button className="stext-106 cl6 hov1 bor3 trans-04 m-r-32 m-tb-5" data-filter=".men">
                Men
              </button>

              <button className="stext-106 cl6 hov1 bor3 trans-04 m-r-32 m-tb-5" data-filter=".bag">
                Bag
              </button>

              <button className="stext-106 cl6 hov1 bor3 trans-04 m-r-32 m-tb-5" data-filter=".shoes">
                Shoes
              </button>

              <button className="stext-106 cl6 hov1 bor3 trans-04 m-r-32 m-tb-5" data-filter=".watches">
                Watches
              </button> */}
            </div>

            <div className="flex-w flex-c-m m-tb-10">
              {/* <div className={`flex-c-m stext-106 cl6 size-104 bor4 pointer hov-btn3 trans-04 m-r-8 m-tb-4 js-show-filter ${this.state.showFilter ? 'show-filter' : ''}`} onClick={this.handleFilterClick}>
                <i className="icon-filter cl2 m-r-6 fs-15 trans-04 zmdi zmdi-filter-list"></i>
                <i className="icon-close-filter cl2 m-r-6 fs-15 trans-04 zmdi zmdi-close dis-none"></i>
                Filter
              </div> */}

              <div className={`flex-c-m stext-106 cl6 size-105 bor4 pointer hov-btn3 trans-04 m-tb-4 js-show-search  ${this.state.showSearch ? 'show-search' : ''}`} onClick={this.handleSearchClick}>
                <i className="icon-search cl2 m-r-6 fs-15 trans-04 zmdi zmdi-search"></i>
                <i className="icon-close-search cl2 m-r-6 fs-15 trans-04 zmdi zmdi-close dis-none"></i>
                Search
              </div>
            </div>

            {/* <!-- Search product --> */}
            <div className={`dis-none panel-search w-full p-t-10 p-b-15`}   style={{ display: this.state.showSearch ? 'block' : 'none' }}>
              <div className="bor8 dis-flex p-l-15">
                <input className="mtext-107 cl2 size-114 plh2 p-r-15" type="text" name="search-product" placeholder="Search" value={this.state.searchValue} onChange={this.handleSearchValueChange} />
                <button className="size-113 flex-c-m fs-16 cl2 hov-cl1 trans-04" onClick={this.handleSearch} >
                  <i className="zmdi zmdi-search"></i>
                </button>
              </div>
            </div>

            {/* <!-- Modal Search --> */}
            <div className="modal-search-header flex-c-m trans-04 js-hide-modal-search">
              <div className="container-search-header">
                <button className="flex-c-m btn-hide-modal-search trans-04 js-hide-modal-search">
                  <img src="https://res.cloudinary.com/faksam-soft/image/upload/v1703858007/faksam/ronin/images/icons/icon-close2_cocswc.png" alt="CLOSE" />
                </button>

                <form className="wrap-search-header flex-w p-l-15">
                  <input className="plh3" type="text" name="search" placeholder="Search..." />
                  <button className="flex-c-m trans-04">
                    <i className="zmdi zmdi-search"></i>
                  </button>
                </form>
              </div>
            </div>

            {/* <!-- Filter --> */}
            <div className={`dis-none panel-filter ${this.state.showFilter ? 'show-filter' : ''} w-full p-t-10`}  style={{ display: this.state.showFilter ? 'block' : 'none' }}>
              <div className="wrap-filter flex-w bg6 w-full p-lr-40 p-t-27 p-lr-15-sm">
                <div className="filter-col1 p-r-15 p-b-27">
                  <div className="mtext-102 cl2 p-b-15">
                    Sort By
                  </div>

                  <ul>
                    <li className="p-b-6">
                      <a href="#" className="filter-link stext-106 trans-04">
                        Default
                      </a>
                    </li>

                    <li className="p-b-6">
                      <a href="#" className="filter-link stext-106 trans-04">
                        Popularity
                      </a>
                    </li>

                    <li className="p-b-6">
                      <a href="#" className="filter-link stext-106 trans-04">
                        Average rating
                      </a>
                    </li>

                    <li className="p-b-6">
                      <a href="#" className="filter-link stext-106 trans-04 filter-link-active">
                        Newness
                      </a>
                    </li>

                    <li className="p-b-6">
                      <a href="#" className="filter-link stext-106 trans-04">
                        Price: Low to High
                      </a>
                    </li>

                    <li className="p-b-6">
                      <a href="#" className="filter-link stext-106 trans-04">
                        Price: High to Low
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="filter-col2 p-r-15 p-b-27">
                  <div className="mtext-102 cl2 p-b-15">
                    Price
                  </div>

                  <ul>
                    <li className="p-b-6">
                      <a href="#" className="filter-link stext-106 trans-04 filter-link-active">
                        All
                      </a>
                    </li>

                    <li className="p-b-6">
                      <a href="#" className="filter-link stext-106 trans-04">
                        $0.00 - $50.00
                      </a>
                    </li>

                    <li className="p-b-6">
                      <a href="#" className="filter-link stext-106 trans-04">
                        $50.00 - $100.00
                      </a>
                    </li>

                    <li className="p-b-6">
                      <a href="#" className="filter-link stext-106 trans-04">
                        $100.00 - $150.00
                      </a>
                    </li>

                    <li className="p-b-6">
                      <a href="#" className="filter-link stext-106 trans-04">
                        $150.00 - $200.00
                      </a>
                    </li>

                    <li className="p-b-6">
                      <a href="#" className="filter-link stext-106 trans-04">
                        $200.00+
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="filter-col3 p-r-15 p-b-27">
                  <div className="mtext-102 cl2 p-b-15">
                    Color
                  </div>

                  <ul>
                    <li className="p-b-6">
                      <span className="fs-15 lh-12 m-r-6" style={{ color: "#222" }}>
                        <i className="zmdi zmdi-circle"></i>
                      </span>

                      <a href="#" className="filter-link stext-106 trans-04">
                        Black
                      </a>
                    </li>

                    <li className="p-b-6">
                      <span className="fs-15 lh-12 m-r-6" style={{ color: "#4272d7" }}>
                        <i className="zmdi zmdi-circle"></i>
                      </span>

                      <a href="#" className="filter-link stext-106 trans-04 filter-link-active">
                        Blue
                      </a>
                    </li>

                    <li className="p-b-6">
                      <span className="fs-15 lh-12 m-r-6" style={{ color: "#b3b3b3" }}>
                        <i className="zmdi zmdi-circle"></i>
                      </span>

                      <a href="#" className="filter-link stext-106 trans-04">
                        Grey
                      </a>
                    </li>

                    <li className="p-b-6">
                      <span className="fs-15 lh-12 m-r-6" style={{ color: "#00ad5f" }}>
                        <i className="zmdi zmdi-circle"></i>
                      </span>

                      <a href="#" className="filter-link stext-106 trans-04">
                        Green
                      </a>
                    </li>

                    <li className="p-b-6">
                      <span className="fs-15 lh-12 m-r-6" style={{ color: "#fa4251" }}>
                        <i className="zmdi zmdi-circle"></i>
                      </span>

                      <a href="#" className="filter-link stext-106 trans-04">
                        Red
                      </a>
                    </li>

                    <li className="p-b-6">
                      <span className="fs-15 lh-12 m-r-6" style={{ color: "#aaa" }}>
                        <i className="zmdi zmdi-circle-o"></i>
                      </span>

                      <a href="#" className="filter-link stext-106 trans-04">
                        White
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="filter-col4 p-b-27">
                  <div className="mtext-102 cl2 p-b-15">
                    Tags
                  </div>

                  <div className="flex-w p-t-4 m-r--5">
                    <a href="#" className="flex-c-m stext-107 cl6 size-301 bor7 p-lr-15 hov-tag1 trans-04 m-r-5 m-b-5">
                      Fashion
                    </a>

                    <a href="#" className="flex-c-m stext-107 cl6 size-301 bor7 p-lr-15 hov-tag1 trans-04 m-r-5 m-b-5">
                      Lifestyle
                    </a>

                    <a href="#" className="flex-c-m stext-107 cl6 size-301 bor7 p-lr-15 hov-tag1 trans-04 m-r-5 m-b-5">
                      Denim
                    </a>

                    <a href="#" className="flex-c-m stext-107 cl6 size-301 bor7 p-lr-15 hov-tag1 trans-04 m-r-5 m-b-5">
                      Streetstyle
                    </a>

                    <a href="#" className="flex-c-m stext-107 cl6 size-301 bor7 p-lr-15 hov-tag1 trans-04 m-r-5 m-b-5">
                      Crafts
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </Grid>
        <Grid container item sm={0} md={4} lg={3} spacing={2} sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
          {/* Categories */}
          <Grid item xs={10} sm={11} md={10} lg={10}>
            <Card className="">
              <List
                sx={{ width: '100%', bgcolor: 'background.paper' }}
                subheader={<ListSubheader sx={{ color: 'black', fontWeight: 'bold' }}>CATEGORIES</ListSubheader>}
              >
                {categories ? (
                  categories.map((category) => {
                    const labelId = `checkbox-list-label-${category.id}`;
                    const hasNestedCategories = category.Children && category.Children.length > 0;
                    const isOpen = openCategories.includes(category.id);

                    return (
                      <React.Fragment key={category.id}>
                        <ListItem disablePadding>
                          <ListItemButton dense>
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={selectedCategories.includes(category.id)}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                                onClick={(event) => this.props.handleCategorySelection(event, category.id)}
                              />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={category.name} />
                            {hasNestedCategories ? (
                              isOpen ? (
                                <ExpandLess onClick={() => this.props.handleNestedCategoryToggle(category.id)} />
                              ) : (
                                <ExpandMore onClick={() => this.props.handleNestedCategoryToggle(category.id)} />
                              )
                            ) : null}
                          </ListItemButton>
                        </ListItem>
                        {hasNestedCategories ? (
                          <Collapse in={isOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding sx={{ paddingLeft: 4 }}>
                              {category.Children.map((nestedCategory) => (
                                <React.Fragment key={nestedCategory.id}>
                                  <ListItem disablePadding>
                                    <ListItemButton dense>
                                      <ListItemIcon>
                                        <Checkbox
                                          edge="start"
                                          checked={selectedCategories.includes(nestedCategory.id)}
                                          tabIndex={-1}
                                          disableRipple
                                          inputProps={{ 'aria-labelledby': `checkbox-list-label-${nestedCategory.id}` }}
                                          onClick={(event) => this.props.handleCategorySelection(event, nestedCategory.id)}
                                        />
                                      </ListItemIcon>
                                      <ListItemText id={`checkbox-list-label-${nestedCategory.id}`} primary={nestedCategory.name} />
                                      {hasNestedCategories ? (
                                        isOpen ? (
                                          <ExpandLess onClick={() => this.props.handleNestedCategoryToggle(nestedCategory.id)} />
                                        ) : (
                                          <ExpandMore onClick={() => this.props.handleNestedCategoryToggle(nestedCategory.id)} />
                                        )
                                      ) : null}
                                    </ListItemButton>
                                  </ListItem>
                                  {/* Add another Collapse to handle nested subcategories */}
                                  {nestedCategory.Children && nestedCategory.Children.length > 0 ? (
                                    <Collapse in={openCategories.includes(nestedCategory.id)} timeout="auto" unmountOnExit>
                                      {/* Wrap the nested list with a div that limits the height and adds a scroller */}
                                      <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                        <List component="div" disablePadding sx={{ paddingLeft: 4 }}>
                                          {nestedCategory.Children.map((subNestedCategory) => (
                                            <ListItem key={subNestedCategory.id} disablePadding>
                                              <ListItemButton dense>
                                                <ListItemIcon>
                                                  <Checkbox
                                                    edge="start"
                                                    checked={selectedCategories.includes(subNestedCategory.id)}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': `checkbox-list-label-${subNestedCategory.id}` }}
                                                    onClick={(event) => this.props.handleCategorySelection(event, subNestedCategory.id)}
                                                  />
                                                </ListItemIcon>
                                                <ListItemText
                                                  id={`checkbox-list-label-${subNestedCategory.id}`}
                                                  primary={subNestedCategory.name}
                                                />
                                              </ListItemButton>
                                            </ListItem>
                                          ))}
                                        </List>
                                      </div>
                                    </Collapse>
                                  ) : null}
                                </React.Fragment>
                              ))}
                            </List>
                          </Collapse>
                        ) : null}
                      </React.Fragment>
                    );
                  })
                ) : (
                  <></>
                )}
              </List>
            </Card>
          </Grid>

          {/* Brands */}
          <Grid item xs={10} sm={11} md={10} lg={10}>
            <Card className="">
              <List
                sx={{ width: '100%', bgcolor: 'background.paper' }}
                subheader={<ListSubheader sx={{ color: 'black', fontWeight: 'bold' }}>BRANDS</ListSubheader>}
              >
                {brands ? (
                  brands.map((brand) => (
                    <ListItem key={brand.id} disablePadding onClick={(event) => this.props.handleBrandSelection(event, brand.id)}>
                      <ListItemButton dense>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={selectedBrands.includes(brand.id)}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': `checkbox-list-label-${brand.id}` }}
                          />
                        </ListItemIcon>
                        <ListItemText id={`checkbox-list-label-${brand.id}`} primary={brand.name || brand.id} />
                      </ListItemButton>
                    </ListItem>
                  ))
                ) : (
                  <></>
                )}
              </List>
            </Card>
          </Grid>

          {/* Price */}
          <Grid item xs={10} sm={11} md={10} lg={10}>
            <Card className="">
              <List
                sx={{ width: '100%', bgcolor: 'background.paper' }}
                subheader={<ListSubheader sx={{ color: 'black', fontWeight: 'bold' }}>PRICE</ListSubheader>}
              >
                <br />
                <ListItem>
                  <Slider
                    value={priceValue}
                    step={500}
                    max={90000}
                    getAriaLabel={() => 'Product price range'}
                    valueLabelDisplay="auto"
                    onChange={this.props.handlePriceChange}
                  />
                </ListItem>
              </List>
              <Button onClick={() => this.props.handlePriceButtonClick(priceValue)}>Apply Price</Button>
            </Card>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={12} md={8} lg={9} spacing={2}>

          {this.props.products.map(product => (<Grid key={`product-${product.id}`} item xs={12} sm={6} md={6} lg={4}>
            <JoyStyledCard hoverShadow={10} variant="outlined" sx={{ width: '100%' }}>
              <JoyAspectRatio minHeight="240px" maxHeight="320px" sx={{ my: 2, height: "240px" }}>
                {product.images && product.images[0] ? <img
                  src={product.images[0]}
                  srcSet={product.images[0]}
                  loading="lazy"
                  alt={`Image of - ${product.name}`}
                  height={"240px"}
                /> :
                  <div>
                    <ImageIcon fontSize="large" sx={{ color: 'text.tertiary' }} />
                  </div>
                }
              </JoyAspectRatio>
              <Divider sx={{ color: 'black' }} />
              <JoyBox sx={{ display: 'flex', paddingTop: '10px', textAlign: 'center' }}>
                <div style={{ width: '100%' }}>
                  <JoyTypography fontSize="md" sx={{ mb: 0.5 }}>
                    {product.name}
                  </JoyTypography>
                  <JoyTypography fontSize="md">
                    ₦{product.price}
                  </JoyTypography>
                  <div style={{}}>
                    <Stack direction="row" alignItems="center" spacing={1} sx={{ justifyContent: 'center' }}>
                      <IconButton
                        aria-label="add to cart"
                        onClick={this.props.setUserCart(product)}
                        sx={{}}
                      >
                        <FavoriteIcon />
                      </IconButton>
                      <Button
                        color='primary'
                        onClick={this.onClickQuickView(product)}
                        variant="contained"
                      >
                        BUY NOW
                      </Button>
                      <IconButton
                        aria-label="add to cart"
                        onClick={this.handleAddToCart(product)}
                        sx={{}}
                      >
                        <AddShoppingCartIcon />
                      </IconButton>
                    </Stack>
                  </div>
                </div>
              </JoyBox>
            </JoyStyledCard>
          </Grid>))}
        </Grid>
        <Grid>
          <Dialog open={this.state.clickQuickView} onClose={this.onCloseModalView} aria-labelledby="form-dialog-title" maxWidth={'lg'} fullWidth={true}>
            <DialogTitle id="form-dialog-title"
              sx={{
                textAlign: 'center'
              }}
            >
              {this.state.product.name}
              <IconButton
                aria-label="close"
                onClick={this.onCloseModalView}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: 'black',
                  backgroundColor: 'transparent'
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <br />
            <DialogContent style={{ minWidth: '320px' }}>
              <Grid container item spacing={2} justifyContent="center" alignItems="center">
                <Grid container item xs={3} sm={2} md={2} lg={1} spacing={2} sx={{ display: 'block' }}>
                  {this.state.product.images ? this.state.product.images.map(image => (<Grid key={`product-${this.state.product.id}-${image}`} item xs={12} sm={12}>
                    <StyledCard className="" hoverShadow={10} style={{ maxWidth: 100 }}>
                      <CardActionArea>
                        <CardMedia
                          className="" style={{ height: 80 }}
                          image={image}
                          title={this.state.product.name}
                          onClick={this.setDisplayedImage(image)}
                        />
                      </CardActionArea>
                    </StyledCard></Grid>))
                    : <></>}
                </Grid>
                <Grid container item xs={12} sm={4} md={5} lg={3} spacing={2} sx={{ marginTop: '10px', justifyContent: 'center' }}>
                  <div>
                    <JoyTypography level="body3">
                      Description: {this.state.product.description}
                    </JoyTypography>
                    <JoyTypography level="body3">Price:</JoyTypography>
                    <JoyTypography fontSize="lg" fontWeight="lg">
                      {`₦${this.state.product.price}`}
                    </JoyTypography>
                  </div>
                </Grid>
                <br />
                <Grid container item xs={10} sm={10} md={10} lg={4} spacing={3} sx={{ marginTop: '10px', justifyContent: "center", alignItems: "center" }}>
                  <Card className="" sx={{ height: 'auto', padding: 4, textAlign: 'center' }}>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
                      <TextField
                        id="productQuantity"
                        label="Quantity"
                        type="number"
                        value={1}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="standard"
                      />
                    </FormControl>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
                      <InputLabel id="product-size-select-label">Discount Percent</InputLabel>
                      <Select
                        labelId="product-size-select-label"
                        id="product-size-select"
                        value={this.state.product.discount_percent}
                        onChange={this.handleSizeChange}
                        label="Size"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={this.state.product.discount_percent}>{this.state.product.discount_percent}</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
                      <InputLabel id="product-color-select-label">Color</InputLabel>
                      <Select
                        labelId="product-color-select-label"
                        id="product-color-select"
                        value={this.state.productColor}
                        onChange={this.handleColorChange}
                        label="Color"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </Select>
                    </FormControl>
                    <div style={{ textAlign: 'center' }}>
                      <br />
                      <Button
                        color='primary'
                        // onClick={this.onClickQuickView(product)}
                        onClick={this.handleAddToCart(this.state.product)}
                        variant="contained"
                      // href='/cart'
                      >
                        ADD TO CART
                      </Button>
                    </div>
                  </Card>
                </Grid>
              </Grid>
            </DialogContent>
            <br />
          </Dialog>
        </Grid>
      </Grid>
    );
  }
}

export const mapStateToProps = state => ({
  products: state.products.data,
  brands: state.brands.data,
  categories: state.categories.data,
  auth: state.auth,
  carts: state.carts.data,
});

export const mapDispatchToProps = dispatch => ({
  addToCart: (cartItem: Product) => dispatch(addToCart(cartItem)),
  editCartQuantity: (cartItem: Product) => dispatch(editCartQuantity(cartItem)),
  loadCart: (cart: Product[]) => dispatch(loadCart(cart)),
  getProducts: (actionData: {
    search: { searchValue },
    brandList: [],
    categoryList: []
  }) => dispatch(getProducts(actionData)),
  getBrands: () => dispatch(getBrands()),
  getCarts: () => dispatch(getCarts()),
  getCategories: () => dispatch(getCategories()),
  getAttributes: () => dispatch(getAttributes()),
  getProduct: () => dispatch(getProduct(Number)),
  getOrders: () => dispatch(getOrders()),
  // setUserCart: () => dispatch(props.setUserCart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);

