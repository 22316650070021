import axios from 'axios';
import urlConfig from '../../../urlConfig';

// interfaces
import {
  Store,
  GetStoreSuccess,
  StoresError,
  GetStoresSuccess,
  EditStoreSuccess,
  CreateStoreSuccess,
  DeleteStoreSuccess
} from './interfaces';

// types
import {
  CREATE_STORE_SUCCESS,
  DELETE_STORE_SUCCESS,
  EDIT_STORE_SUCCESS,
  STORES_ERROR,
  GET_STORE_SUCCESS,
  GET_STORES_SUCCESS,
} from './types';

import { stores } from './fixtures';




/**
 * action creator
 *
 * Create stores success action creator
 *
 * @param {Store} store
 *
 * @returns {CreateStoreSuccess}
 */

export const createStoreSuccess = (store: Store): CreateStoreSuccess => ({
  store,
  type: CREATE_STORE_SUCCESS,
});

/**
 * action creator
 *
 * Get store success action creator
 *
 * @param {Store[]} store
 *
 * @returns {GetStoresSuccess}
 */
export const getStoresSuccess = (data: Store[]): GetStoresSuccess => ({
  stores: data,
  type: GET_STORES_SUCCESS,
});


export const storeError = (error): StoresError => ({
    type: STORES_ERROR,
    error
  });
/**
 * action creator
 *
 * Get an store success action creator
 *
 * @param {Store} store
 *
 * @returns {GetStoreSuccess}
 */
export const getStoreSuccess = (data: Store): GetStoreSuccess => ({
  store: data,
  type: GET_STORE_SUCCESS,
});

/**
 * action creator
 *
 * Delete specific store success action creator
 *
 * @param {Store} store
 *
 * @returns {DeleteStoreSuccess}
 */
export const deleteStoreSuccess = (storeId: string): DeleteStoreSuccess => ({
  id: storeId,
  type: DELETE_STORE_SUCCESS,
});

/**
 * action creator
 *
 * Update store success action creator
 *
 * @param {Store} store
 *
 * @returns {EditStoreSuccess}
 */
export const editStoreSuccess = (store: Store): EditStoreSuccess => ({
  store,
  type: EDIT_STORE_SUCCESS,
});

/**
 * Thunk
 *
 * Create store thunk
 *
 * @param {Object} store
 *
 * @returns {Function}
 */
export const createStore = (store: Store) => dispatch => axios.post(
  `${urlConfig.apiUrl}/stores/`, { ...store }
)
  .then((response) => {
    dispatch(createStoreSuccess(response.data));
  })
  .catch(error =>
    dispatch(storeError({
      status: error.response.status,
      data: error.response.data
    }))
  );

/**
 * Thunk
 *
 * Delete store thunk
 *
 * @param {string} storeId
 *
 * @returns {Function}
 */
export const deleteStore = storeId => dispatch => axios.delete(
  `${urlConfig.apiUrl}/stores/${storeId}`
)
  .then(() => {
    dispatch(deleteStoreSuccess(storeId));
  })
  .catch((error) =>
    dispatch(storeError({
      status: error.response.status,
      data: error.response.data
    }))
  );

  /**
 * Thunk
 *
 * Delete store thunk
 *
 * @param {string} storeId
 *
 * @returns {Function}
 */
export const getStore = storeId => dispatch => axios.get(
  `${urlConfig.apiUrl}/stores/${storeId}`
)
  .then((response) => {
    dispatch(getStoreSuccess(response.data.data));
  })
  .catch((error) =>
    dispatch(storeError({
      status: error.response.status,
      data: error.response.data
    }))
  );

/**
 * Thunk
 *
 * Get stores thunk
 *
 * @returns {Function}
 */
export const getStores = () => dispatch => {
  return axios.get(
  `${urlConfig.apiUrl}/stores`
)
  .then(response => {
    return dispatch(getStoresSuccess(response.data.data))
  })
  .catch((error) =>
    dispatch(storeError({
      status: error.response.status,
      data: error.response.data
    }))
  );
}

/**
 * Thunk
 *
 * Edit an store thunk
 *
 * @param {string} storeId
 * @param {Object} updatedRolePayload
 *
 * @returns {Funciton}
 */
export const editStore = (store: Store) => dispatch => axios.put(
  `${urlConfig.apiUrl}/stores/${store.id}`, {store}
)
  .then((response) => {
    dispatch(getStoreSuccess(response.data.data));
  })
  .catch((error) =>
    dispatch(storeError({
      status: error.response.status,
      data: error.response.data
    }))
  );

// Set the initial role state
const storeInitialState = {
  data: [],
  store: {},
  meta: {},
  errors: {},
  isLoading: false
};

/**
 * Redux reducer for User Role actions
 *
 * This reducer changes the store state of the application
 *
 * @param {StoreState} state Reducer initial state
 * @param {Action} action
 *
 * @returns {StoreState} new state
 */
const reducer = (state = storeInitialState, action) => {
  switch (action.type) {
    case CREATE_STORE_SUCCESS:
      return {
        ...state,
        data: [action.store, ...state.data],
      };
    case EDIT_STORE_SUCCESS:
      return {
        ...state,
        data: state.data.map(store =>
          store.id === action.store.id ? action.store : store),
      };
    case DELETE_STORE_SUCCESS:
      const updatedStoreList = state.data.filter(store => store.id !== action.storeId);
      return {
        ...state,
        data: updatedStoreList,
      };
    case GET_STORE_SUCCESS:
      return {
        ...state,
        store: action.store,
        isLoading: action.isLoading,
      };
    case GET_STORES_SUCCESS:
      return {
        ...state,
        data: action.stores,
        meta: action.meta,
        isLoading: action.isLoading,
      };
    case STORES_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
};

export default reducer;
