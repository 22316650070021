export const GET_STORES_SUCCESS = 'xences/stores/GET_STORES_SUCCESS';
export type GET_STORES_SUCCESS = typeof GET_STORES_SUCCESS;

export const STORES_ERROR = 'xences/stores/STORES_ERROR';
export type STORES_ERROR = typeof STORES_ERROR;

export const DELETE_STORE_SUCCESS = 'xences/stores/DELETE_STORE_SUCCESS';
export type DELETE_STORE_SUCCESS = typeof DELETE_STORE_SUCCESS;

export const GET_STORE_SUCCESS = 'xences/stores/GET_STORE_SUCCESS';
export type GET_STORE_SUCCESS = typeof GET_STORE_SUCCESS;

export const CREATE_STORE_SUCCESS = 'xences/stores/CREATE_STORE_SUCCESS ';
export type CREATE_STORE_SUCCESS  = typeof CREATE_STORE_SUCCESS ;

export const EDIT_STORE_SUCCESS = 'xences/stores/EDIT_STORE_SUCCESS';
export type EDIT_STORE_SUCCESS  = typeof EDIT_STORE_SUCCESS;
