// import libraries
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom';

import { RootState } from '../../store/rootReducer';

// third-party libraries
import { connect } from 'react-redux';
import CategoryValidation from '../../helpers/categoryValidation';
import { storage } from "../../helpers/firebaseConf";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import { createCategory, editCategory, getCategory, getAllParentCategories, getCategories } from '../../store/modules/categories';
import { getBrands } from '../../store/modules/brands';

// styles
import { makeStyles } from '@mui/styles';

// components
import {
  Button,
  Container,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  ListItemButton,
  ListItemText,
} from '@mui/material';

import CircularProgress from '@mui/joy/CircularProgress';

// interfaces
import { Category } from 'modules/categories/interfaces';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '64px',
  },
  form: {
    width: '100%',
    maxWidth: '400px',
    marginTop: '24px',
  },
  textField: {
    margin: '12px 0'
  },
}));

const NewCategory = (props: any) => {
  const dispatch = useDispatch<any>();
  const classes = useStyles();
  const {id} = useParams();
  React.useEffect(() => {
    if (id) dispatch(getCategory(id));
    dispatch(getCategories());
  }, [dispatch]);

  const emptyCategory = {
    name: '',
    description: '',
  }

  const category = id ? useSelector((state: RootState) => state.categories.category): emptyCategory;

  const categories = useSelector((state: RootState) => state.categories.data);
  const [updateCategory, setUpdateCategory] = React.useState<Category>({...category});
  const [editingCategory, setEditingCategory] = id ? React.useState(true) : React.useState(false);
  React.useEffect(() => {
  }, []); 
  const [categoryImages, setCategoryImages] = React.useState(null);

  const [editCategoryImagesUrl, setEditCategoryImagesUrl] = React.useState(false);
  const [editCategoryDescription, setEditCategoryDescription] = React.useState(false);
  const [editCategoryAddress, setEditCategoryAddress] = React.useState(false);
  const [editCategoryName, setEditCategoryName] = React.useState(false);
  const [editCategoryDiscountPercent, setEditCategoryDiscountPercent] = React.useState(false);
  const [editcategoryPrice, setEditCategoryPrice] = React.useState(false);
  const [editCategoryGender, setEditCategoryGender] = React.useState(false);
  const [editCategoryCategory, setEditCategoryCategory] = React.useState(false);
  const [editCategoryQuantity, setEditCategoryQuantity] = React.useState(false);

  const [categoryDescriptionLabel, setCategoryDescriptionLabel] = React.useState('Description');
  const [categoryDescriptionHelperText, setCategoryDescriptionHelperText] = React.useState('Please enter category description');
  const [categoryAddressLabel, setCategoryAddressLabel] = React.useState('Category Address');
  const [categoryAddressHelperText, setCategoryAddressHelperText] = React.useState('Please the the category address');
  const [categoryNameLabel, setCategoryNameLabel] = React.useState('Category Name');
  const [categoryNameHelperText, setCategoryNameHelperText] = React.useState('Please the your category name');
  const [categoryImagesLabel, setCategoryImagesLabel] = React.useState('Images');
  const [categoryImagesHelperText, setCategoryImagesHelperText] = React.useState('Please select category image');
  const [categoryDiscountPercentLabel, setCategoryDiscountPercentLabel] = React.useState('Discount');
  const [categoryDiscountPercentHelperText, setCategoryDiscountPercentHelperText] = React.useState('Please enter category discount in percent (if any)');
  const [categoryPriceLabel, setCategoryPriceLabel] = React.useState('Price');
  const [categoryPriceHelperText, setCategoryPriceHelperText] = React.useState('Please enter category price');
  const [categoryGenderLabel, setCategoryGenderLabel] = React.useState('Gender');
  const [categoryGenderHelperText, setCategoryGenderHelperText] = React.useState('Please enter category gender');
  const [categoryCategoryLabel, setCategoryCategoryLabel] = React.useState('Category');
  const [categoryCategoryHelperText, setCategoryCategoryHelperText] = React.useState('Please enter category category');
  const [categoryQuantityLabel, setCategoryQuantityLabel] = React.useState('Quantity');
  const [categoryQuantityHelperText, setCategoryQuantityHelperText] = React.useState('Please enter category quantity');
  const [uploadingImage, setUploadingImage] = React.useState(false);


  const [categoryId, setCategoryId] = React.useState(0);
  let [isValid, setIsValid] = React.useState<{[key: string]: any}>({});
  const navigate = useNavigate();

  const [categoryDescriptionError, setCategoryDescriptionError] = React.useState(false);
  const [categoryNameError, setCategoryNameError] = React.useState(false);


  const [addToParent, setAddToParent] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState(0);

  const handleCheckboxChange = (event) => {
    setAddToParent(event.target.checked);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  // const categories = ['Category 1', 'Category 2', 'Category 3']; // Replace this with your actual list of categories


  const addNewCategory = (e) => {
    e.preventDefault();
    isValid = CategoryValidation({
      name: updateCategory.name,
      description: updateCategory.description,
    });

    if (isValid.success == true && editingCategory == true) {
      updateCategory.id = parseInt(id);
      dispatch(editCategory(updateCategory));
    } else if(isValid.success == true && editingCategory == false) {
      dispatch(createCategory({...updateCategory, parentId: selectedCategory > 0 ? selectedCategory : null }));
    } else {
      setCategoryDescriptionError((isValid.error && isValid.error.description) ? true : false)
      setCategoryNameError((isValid.error && isValid.error.name) ? true : false)
    }

    if (isValid.success == true) navigate(`/`);
  }

  return (
    <div className="new-category">
    <Container component="main" maxWidth="xs" className={classes.container}>
      <form className={classes.form}>
    {editingCategory ? <h3>Update Category Details</h3> : <h3>Add New Category</h3>}
      <TextField
  variant="standard"
        id="filled-select-currency"
        error={categoryNameError}
        label={id ? ``: categoryNameLabel}
        helperText={isValid.error ? isValid.error.name[0] : 'Please the your category name'}
        name="categoryName"
        value={editCategoryName ? updateCategory.name : category.name}
        onChange={(event) => {
          setEditCategoryName(true);
          setUpdateCategory({
              name: event.target.value,
              description: editCategoryDescription ? updateCategory.description : category.description,
            });
        }}
        placeholder="Full Name"
        fullWidth
        required={true}
      ></TextField>
      <TextField
  variant="standard"
        id="filled-select-currency"
        error={categoryDescriptionError}
        label={id ? ``: categoryDescriptionLabel}
        helperText={isValid.error ? isValid.error.description[0] : 'Please enter category description'}
        name="categoryDescription"
        value={editCategoryDescription ? updateCategory.description : category.description}
        onChange={(event) => {
          setEditCategoryDescription(true);
          setUpdateCategory({
              name: editCategoryName ? updateCategory.name : category.name,
              description: event.target.value,
            });
        }}
        placeholder="Description No"
        fullWidth
        required={true}
      ></TextField>

<FormControlLabel
            control={
              <Checkbox
                checked={addToParent}
                onChange={handleCheckboxChange}
                name="addToParent"
                color="primary"
              />
            }
            label="Add to parent"
          />
          {addToParent && (
            <FormControl fullWidth>
              <InputLabel id="category-label">Select Category</InputLabel>
              <Select
                labelId="category-label"
                value={selectedCategory}
                onChange={handleCategoryChange}
              >
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
      <Button variant="contained" color="primary" onClick={addNewCategory}>Save</Button>
      </form>
      </Container>
    </div>
  );
}

export default NewCategory;
