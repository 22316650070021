import axios from 'axios';
import urlConfig from '../../../urlConfig';

// interfaces
import {
  Order,
  GetOrderSuccess,
  OrdersError,
  GetOrdersSuccess,
  EditOrderSuccess,
  CreateOrderSuccess,
  DeleteOrderSuccess
} from './interfaces';

// types
import {
  CREATE_ORDER_SUCCESS,
  DELETE_ORDER_SUCCESS,
  EDIT_ORDER_SUCCESS,
  ORDERS_ERROR,
  GET_ORDER_SUCCESS,
  GET_ORDERS_SUCCESS,
} from './types';

// import { orders } from './fixtures';

/**
 * action creator
 *
 * Create orders success action creator
 *
 * @param {Order} order
 *
 * @returns {CreateOrderSuccess}
 */

export const createOrderSuccess = (order: Order): CreateOrderSuccess => ({
  order,
  type: CREATE_ORDER_SUCCESS,
});

/**
 * action creator
 *
 * Get order success action creator
 *
 * @param {Order[]} order
 *
 * @returns {GetOrdersSuccess}
 */
export const getOrdersSuccess = (data: Order[]): GetOrdersSuccess => ({
  orders: data,
  type: GET_ORDERS_SUCCESS,
});


export const orderError = (error): OrdersError => ({
    type: ORDERS_ERROR,
    error
  });
/**
 * action creator
 *
 * Get an order success action creator
 *
 * @param {Order} order
 *
 * @returns {GetOrderSuccess}
 */
export const getOrderSuccess = (data: Order): GetOrderSuccess => ({
  order: data,
  type: GET_ORDER_SUCCESS,
});

/**
 * action creator
 *
 * Delete specific order success action creator
 *
 * @param {Order} order
 *
 * @returns {DeleteOrderSuccess}
 */
export const deleteOrderSuccess = (orderId: string): DeleteOrderSuccess => ({
  id: orderId,
  type: DELETE_ORDER_SUCCESS,
});

/**
 * action creator
 *
 * Update order success action creator
 *
 * @param {Order} order
 *
 * @returns {EditOrderSuccess}
 */
export const editOrderSuccess = (order: Order): EditOrderSuccess => ({
  order,
  type: EDIT_ORDER_SUCCESS,
});

/**
 * Thunk
 *
 * Create order thunk
 *
 * @param {Object} order
 *
 * @returns {Function}
 */
export const createOrder = (order: Order) => dispatch => axios.post(
  `${urlConfig.apiUrl}/orders/`, { order }
)
  .then((response) => {
    dispatch(createOrderSuccess(response.data));
  })
  .catch(error =>
    dispatch(orderError({
      status: error.response.status,
      data: error.response.data
    }))
  );

/**
 * Thunk
 *
 * Delete order thunk
 *
 * @param {string} orderId
 *
 * @returns {Function}
 */
export const deleteOrder = orderId => dispatch => axios.delete(
  `${urlConfig.apiUrl}/orders/${orderId}`
)
  .then(() => {
    dispatch(deleteOrderSuccess(orderId));
  })
  .catch((error) =>
    dispatch(orderError({
      status: error.response.status,
      data: error.response.data
    }))
  );

  /**
 * Thunk
 *
 * Delete order thunk
 *
 * @param {string} orderId
 *
 * @returns {Function}
 */
export const getOrder = orderId => dispatch => axios.get(
  `${urlConfig.apiUrl}/orders/${orderId}`
)
  .then((response) => {
    dispatch(getOrderSuccess(response.data.data));
  })
  .catch((error) =>
    dispatch(orderError({
      status: error.response.status,
      data: error.response.data
    }))
  );

/**
 * Thunk
 *
 * Get orders thunk
 *
 * @returns {Function}
 */
export const getOrders = () => dispatch => {
  console.log("in get user orders")
  return axios.get(
  `${urlConfig.apiUrl}/orders`
)
  .then(response => {
    return dispatch(getOrdersSuccess(response.data.data))
  })
  .catch((error) =>
    dispatch(orderError({
      status: error.response.status,
      data: error.response.data
    }))
  );
}

/**
 * Thunk
 *
 * Edit an order thunk
 *
 * @param {string} orderId
 * @param {Object} updatedRolePayload
 *
 * @returns {Funciton}
 */
export const editOrder = (order: Order) => dispatch => axios.put(
  `${urlConfig.apiUrl}/orders/${order.id}`, {order}
)
  .then((response) => {
    dispatch(getOrderSuccess(response.data.data));
  })
  .catch((error) =>
    dispatch(orderError({
      status: error.response.status,
      data: error.response.data
    }))
  );

// Set the initial role state
const orderInitialState = {
  data: [],
  meta: {},
  order: {},
  errors: {},
  isLoading: false
};

/**
 * Redux reducer for User Role actions
 *
 * This reducer changes the order state of the application
 *
 * @param {OrderState} state Reducer initial state
 * @param {Action} action
 *
 * @returns {OrderState} new state
 */
const reducer = (state = orderInitialState, action) => {
  switch (action.type) {
    case CREATE_ORDER_SUCCESS:
      return {
        ...state,
        data: [action.order, ...state.data],
      };
    case EDIT_ORDER_SUCCESS:
      return {
        ...state,
        data: state.data.map(order =>
          order.id === action.order.id ? action.order : order),
      };
    case DELETE_ORDER_SUCCESS:
      const updatedOrderList = state.data.filter(order => order.id !== action.orderId);
      return {
        ...state,
        data: updatedOrderList,
      };
    case GET_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        data: action.orders,
        meta: action.meta,
        isLoading: action.isLoading,
      };
    case ORDERS_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
};

export default reducer;
