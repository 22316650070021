import { combineReducers } from 'redux';

// reducers
import auth from './modules/auth';
import products from './modules/products';
import users from './modules/users';
import stores from './modules/stores';
import carts from './modules/carts';
import brands from './modules/brands';
import attributes from './modules/attributes';
import categories from './modules/categories';
import orders from './modules/orders';
import { Product } from 'modules/products/interfaces';
import { User } from 'modules/users/interfaces';
import { Store } from 'modules/stores/interfaces';
import { Brand } from 'modules/brands/interfaces';
import { Attribute } from 'modules/attributes/interfaces';
import { Category } from 'modules/categories/interfaces';
import { Order } from 'modules/orders/interfaces';

// types

const appReducer = combineReducers({
  auth,
  carts,
  products,
  users,
  stores,
  brands,
  categories,
  attributes,
  orders,
});

const rootReducer = (state, action) => {
  switch (action.type) {

  }

  return appReducer(state, action);
};

export default rootReducer;

export interface RootState {
  auth: boolean,
  stores: {
    data: Store[],
    store: Store,
    errors: {},
    isLoading: Boolean,
    meta: {},
  },
  carts: {
    data: Product[],
    product: Product,
    errors: {},
    isLoading: Boolean,
    meta: {},
  },
  users: {
    data: User[],
    user: User,
    errors: {},
    isLoading: Boolean,
    meta: {},
  },
  products: {
    data: Product[],
    product: Product,
    errors: {},
    isLoading: Boolean,
    meta: {},
  },
  orders: {
    data: Order[],
    order: Order,
    errors: {},
    isLoading: Boolean,
    meta: {},
  },
  brands: {
    data: Brand[],
    brand: Brand,
    errors: {},
    isLoading: Boolean,
    meta: {},
  },
  attributes: {
    data: Attribute[],
    attribute: Attribute,
    errors: {},
    isLoading: Boolean,
    meta: {},
  },
  categories: {
    data: Category[],
    category: Category,
    errors: {},
    isLoading: Boolean,
    meta: {},
  },
}