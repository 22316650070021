import * as React from 'react';
// import { useState } from 'react';
// import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { getUser } from '../../store/modules/users';
import { RootState } from '../../store/rootReducer';
import { Container, Typography, Avatar, Grid, TextField, Button, useTheme } from '@mui/material';
import { User } from 'modules/users/interfaces';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '64px',
  },
  form: {
    width: '100%',
    maxWidth: '400px',
    marginTop: '24px',
  },
  textField: {
    marginBottom: '16px',
  },
  submitButton: {
    marginTop: '24px',
  },
  gridItem: {
    margin: '12px 0'
  }
}));

const ProfilePage = (props: any) => {

  const { auth } = props;
  const dispatch = useDispatch<any>();


  // console.log(user);
  const theme = useTheme();
  const classes = useStyles();
  const [editMode, setEditMode] = React.useState(false);
  const [formData, setFormData] = React.useState<User>({
    name: '',
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    phone: '',
    address: '',
    region: ''
  });

  const user: User = useSelector((state: RootState) => state.users.user);
  // console.log(user);
  // setFormData({ ...user });
  // console.log(formData);

  React.useEffect(() => {
    dispatch(getUser(auth.user.userId));
    // let user = auth.user.userId ? useSelector((state: RootState) => state.users.user) : null;
  // let user: User = useSelector((state: RootState) => state.users.user);
    // setFormData({ ...user });
    // console.log(user)
    // console.log(formData);
  }, [dispatch]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSave = () => {
    // Add logic to save changes to the user's account
    // console.log('Changes saved:', formData);
    setEditMode(false);
  };

  // React.useEffect(() => {
  //   dispatch(getUser(auth.user.userId));
  // }, [dispatch]);

  return (
    <div>
      <Container component="main" maxWidth="xs" className={classes.container}>
        <form className={classes.form}>
          <Grid container spacing={2} justifyContent="center" alignItems="center" direction="column" style={{ marginTop: '2rem' }}>
            <div className={classes.gridItem}>
              <Avatar alt={user && `${user.firstname} ${user.lastname}`} sx={{ width: 120, height: 120 }}></Avatar>
            </div>
          </Grid>
          <div className={classes.gridItem}>
            <TextField
              className={classes.textField}
              variant="outlined"
              fullWidth
              label="Firstname"
              name="firstname"
              type="firstname"
              value={user.firstname}
              onChange={handleChange}
              disabled={!editMode}
            />
          </div>
          <div className={classes.gridItem}>
            <TextField
              className={classes.textField}
              variant="outlined"
              fullWidth
              label="Address"
              name="address"
              type="address"
              value={user && user.address}
              onChange={handleChange}
              disabled={!editMode}
            />
          </div>
          <div className={classes.gridItem}>
            <TextField
              className={classes.textField}
              variant="outlined"
              fullWidth
              label="Phone"
              name="phone"
              type="phone"
              value={user && user.phone}
              onChange={handleChange}
              disabled={!editMode}
            />
          </div>
          <div className={classes.gridItem}>
            <TextField
              className={classes.textField}
              variant="outlined"
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={user && user.email}
              onChange={handleChange}
              disabled={!editMode}
            />
          </div>
          {editMode ? (
            <div className={classes.gridItem}>
              <Button
                className={classes.submitButton}
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSave}
              >
                Save Changes
              </Button>
            </div>
          ) : (
            <div className={classes.gridItem}>
              <Button
                className={classes.submitButton}
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleEdit}
              >
                Edit Account
              </Button>
            </div>
          )}
        </form>
      </Container>
    </div>
  );
};

export default ProfilePage;
