import {APPS} from './constants'

export const getApp = () => {
  const subdomain = getSubdomain(window.location.hostname);

  const mainRouter = APPS.find((app) => app.main);

  if(!mainRouter) throw new Error ("Must have main app");

  if(subdomain === "") return mainRouter.app;

  const app = APPS.find(app => subdomain === app.subdomain);

  if(!app) return mainRouter.app;

  return app.app;
}

const getSubdomain = (location: string) => {
  const locationParts = location.split(".");

  let sliceTill = -2;

  const isLocalHost = locationParts.slice(-1)[0] === "localhost";
  if(isLocalHost) sliceTill = -1

  return locationParts.slice(0, sliceTill).join("");
}
