export const GET_CARTS_SUCCESS = 'xences/carts/GET_CARTS_SUCCESS';
export type GET_CARTS_SUCCESS = typeof GET_CARTS_SUCCESS;

export const CARTS_ERROR = 'xences/carts/CARTS_ERROR';
export type CARTS_ERROR = typeof CARTS_ERROR;

export const DELETE_CART_SUCCESS = 'xences/carts/DELETE_CART_SUCCESS';
export type DELETE_CART_SUCCESS = typeof DELETE_CART_SUCCESS;

export const GET_CART_SUCCESS = 'xences/carts/GET_CART_SUCCESS';
export type GET_CART_SUCCESS = typeof GET_CART_SUCCESS;

export const CREATE_CART_SUCCESS = 'xences/carts/CREATE_CART_SUCCESS ';
export type CREATE_CART_SUCCESS  = typeof CREATE_CART_SUCCESS ;

export const ADD_TO_CART_SUCCESS = 'xences/carts/ADD_TO_CART_SUCCESS ';
export type ADD_TO_CART_SUCCESS  = typeof ADD_TO_CART_SUCCESS ;

export const EDIT_CART_SUCCESS = 'xences/carts/EDIT_CART_SUCCESS';
export type EDIT_CART_SUCCESS  = typeof EDIT_CART_SUCCESS;

export const EDIT_CART_QUANTITY_SUCCESS = 'xences/carts/EDIT_CART_QUANTITY_SUCCESS';
export type EDIT_CART_QUANTITY_SUCCESS  = typeof EDIT_CART_QUANTITY_SUCCESS;

export const DELETE_FROM_CART_SUCCESS = 'xences/carts/DELETE_FROM_CART_SUCCESS';
export type DELETE_FROM_CART_SUCCESS = typeof DELETE_FROM_CART_SUCCESS;

export const LOAD_CART_SUCCESS = 'xences/carts/LOAD_CART_SUCCESS';
export type LOAD_CART_SUCCESS = typeof LOAD_CART_SUCCESS;
