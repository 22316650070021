// import libraries
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom';

import { RootState } from '../../store/rootReducer';

// third-party libraries
import BrandValidation from '../../helpers/brandValidation';

import { createBrand, editBrand, getBrand } from '../../store/modules/brands';

// styles
import { makeStyles } from '@mui/styles';

// components
import {
  Button,
  Container,
  TextField,
} from '@mui/material';

// interfaces
import { Brand } from 'modules/brands/interfaces';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '64px',
  },
  form: {
    width: '100%',
    maxWidth: '400px',
    marginTop: '24px',
  },
  textField: {
    margin: '12px 0'
  },
}));

const NewBrand = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch<any>();
  const {id} = useParams();
  React.useEffect(() => {
    if (id) dispatch(getBrand(id));
  }, [dispatch]);

  const emptyBrand = {
    name: '',
    description: '',
  }

  const brand = id ? useSelector((state: RootState) => state.brands.brand): emptyBrand;

  const [updateBrand, setUpdateBrand] = React.useState<Brand>({...brand});
  const [editingBrand, setEditingBrand] = id ? React.useState(true) : React.useState(false);
  
  const [brandImages, setBrandImages] = React.useState(null);

  const [editBrandImagesUrl, setEditBrandImagesUrl] = React.useState(false);
  const [editBrandDescription, setEditBrandDescription] = React.useState(false);
  const [editBrandAddress, setEditBrandAddress] = React.useState(false);
  const [editBrandName, setEditBrandName] = React.useState(false);
  const [editBrandDiscountPercent, setEditBrandDiscountPercent] = React.useState(false);
  const [editbrandPrice, setEditBrandPrice] = React.useState(false);
  const [editBrandGender, setEditBrandGender] = React.useState(false);
  const [editBrandBrand, setEditBrandBrand] = React.useState(false);
  const [editBrandQuantity, setEditBrandQuantity] = React.useState(false);

  const [brandDescriptionLabel, setBrandDescriptionLabel] = React.useState('Description');
  const [brandDescriptionHelperText, setBrandDescriptionHelperText] = React.useState('Please enter brand description');
  const [brandAddressLabel, setBrandAddressLabel] = React.useState('Brand Address');
  const [brandAddressHelperText, setBrandAddressHelperText] = React.useState('Please the the brand address');
  const [brandNameLabel, setBrandNameLabel] = React.useState('Brand Name');
  const [brandNameHelperText, setBrandNameHelperText] = React.useState('Please the your brand name');
  const [brandImagesLabel, setBrandImagesLabel] = React.useState('Images');
  const [brandImagesHelperText, setBrandImagesHelperText] = React.useState('Please select brand image');
  const [brandDiscountPercentLabel, setBrandDiscountPercentLabel] = React.useState('Discount');
  const [brandDiscountPercentHelperText, setBrandDiscountPercentHelperText] = React.useState('Please enter brand discount in percent (if any)');
  const [brandPriceLabel, setBrandPriceLabel] = React.useState('Price');
  const [brandPriceHelperText, setBrandPriceHelperText] = React.useState('Please enter brand price');
  const [brandGenderLabel, setBrandGenderLabel] = React.useState('Gender');
  const [brandGenderHelperText, setBrandGenderHelperText] = React.useState('Please enter brand gender');
  const [brandBrandLabel, setBrandBrandLabel] = React.useState('Brand');
  const [brandBrandHelperText, setBrandBrandHelperText] = React.useState('Please enter brand brand');
  const [brandQuantityLabel, setBrandQuantityLabel] = React.useState('Quantity');
  const [brandQuantityHelperText, setBrandQuantityHelperText] = React.useState('Please enter brand quantity');
  const [uploadingImage, setUploadingImage] = React.useState(false);


  const [brandId, setBrandId] = React.useState(0);
  let [isValid, setIsValid] = React.useState<{[key: string]: any;}>({});
  const navigate = useNavigate();

  const [brandDescriptionError, setBrandDescriptionError] = React.useState(false);
  const [brandNameError, setBrandNameError] = React.useState(false);



  const addNewBrand = (e) => {
    e.preventDefault();
    isValid = BrandValidation({
      name: updateBrand.name,
      description: updateBrand.description,
    });

    if (isValid.success == true && editingBrand == true) {
      updateBrand.id = parseInt(id);
      dispatch(editBrand(updateBrand));
    } else if(isValid.success == true && editingBrand == false) {
      dispatch(createBrand(updateBrand));
    } else {
      setBrandDescriptionError((isValid.error && isValid.error.description) ? true : false)
      setBrandNameError((isValid.error && isValid.error.name) ? true : false)
    }

    if (isValid.success == true) navigate(`/`);
  }

  return (
    <div className="new-brand">
    <Container component="main" maxWidth="xs" className={classes.container}>
      <form className={classes.form}>
      {editingBrand ? <h3>Update Brand Details</h3> : <h3>Add New Brand</h3>}
      <TextField
  variant="standard"
        id="filled-select-currency"
        error={brandNameError}
        label={id ? ``: brandNameLabel}
        helperText={isValid.error ? isValid.error.name[0] : 'Please the your brand name'}
        name="brandName"
        value={editBrandName ? updateBrand.name : brand.name}
        onChange={(event) => {
          setEditBrandName(true);
          setUpdateBrand({
              name: event.target.value,
              description: editBrandDescription ? updateBrand.description : brand.description,
            });
        }}
        placeholder="Full Name"
        fullWidth
        required={true}
      ></TextField>
      <TextField
  variant="standard"
        id="filled-select-currency"
        error={brandDescriptionError}
        label={id ? ``: brandDescriptionLabel}
        helperText={isValid.error ? isValid.error.description[0] : 'Please enter brand description'}
        name="brandDescription"
        value={editBrandDescription ? updateBrand.description : brand.description}
        onChange={(event) => {
          setEditBrandDescription(true);
          setUpdateBrand({
              name: editBrandName ? updateBrand.name : brand.name,
              description: event.target.value,
            });
        }}
        placeholder="Description No"
        fullWidth
        required={true}
      ></TextField>
      <Button variant="contained" color="primary" onClick={addNewBrand}>Save</Button>
      </form>
      </Container>
    </div>
  );
}

export default NewBrand;
