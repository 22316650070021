// import libraries
import * as React from 'react';
import * as PropTypes from 'prop-types';

// third-party libraries
import { connect } from 'react-redux';
import UserInputValidation from '../../helpers/authValidation';
import auth, { signUpUser, signInUser } from '../../store/modules/auth';
import { Navigate } from "react-router-dom";

// styles

// components
// import Carousel from 'react-material-ui-carousel';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Tabs,
  Tab,
  Typography,
} from '@mui/material';

// interfaces
import { HomeProps, HomeState } from './interfaces';
import { User } from 'modules/users/interfaces';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export class HomePage extends React.Component<HomeProps, HomeState> {

  initialState = {
    displayFullAuth: false,
    errors: {
      userPhoneError: false,
      userEmailError: false,
      userAddressError: false,

      userCityError: false,
      userStateError: false,
      userZipcodeError: false,
      userCountryError: false,

      userFirstNameError: false,
      userLastNameError: false,
      userPasswordError: false,
      userConfirmPasswordError: false,
    },
    openedTab: 0,
    loginUserPassword: '',
    loginUserEmail: '',
    userConfirmPassword: '',
    userPhone: '',
    userPassword: '',
    userEmail: '',
    userAddress: '',

    userCity: '',
    userState: '',
    userZipcode: '',
    userCountry: '',

    userRegion: '',
    userFirstName: '',
    userLastName: '',
    userPhoneLabel: 'Phone',
    userPhoneHelperText: 'Please input your mobile no',
    userEmailLabel: 'Email',
    userEmailHelperText: 'Please input your e-mail address',
    userAddressLabel: 'Address',
    userAddressHelperText: 'Please input the delivery address',

    userCityLabel: 'City',
    userCityHelperText: 'Please input value for city',

    userStateLabel: 'State',
    userStateHelperText: 'Please input value for state',

    userZipcodeLabel: 'ZipCode',
    userZipcodeHelperText: 'Please input value for zipcode',

    userCountryLabel: 'Country',
    userCountryHelperText: 'Please input value for country',

    userFirstNameLabel: 'Firstname',
    userFirstNameHelperText: 'Please input your Firstname',
    userLastNameLabel: 'Lastname',
    userLastNameHelperText: 'Please input your Lastname',
    userRegionLabel: 'Region',
    userRegionHelperText: 'Please input your region',
    userPasswordLabel: 'Password',
    userPasswordHelperText: 'Please input your password',
    userConfirmPasswordLabel: 'Confirm Password',
    userConfirmPasswordHelperText: 'Please confirm your password',
  };

  state = this.initialState;

  componentDidMount() {
  }


  onChange = (event) => {
    const { errors } = this.state;
    this.setState({
      [event.target.name]: event.target.value
    });
    event.preventDefault();
  }

  onTabChange = (e: React.SyntheticEvent, selectedTab: Number) => {
    this.setState((prevState) => ({
      openedTab: selectedTab,
      displayFullAuth: !prevState.displayFullAuth,
      userEmailHelperText: 'Please input your e-mail address',
      userPhoneHelperText: 'Please input your mobile no',
      userAddressHelperText: 'Please input your street address',

      userCityHelperText: 'Please input your address city',
      userStateHelperText: 'Please input address state',
      userZipcodeHelperText: 'Please input address zipcode',
      userCountryHelperText: 'Please input address country',

      userNameHelperText: 'Please input your fullname',
      userFirstNameHelperText: 'Please input your Firstname',
      userLastNameHelperText: 'Please input your Lastname',
      userRegionHelperText: 'Please input your region',
      userPasswordHelperText: 'Please input your password',
      userConfirmPasswordHelperText: 'Please confirm your password',
      errors: {
        userPhoneError: false,
        userEmailError: false,
        userAddressError: false,
        userFirstNameError: false,
        userLastNameError: false,
        userPasswordError: false,
        userConfirmPasswordError: false,
      },
    }));
  }



  onClickQuickView = (product) => (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      product,
      displayedImage: product.images[0],
      clickQuickView: !prevState.clickQuickView,
    }));
  }

  authClick = (e) => {
    e.preventDefault();
    let isValid;
    const {
      displayFullAuth,
      userFirstName,
      userLastName,
      userAddress,
      userCity,
      userState,
      userZipcode,
      userCountry,
      userPhone,
      userEmail,
      userPassword,
      userConfirmPassword,
      loginUserEmail,
      loginUserPassword,
      userRegion } = this.state;

    if (displayFullAuth) {
      isValid = UserInputValidation.signUpInputValidation({
        firstname: userFirstName,
        lastname: userLastName,
        phone: userPhone,
        email: userEmail,
        password: userPassword,
        confirmPassword: userConfirmPassword,
        address: userAddress,
        streetAddress: userAddress,
        city: userCity,
        state: userState,
        zipcode: userZipcode,
        country: userCountry,
      });
    } else {
      isValid = UserInputValidation.signInInputValidation({
        email: loginUserEmail,
        password: loginUserPassword,
      });
    }

    if (isValid == true && displayFullAuth) {
      const { signUpUser } = this.props;
      signUpUser({
        firstname: userFirstName,
        lastname: userLastName,
        phone: userPhone,
        email: userEmail,
        password: userPassword,
        confirmPassword: userConfirmPassword,
        address: userAddress,
        streetAddress: userAddress,
        city: userCity,
        state: userState,
        zipcode: userZipcode,
        country: userCountry,
      });
    } else if (isValid == true && !displayFullAuth) {
      const { signInUser } = this.props;
      signInUser({
        email: loginUserEmail,
        password: loginUserPassword,
      });
    }
    else {
      if (displayFullAuth) {
        this.setState({
          userPasswordHelperText: (isValid.error.password ? isValid.error.password[0] : 'Please input your password'),
          userRegionHelperText: (isValid.error.region ? isValid.error.region[0] : 'Please input your region'),
          userFirstNameHelperText: (isValid.error.firstname ? isValid.error.firstname[0] : 'Please input your Firstname'),
          userLastNameHelperText: (isValid.error.lastname ? isValid.error.lastname[0] : 'Please input your Lastname'),
          userAddressHelperText: (isValid.error.address ? isValid.error.address[0] : 'Please input the delivery address'),
          userCity: (isValid.error.city ? isValid.error.city[0] : 'Please input the delivery city'),
          userState: (isValid.error.state ? isValid.error.state[0] : 'Please input the delivery state'),
          userZipcode: (isValid.error.zipcode ? isValid.error.zipcode[0] : 'Please input the delivery zipcode'),
          userCountry: (isValid.error.country ? isValid.error.country[0] : 'Please input the delivery country'),
          userEmailHelperText: (isValid.error.email ? isValid.error.email[0] : 'Please input your e-mail address'),
          userConfirmPasswordHelperText: (isValid.error.password_confirmation ? isValid.error.password_confirmation[0] : 'Please confirm your password'),
          errors: {
            userPasswordError: (isValid.error.password ? true : false),
            userPhoneError: (isValid.error.phone ? true : false),
            userFirstNameError: (isValid.error.firstname ? true : false),
            userLastNameError: (isValid.error.lastname ? true : false),
            userAddressError: (isValid.error.address ? true : false),
            userEmailError: (isValid.error.email ? true : false),
            userConfirmPasswordError: (isValid.error.password_confirmation ? true : false),
          },
        });
      } else {
        this.setState({
          userPasswordHelperText: (isValid.error.password ? isValid.error.password[0] : 'Please input your password'),
          userEmailHelperText: (isValid.error.email ? isValid.error.email[0] : 'Please input your e-mail address'),
          errors: {
            userPasswordError: (isValid.error.password ? true : false),
            userEmailError: (isValid.error.email ? true : false),
          },
        });
      }
    }
  }

  displayFullAuthForm = (e) => {
    e.preventDefault();

    this.setState((prevState) => ({
      displayFullAuth: !prevState.displayFullAuth,
      userEmailHelperText: 'Please input your e-mail address',
      userAddressHelperText: 'Please input the delivery address',
      userNameHelperText: 'Please input your fullname',
      userRegionHelperText: 'Please input your region',
      userPasswordHelperText: 'Please input your password',
      userConfirmPasswordHelperText: 'Please confirm your password',
      errors: {
        userPhoneError: false,
        userEmailError: false,
        userAddressError: false,
        userNameError: false,
        userRegionError: false,
        userPasswordError: false,
        userConfirmPasswordError: false,
      },
    }))
  }
  

  render() {
    const {
      displayFullAuth,
      userFirstName,
      userLastName,
      userAddress,

      userCity,
      userState,
      userZipcode,
      userCountry,
      userCityLabel,
      userCityHelperText,
      userStateLabel,
      userStateHelperText,
      userZipcodeLabel,
      userZipcodeHelperText,
      userCountryLabel,
      userCountryHelperText,
  

      userPhone,
      userEmail,
      userPassword,
      userConfirmPassword,
      userRegion,
      errors,
      userAddressHelperText,
      userAddressLabel,
      userEmailHelperText,
      userEmailLabel,
      userFirstNameHelperText,
      userLastNameHelperText,
      userFirstNameLabel,
      userLastNameLabel,
      userPhoneHelperText,
      userPhoneLabel,
      userRegionLabel,
      userRegionHelperText,
      userPasswordLabel,
      userPasswordHelperText,
      userConfirmPasswordLabel,
      userConfirmPasswordHelperText,
      openedTab,
      loginUserEmail,
      loginUserPassword,
    } = this.state;

    return (
      <div className="home-page">
        <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={openedTab} onChange={this.onTabChange} aria-label="Authentication Tabs(Login/Register)">
          <Tab label="Login" {...a11yProps(0)} />
          <Tab label="Register" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={openedTab} index={0}>
      <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop: "40px", marginRight: "auto", marginLeft: "auto"}}>
            <h2>{displayFullAuth ? `Register` : `Login`}</h2>
            <TextField
  variant="standard"
              id="filled-email"
              error={errors.userEmailError}
              label={userEmailLabel}
              helperText={userEmailHelperText}
              name="loginUserEmail"
              value={loginUserEmail}
              onChange={this.onChange}
              placeholder="Email"
              fullWidth
              required={true}
            ></TextField>
            <TextField
  variant="standard"
              id="filled-password"
              type="password"
              error={errors.userPasswordError}
              label={userPasswordLabel}
              helperText={userPasswordHelperText}
              name="loginUserPassword"
              value={loginUserPassword}
              onChange={this.onChange}
              placeholder="Password"
              fullWidth
              required={true}
            ></TextField>
            <Button color="primary" size="medium" onClick={this.authClick}>{displayFullAuth ? `Register` : `Login`}</Button>
          </Grid>
      </TabPanel>
      <TabPanel value={openedTab} index={1}>
      <Grid item xs={12} sm={12} md={12} lg={12} style={{paddingTop: "40px", marginRight: "auto", marginLeft: "auto"}}>
            <h2>{displayFullAuth ? `Register` : `Login`}</h2>
            <TextField
  variant="standard"
              id="filled-email"
              error={errors.userEmailError}
              label={userEmailLabel}
              helperText={userEmailHelperText}
              name="userEmail"
              value={userEmail}
              onChange={this.onChange}
              placeholder="Email"
              fullWidth
              required={true}
            ></TextField>
            <TextField
  variant="standard"
              id="filled-password"
              type="password"
              error={errors.userPasswordError}
              label={userPasswordLabel}
              helperText={userPasswordHelperText}
              name="userPassword"
              value={userPassword}
              onChange={this.onChange}
              placeholder="Password"
              fullWidth
              required={true}
            ></TextField>
            {displayFullAuth ?
              (
                <div><TextField
  variant="standard"
                  error={errors.userConfirmPasswordError}
                  id="filled-ConfirmPassword"
                  type="password"
                  label={userConfirmPasswordLabel}
                  helperText={userConfirmPasswordHelperText}
                  name="userConfirmPassword"
                  value={userConfirmPassword}
                  onChange={this.onChange}
                  placeholder="Confirm Password"
                  fullWidth
                  required={true}
                ></TextField>
                  <TextField
  variant="standard"
                    id="filled-name"
                    error={errors.userFirstNameError}
                    label={userFirstNameLabel}
                    helperText={userFirstNameHelperText}
                    name="userFirstName"
                    value={userFirstName}
                    onChange={this.onChange}
                    placeholder="Firstname"
                    fullWidth
                    required={true}
                  ></TextField>
                  <TextField
  variant="standard"
                    id="filled-name"
                    error={errors.userLastNameError}
                    label={userLastNameLabel}
                    helperText={userLastNameHelperText}
                    name="userLastName"
                    value={userLastName}
                    onChange={this.onChange}
                    placeholder="Lastname"
                    fullWidth
                    required={true}
                  ></TextField>
                  <TextField
  variant="standard"
                    id="filled-phone"
                    error={errors.userPhoneError}
                    label={userPhoneLabel}
                    helperText={userPhoneHelperText}
                    name="userPhone"
                    value={userPhone}
                    onChange={this.onChange}
                    placeholder="Phone No"
                    fullWidth
                    required={false}
                  ></TextField>
                  <TextField
                    variant="standard"
                    error={errors.userAddressError}
                    id="filled-address"
                    label={userAddressLabel}
                    helperText={userAddressHelperText}
                    name="userAddress"
                    value={userAddress}
                    onChange={this.onChange}
                    placeholder="Address"
                    fullWidth
                    required={true}
                  ></TextField>
                  <TextField
                    variant="standard"
                    error={errors.userCityError}
                    id="filled-city"
                    label={userCityLabel}
                    helperText={userCityHelperText}
                    name="userCity"
                    value={userCity}
                    onChange={this.onChange}
                    placeholder="City"
                    fullWidth
                    required={true}
                  ></TextField>
                  <TextField
                    variant="standard"
                    error={errors.userStateError}
                    id="filled-state"
                    label={userStateLabel}
                    helperText={userStateHelperText}
                    name="userState"
                    value={userState}
                    onChange={this.onChange}
                    placeholder="State"
                    fullWidth
                    required={true}
                  ></TextField>
                  <TextField
                    variant="standard"
                    error={errors.userZipcodeError}
                    id="filled-zipcode"
                    label={userZipcodeLabel}
                    helperText={userZipcodeHelperText}
                    name="userZipcode"
                    value={userZipcode}
                    onChange={this.onChange}
                    placeholder="Zipcode"
                    fullWidth
                    required={true}
                  ></TextField>
                  <TextField
                    variant="standard"
                    error={errors.userCountryError}
                    id="filled-country"
                    label={userCountryLabel}
                    helperText={userCountryHelperText}
                    name="userCountry"
                    value={userCountry}
                    onChange={this.onChange}
                    placeholder="Country"
                    fullWidth
                    required={true}
                  ></TextField>
                  
                  </div>)
              : null
            }
            <Button color="primary" size="medium" onClick={this.authClick}>{displayFullAuth ? `Register` : `Login`}</Button>
          </Grid>
      </TabPanel>
        </Box>
      </div>
    );
  }
}

export const mapStateToProps = state => ({

});

export const mapDispatchToProps = dispatch => ({
  signInUser: (user: User) => dispatch(signInUser(user)),
  signUpUser: (user: User) => dispatch(signUpUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
