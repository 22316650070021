import * as React from 'react';
import { connect } from 'react-redux';

// Import Materialize
// import * as M from 'materialize-css';

// components
import {AppRoutes, AdminAppRoutes} from '../routes';
import { getApp } from '../utils/helpers';

import { signoutUser } from '../store/modules/auth';
import { loadCart } from '../store/modules/carts';
import { Product } from '../store/modules/products/interfaces';
import { getProduct, getProducts, productError } from '../store/modules/products';
import { getBrands } from '../store/modules/brands';
import { getCategories } from '../store/modules/categories';
import { getAttribute, getAttributes } from '../store/modules/attributes';
// import { editOrder, getOrders, deleteOrder  } from '../store/modules/orders';
import { getUser } from '../store/modules/users';


// interfaces
import { AppProps, AppState } from './interfaces';

class App extends React.Component<AppProps, AppState> {
  componentDidMount() {
    // Auto initialize all the things Materialize!
    // M.AutoInit();
    // this.props.getUser(this.props.auth.user.userId);
  }


  render() {
    const CurrentApp = getApp();

    return (
      <CurrentApp {...this.props} />
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  products: state.products.data,
  brands: state.brands.data,
  categories: state.categories.data,
  carts: state.carts.data,
});

export const mapDispatchToProps = dispatch => ({
  signoutUser: () => dispatch(signoutUser()),
  loadCart: (cart: Product[]) => dispatch(loadCart(cart)),
  getProducts: (actionData: {
    search: {searchValue},
    brandList: [],
    categoryList: []
  }) => dispatch(getProducts(actionData)),
  getAttributes: () => dispatch(getAttributes()),
  getCategories: () => dispatch(getCategories()),
  getBrands: () => dispatch(getBrands()),
  getUser: (userId: any) => dispatch(getUser(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
