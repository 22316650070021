// import Validator from 'validatorjs';
const Validator = require('validatorjs');

export const ProductValidation: any = (formBody) => {
  const {
    name, description, images, category, discount_percent,
    price, brand, quantity, 
  } = formBody;

  const validation = new Validator(
    {
      name, 
      description,
      images, 
      discount_percent,
      price,
      brand,
      category,
      quantity, 
    },
    {
      name: 'required|string',
      description: 'required|string',
      images: 'required|numeric|min:1',
      discount_percent: 'required|numeric',
      price: 'required|numeric',
      brand: 'required|numeric',
      category: 'required|array|min:1',
      quantity: 'required|numeric',
    }
  );

  if (validation.passes()) {
    return { success: true };
  }
  {
    const errors = validation.errors.all();
    return {
      success: false,
      status: 400,
      error: errors,
    };
  }
};

export default ProductValidation;
