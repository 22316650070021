import * as Validator from 'validatorjs';

export const BrandValidation: any = (formBody) => {
  const {
    name, description, 
  } = formBody;
  const validation = new Validator(
    {
      name, 
      description, 
    },
    {
      name: 'required|string|min:3|max:100',
      description: 'required|string|min:3|max:100',
    }
  );

  if (validation.passes()) {
    return { success: true };
  }
  {
    const errors = validation.errors.all();
    return {
      success: false,
      status: 400,
      error: errors,
    };
  }
};

export default BrandValidation;
