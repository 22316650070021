// import Validator from 'validatorjs';
// const Validator = require('validatorjs');
// import Validator from 'validatorjs';
import * as Validator from 'validatorjs';

export const StoreValidation: any = (formBody) => {
  const {
    name, description, address, phone,
    email, 
  } = formBody;
  const validation = new Validator(
    {
      name, 
      description, 
      address, 
      phone, 
      email,
    },
    {
      name: 'required|string|min:3|max:40',
      description: 'required|string|min:3|max:40',
      address: 'required|string|min:10',
      email: 'required|string|email',
      phone: 'required|string|max:40',
    }
  );

  if (validation.passes()) {
    return { success: true };
  }
  {
    const errors = validation.errors.all();
    return {
      success: false,
      status: 400,
      error: errors,
    };
  }
};

export default StoreValidation;
