

/**
 * 
 */
export const queryParamsUtil = (url, actionData) => {
  let queryParams = '';
  const {categoryList, brandList, priceRange, searchQuery} = actionData;
  url += `?`

  if(searchQuery.length > 0){
    url += `search=${searchQuery}`
  }
  if (brandList.length) {
    url += `&brand=[${brandList}]`;
  }

  if (categoryList.length) {
    url += `&category=[${categoryList}]`;
  }

  if (priceRange.length) {
    url += `&priceRange=[${priceRange}]`;
  }

  return url;
};
